import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useCustomAuth, useMyUser } from 'hooks';
import { sendEventToGTM } from 'utils';
import { useState } from 'react';

export const useAccountMenu = () => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useCustomAuth();
  const { myUser } = useMyUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loggedOutDrawer, setLoggedOutDrawer] = useState<boolean>(false);
  const [loggedInDrawer, setLoggedInDrawer] = useState<boolean>(false);
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onLogin = () => {
    loginWithRedirect();
    sendEventToGTM('login_click');
  };

  const onSignup = () => {
    navigate('/signup');
    sendEventToGTM('signup_click');
  };

  const handleLogoutModalPrimaryClick = () => {
    localStorage.setItem('congratulated', `false`);
    logout({
      logoutParams: { returnTo: `${window.location.origin}` },
    });
    sendEventToGTM('logout_success', {
      user_name: user?.name,
      user_email: user?.email,
    });
    localStorage.removeItem('myUser');
  };

  const getUserName = () => {
    if (!myUser) {
      return user?.email;
    } else {
      if (myUser?.user_metadata?.userName) return myUser?.user_metadata?.userName;
      else return myUser?.email;
    }
  };

  return {
    isAuthenticated,
    myUser,
    navigate,
    anchorEl,
    open,
    isDownMd,
    loggedOutDrawer,
    setLoggedOutDrawer,
    loggedInDrawer,
    setLoggedInDrawer,
    logoutOpen,
    setLogoutOpen,
    handleClickMenu,
    handleCloseMenu,
    handleLogoutModalPrimaryClick,
    pathname,
    onLogin,
    onSignup,
    getUserName,
  };
};
