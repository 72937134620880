import { AlgoliaAutocomplete } from 'components';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

export const ResponsiveSecondaryHeader = () => {
  return (
    <Box className={styles.responsive__secondary__header}>
      <AlgoliaAutocomplete />
    </Box>
  );
};
