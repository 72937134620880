export const BusinessTypesOptions = [
  'Audiologist',
  'Animal Care & Supplies',
  'Antiques & Collectibles',
  'Architects, Landscape Architects, Engineers & Surveyors',
  'Auto Accessories',
  'Barber & Beauty Salons',
  'Beauty Supplies',
  'Blasting & Demolition',
  'Building Materials & Supplies',
  'Cards & Gifts',
  'Chiropractic',
  'Cleaning',
  'Clothing & Accessories',
  'Consumer Electronics & Accessories',
  'Construction Companies',
  'Crafts, Hobbies & Sports',
  'Dental',
  'Department Stores, Sporting Goods',
  'Desserts, Catering & Supplies',
  'Distribution, Import/Export',
  'Dry Cleaners & Laundromats',
  'Electricians',
  'Exercise & Fitness',
  'Fast Food & Carry Out',
  'Flower Shops',
  'Grocery, Beverage & Tobacco',
  'Home Furnishings',
  'Home Goods',
  'Jewelry',
  'Laboratory, Imaging & Diagnostic',
  'Landscape & Lawn Service',
  'Manufacturing',
  'Motorcycle Sales & Repair',
  'Optical',
  'Pharmacy, Drug & Vitamin Stores',
  'Pest Control',
  'Plaster & Concrete',
  'Plumbers',
  'Pool Supplies & Service',
  'Restaurants',
  'Roofers',
  'Security System & Services',
  'Shoe Repairs',
  'Shoes',
  'Tailors',
  'Wholesale',
];
