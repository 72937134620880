import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { ReactNode } from 'react';

interface ToastWrapperProps {
  children: ReactNode;
}

export const ToastWrapper: React.FC<ToastWrapperProps> = ({ children }) => {
  return (
    <>
      {children}
      <ToastContainer />
    </>
  );
};
