import { ProductDetailsAttributeChangeResponse, ProductDetailsModelDto, VendorModelDto } from 'codegen';
import { getProductById, getVendorById } from 'services/orchestrator.service';
import { BreadcrumbItem, useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { sendEventToGTM } from 'utils';

export const useProductDetails = () => {
  const { productId, seName } = useParams();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  const [productDetails, setProductDetails] = useState<ProductDetailsModelDto>();
  const [currentTab, setCurrentTab] = useState(0);
  const [vendorDetails, setVendorDetails] = useState<VendorModelDto>();
  const [isUpperPartInView, setIsUpperPartInView] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [productAttributeChange, setProductAttributeChange] = useState<ProductDetailsAttributeChangeResponse>();

  const upperPartRef = useRef<any>(null);
  const nodeRef = useRef(null);

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const checkInView = () => {
    if (upperPartRef && upperPartRef.current) {
      const rect = upperPartRef.current.getBoundingClientRect();
      setIsUpperPartInView(rect.top < window.innerHeight && rect.bottom >= 150);
    }
  };

  const getProductDetailsDependencies = async (productId: number) => {
    try {
      setIsLoading(true);
      const [{ data: product }] = await Promise.all([getProductById(productId)]);
      setProductDetails(product?.productDetailsModel);
      const vendorId = product?.productDetailsModel.vendorModel?.id;
      if (vendorId !== 0) {
        const { data } = await getVendorById(vendorId);
        setVendorDetails(data);
      } else setVendorDetails(undefined);

      const breadcrumbs: BreadcrumbItem[] = [
        ...(product.productDetailsModel.breadcrumb.categoryBreadcrumb || []).map(item => {
          return {
            label: item.name,
            link: `/category/${item.id}/${item.seName}`,
          };
        }),
        {
          label: product.productDetailsModel.name,
          link: `/product-details/${product.productDetailsModel.id}/${product.productDetailsModel.seName}`,
        },
      ];

      updateBreadcrumbs(breadcrumbs);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching product details:', error);
      setIsLoading(false);
    }
  };

  const handleShareClick = () => {
    if (isDownMd && navigator.share && productDetails) {
      navigator.share({
        title: `${productDetails.seName}`,
        text: `Check out ${productDetails.seName} on `,
        url: document.location.href,
      });
    }
  };

  useEffect(() => {
    if (productId) {
      getProductDetailsDependencies(+productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    sendEventToGTM('view_product', {
      product_short_id: productId,
      product_se_name: seName,
    });
    updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkInView();
    document.addEventListener('scroll', checkInView);
    return () => {
      document.removeEventListener('scroll', checkInView);
    };
  }, []);

  return {
    productId,
    currentTab,
    setCurrentTab,
    productDetails,
    vendorDetails,
    isUpperPartInView,
    isLoading,
    navigate,
    handleShareClick,
    productAttributeChange,
    setProductAttributeChange,
    upperPartRef,
    nodeRef,
  };
};
