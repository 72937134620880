import { Breadcrumbs, Divider, Hidden, Link } from '@mui/material';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { Link as ReactRouterLink } from 'react-router-dom';
import { AppContainer, Typography } from 'design-system';
import { NavigateNext } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

type BreadcrumbItem = {
  label: string | null;
  link: string;
};

type BreadcrumbProps = {
  breadcrumbs: BreadcrumbItem[];
};

const Breadcrumb = ({ breadcrumbs }: BreadcrumbProps) => {
  const mappedBreadcrumbs = breadcrumbs.map((item: any, index: number) => {
    switch (index) {
      case 0:
      default:
        return (
          <ReactRouterLink to={item.link} key={`breadcrumb-link-${index}-${item.link}`}>
            <Link key={index + 1} underline="hover" color={'var(--midDarkGray)'} component={'div'}>
              <Typography key={index + 1} variant="body2" id={`bread-${item?.label}`}>
                {item.label}
              </Typography>
            </Link>
          </ReactRouterLink>
        );

      case breadcrumbs.length - 1:
        return (
          <Typography key={index + 1} variant="subtitle2" className="text-deepYellow">
            {item.label}
          </Typography>
        );
    }
  });

  return (
    <Breadcrumbs separator={<NavigateNext fontSize="small" className="fill-gray" />}>{mappedBreadcrumbs}</Breadcrumbs>
  );
};

export const BreadcrumbHeader = () => {
  const { pathname } = useLocation();
  const { breadcrumbItems } = useBreadcrumbs();
  const hasNoBreadcrumb =
    pathname === '/error' ||
    pathname === '/home' ||
    pathname.includes('/products') ||
    pathname === '/auth/sign-up' ||
    pathname === '/about-otrovato' ||
    pathname === '/contact-us/submit-success' ||
    pathname === '/terms-and-conditions' ||
    pathname === '/privacy-policy' ||
    pathname === '/all-categories' ||
    pathname === '/profile/verified';

  return (
    <>
      {!hasNoBreadcrumb && (
        <>
          <AppContainer>
            <Box className="mt-6 max-sm:mt-5">
              <Breadcrumb breadcrumbs={breadcrumbItems} />

              {pathname.includes('/product-details') && (
                <Hidden mdUp>
                  <Box className="mt-3">
                    <Divider sx={{ borderColor: 'var(--antiFlashWhite)', width: '100vw', margin: '0 -20px' }} />
                  </Box>
                </Hidden>
              )}
            </Box>
          </AppContainer>
        </>
      )}
    </>
  );
};
