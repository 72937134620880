import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const useScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // This ensures that when i navigate a page through react browser is scrolls to top
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  useEffect(() => {
    // This ensures that the scroll restoration of browser is turned off
    window.history.scrollRestoration = 'manual';
  }, []);
};
