import { ReactComponent as ChatPopupIconWhite } from 'design-system/public/icons/message-center-popup-icon-white.svg';
import { ReactComponent as ChatPopupIcon } from 'design-system/public/icons/message-center-popup-icon.svg';
import { ReactComponent as ChevronDown } from 'design-system/public/icons/chevron-down.svg';
import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { useState, useEffect } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

export const ChannelListTitle = () => {
  const { setIsChatPopupOpen, isChatPopupOpen, unreadMessages } = useMessageCenterContext();
  const [notificationMode, setNotificationMode] = useState<boolean>(false);
  useEffect(() => {
    if (unreadMessages > 0) setNotificationMode(true);
    else setNotificationMode(false);
  }, [unreadMessages]);
  const hasUnreadBg = !isChatPopupOpen && notificationMode;

  return (
    <div
      onClick={() => setIsChatPopupOpen(!isChatPopupOpen)}
      className={`${styles.message__center__title} ${hasUnreadBg ? styles.active : ''}`}
    >
      <Box className="flex justify-center items-center">
        {hasUnreadBg && <ChatPopupIconWhite className="me-3" />}
        {!hasUnreadBg && <ChatPopupIcon className="me-3" />}

        <Typography variant="subtitle1">Message Center</Typography>
      </Box>

      <Box className="flex justify-center items-center">
        {hasUnreadBg && (
          <Box className={styles.notification__badge}>
            <Typography variant="body2" className="text-xs">
              {unreadMessages}
            </Typography>
          </Box>
        )}

        <div className="ml-auto">
          {isChatPopupOpen && <CloseIcon className="fill-midLightGray" />}
          {!isChatPopupOpen && <ChevronDown />}
        </div>
      </Box>
    </div>
  );
};
