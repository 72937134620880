import { BestSellingProductsSkeleton } from './components/best-selling-products-skeleton';
import { PromotionsBannersSkeleton } from './components/promotions-banners-skeleton';
import { BestSellingProducts } from './components/best-selling-products';
import { CategoryBestSeller } from './components/category-best-sellers';
import { PromotionsBanners } from './components/promotions-banners';
import { CallToAction } from './components/call-to-action';
import { TopVendors } from './components/top-vendors';
import { AppContainer } from 'design-system';
import { useHomePage } from './useHomePage';
import styles from './styles.module.scss';
import { Faqs } from './components/faqs';
import { Box } from '@mui/material';
import classNames from 'classnames';

export const Home = () => {
  const { promotions, bestSellingProducts, homePageCategories, topVendors, faqs, isLoading } = useHomePage();

  return (
    <>
      <Box className={classNames(styles.promotion__section__container, styles.section__odd)}>
        <AppContainer>
          {!isLoading && promotions && <PromotionsBanners promotions={promotions} />}
          {isLoading && <PromotionsBannersSkeleton />}
          {!isLoading && bestSellingProducts && <BestSellingProducts products={bestSellingProducts} />}
          {isLoading && <BestSellingProductsSkeleton />}
        </AppContainer>
      </Box>

      {!isLoading && (
        <>
          <Box
            className={classNames(
              styles.section__container,
              styles.section__even,
              styles.categories__section__container,
            )}
          >
            <AppContainer>{homePageCategories && <CategoryBestSeller categories={homePageCategories} />}</AppContainer>
          </Box>

          <Box className={classNames(styles.section__container, styles.section__odd)}>
            <AppContainer>{topVendors && <TopVendors topVendors={topVendors} />}</AppContainer>
          </Box>

          <Box className={classNames(styles.section__even)}>
            <CallToAction />
          </Box>

          {/* <Box
            className={classNames(
              styles.section__container,
              styles.section__odd,
              styles.categories__section__container,
            )}
          >
            <BrowseByCategory />
          </Box> */}

          {faqs && faqs.total && faqs.total > 0 ? (
            <Box className={classNames(styles.section__container, styles.section__faq)}>
              <AppContainer>
                <Faqs faqs={faqs} />
              </AppContainer>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
