import { submitContactUs } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailPattern, phoneValidation } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import * as Yup from 'yup';

export const useContactUs = () => {
  const navigate = useNavigate();
  const { updateBreadcrumbs } = useBreadcrumbs();

  const formSchema = Yup.object().shape({
    firstname: Yup.string().trim().required('First name is required'),
    lastname: Yup.string().trim().required('Last name is required'),
    email: Yup.string().required('Email is required').matches(emailPattern, 'Enter a valid email address'),
    company: Yup.string().trim().required('Company name is required'),
    phone: phoneValidation('Invalid phone number'),
    message: Yup.string(),
  });

  const {
    handleSubmit,
    formState: { isValid },
    getValues,
    control,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async () => {
    const values = getValues();
    try {
      await submitContactUs({ ...values });
      navigate('/contact-us/submit-success');
    } catch (error) {
      if (isAxiosError(error) && error.response?.data?.message) toast.error('Invalid email address.');
      else toast.error('Un-expected Error.');
    }
  };

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Contact Us', link: '/contact-us' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isValid,
    control,
    handleSubmit,
    onSubmit,
  };
};
