import { ReactComponent as FilterIcon } from 'design-system/public/icons/filter-narrow.svg';
import { ReactComponent as FilteredIcon } from 'design-system/public/icons/filtered.svg';
import { AlgoliaCurrentRefinements } from '../algolia-current-refinements';
import { useCurrentRefinements } from 'react-instantsearch';
import { IconButton, Typography } from 'design-system';
import { Box, Hidden } from '@mui/material';
import styles from './styles.module.scss';

type Props = {
  itemsCount: number | undefined;
  pageSizeComponent: JSX.Element;
  sortComponent: JSX.Element;
  query?: string;
  setFilterDrawerOpen: Function;
  filterDrawerOpen: boolean;
};

export const AlgoliaResultsHeader = ({
  itemsCount,
  pageSizeComponent,
  sortComponent,
  query,
  setFilterDrawerOpen,
  filterDrawerOpen,
}: Props) => {
  const { items } = useCurrentRefinements();

  return (
    <>
      <Box className={styles.results__header}>
        <Typography variant="subtitle1" className="text-darkGray">
          {query ? query : 'Results'}&nbsp;&nbsp;
          <Typography variant="subtitle1" className="text-deepYellow" component={'span'}>
            {itemsCount}
          </Typography>
        </Typography>

        <Box className="flex">
          <Box className="me-6 max-sm:me-3">{pageSizeComponent}</Box>
          <Box className="max-sm:me-3">{sortComponent}</Box>
          <Hidden mdUp>
            <IconButton
              icon={items.length > 0 ? <FilteredIcon /> : <FilterIcon stroke="var(--midLightGray)" />}
              size="small"
              onClick={() => setFilterDrawerOpen(!filterDrawerOpen)}
            />
          </Hidden>
        </Box>
      </Box>

      <AlgoliaCurrentRefinements />
    </>
  );
};
