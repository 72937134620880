import { TextField, Autocomplete as MuiAutocomplete, Checkbox, Chip } from '@mui/material';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp, Close } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Controller, Control } from 'react-hook-form';
import { ErrorFeedback } from '../error-feedback';
import { InputLabel } from 'src/index';

const icon = <CheckBoxOutlineBlankSharp fontSize="small" />;
const checkedIcon = <CheckBoxSharp fontSize="small" />;

type MultipleAutocompleteProps = {
  control: Control<any>;
  name: string;
  options: any[];
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  limit?: number;
  setSelectedValues: (val: any[]) => void;
  defaultValues?: any[];
};

export const MultipleAutocomplete = ({
  control,
  name,
  options,
  label,
  placeholder,
  required,
  disabled,
  limit,
  setSelectedValues,
  defaultValues,
}: MultipleAutocompleteProps) => {
  const [limitReached, setLimitReached] = useState(false);
  const [values, setValues] = useState<any>(defaultValues);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValues(defaultValues);
    limit && defaultValues && setLimitReached(defaultValues?.length >= limit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onSelect = (newValues: any) => {
    setValues(newValues);
    setSelectedValues(newValues);
    limit && setLimitReached(newValues?.length >= limit);
  };

  const checkDisable = useCallback((option: any) => limitReached && !values.includes(option), [limitReached, values]);

  const handleOnOpen = (e: any) => {
    if (e.type !== 'change') setOpen(true);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, ref, value } = field;
        const selectedValues = value || [];

        return (
          <>
            <InputLabel label={label} required={required} disabled={disabled} />

            <MuiAutocomplete
              open={open}
              multiple
              options={options}
              getOptionLabel={option => option}
              value={selectedValues}
              getOptionDisabled={checkDisable}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              )}
              renderInput={fieldParams => (
                <TextField
                  name={`${name}-${Math.random()}`}
                  {...fieldParams}
                  required={required}
                  placeholder={placeholder}
                  inputRef={ref}
                  error={!!fieldState.error}
                  InputProps={{
                    ...fieldParams.InputProps,
                    endAdornment: (
                      <KeyboardArrowDown
                        onClick={() => setOpen(!open)}
                        style={{
                          color: 'var(--midLightGray)',
                          marginRight: '1rem',
                        }}
                      />
                    ),
                  }}
                />
              )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    label={option}
                    className="m-1 z-[999]"
                    deleteIcon={<Close className="fill-midLightGray text-base border-1 border-midLightGray" />}
                    key={option}
                  />
                ))
              }
              onChange={(event: any, newValue: any) => {
                onChange(newValue ? newValue : null);
                onSelect(newValue.length > 0 ? newValue : null);
              }}
              onOpen={handleOnOpen}
              onClose={() => setOpen(false)}
            />
            <ErrorFeedback fieldState={fieldState} />
          </>
        );
      }}
    />
  );
};
