import { ReactComponent as UnCheckedIcon } from 'design-system/public/icons/check_box_disabled.svg';
import { ReactComponent as CheckedIcon } from 'design-system/public/icons/check_box_enabled.svg';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Typography } from 'design-system';

export const CheckboxFilter = ({ manufacturerOptions, onChangeManufacturer }) => {
  return (
    <FormGroup className="mt-4">
      <Typography variant="subtitle1" className="mb-3 text-darkGray">
        Product Manufacturer
      </Typography>
      {manufacturerOptions?.map((manufacturer, idx) => (
        <FormControlLabel
          key={`manufacturer ${idx}`}
          control={
            <Checkbox
              icon={<UnCheckedIcon />}
              checkedIcon={<CheckedIcon fill="var(--magentaPink)" />}
              onChange={(e, v) => onChangeManufacturer(e, v)}
              value={manufacturer.value || ''}
            />
          }
          label={manufacturer.text}
        />
      ))}
    </FormGroup>
  );
};
