import { Auth0GetUser } from 'services/dtos/auth/Auth0GetUser.dto';
import { getUser } from 'services/orchestrator.service';
import { useAuthToken } from './useAuthToken';
import { useState, useEffect } from 'react';

export const useMyUser = () => {
  const { token } = useAuthToken();
  const [myUser, setMyUser] = useState<Auth0GetUser>(() => {
    try {
      const user = localStorage.getItem('myUser');
      return user ? JSON.parse(user) : null;
    } catch (error) {
      console.error('Error parsing user from localStorage:', error);
      return null;
    }
  });

  useEffect(() => {
    const getUserByToken = async () => {
      if (token)
        try {
          const user = await getUser(token);
          setMyUser(user?.data[0]);
          localStorage.setItem('myUser', JSON.stringify(user?.data[0]));
        } catch (error) {
          console.log(error);
        }
    };

    getUserByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    myUser,
    setMyUser,
  };
};
