import { ReactComponent as LogoutAltImage } from 'design-system/public/icons/logout-alt.svg';
import { Modal, Typography } from 'design-system';

export const LogoutModal = ({ logoutOpen, setLogoutOpen, handleLogoutModalPrimaryClick }) => {
  return (
    <>
      <Modal
        open={logoutOpen}
        image={<LogoutAltImage className="w-9 h-9" />}
        header={
          <Typography variant="h6" component="h6" className="text-center text-darkGray">
            Logout
          </Typography>
        }
        content={
          <Typography variant="body2" className="text-center text-midDarkGray">
            Are you sure you want to logout? You will be missed.
          </Typography>
        }
        primaryButtonText="Logout"
        secondaryButtonText="Cancel"
        handleClose={() => setLogoutOpen(false)}
        handlePrimary={handleLogoutModalPrimaryClick}
      />
    </>
  );
};
