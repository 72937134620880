import { Typography } from 'design-system';
import './styles.scss';

export const SectionHeader = ({ title }: { title: string }) => {
  return (
    <div className="aa-SourceHeader">
      <Typography variant="subtitle2" className="aa-SourceHeaderTitle" component="span">
        {title}
      </Typography>
      <div className="aa-SourceHeaderLine" />
    </div>
  );
};
