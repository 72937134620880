import { ReactComponent as ChevronRight } from 'design-system/public/icons/chevron-right.svg';
import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = {
  customClassName?: string;
  onClick?: any;
} & HTMLAttributes<HTMLDivElement>;

export const NextArrow = ({ className, onClick, customClassName }: Props) => {
  return (
    <div className={`${className} ${customClassName}`} onClick={onClick}>
      <ChevronRight />
    </div>
  );
};
