import { ReactComponent as CompanyLogo } from 'design-system/public/logo/Logo.svg';
import { AccountMenu } from '../../account-menu';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export const ResponsiveMainHeader = () => {
  return (
    <Box className={styles.main__toolbar__container__responsive}>
      <Link to={'/home'} className="me-3">
        <CompanyLogo className="h-[32px]" />
      </Link>
      <AccountMenu className={styles.account__menu} />
    </Box>
  );
};
