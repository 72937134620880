export enum Unit {
  Acre = 'ac',
  Ampere = 'amp',
  // Bag = 'bag',
  Barrel = 'bbl',
  // Blade = 'blade',
  // Box = 'box',
  Bushel = 'bu',
  Carat = 'ct',
  // Carton = 'carton',
  // Case = 'case',
  Centimeter = 'cm',
  Chain = 'ch',
  // Coil = 'coil',
  // Combo = 'combo',
  // CRTG = 'crtg',
  'Cubic Centimeter' = 'cm³',
  'Cubic Feet' = 'ft³',
  'Cubic Inche' = 'in³',
  'Cubic Meter' = 'm³',
  'Cubic Yard' = 'yd³',
  Celsius = '°C',
  Fahrenheit = '°F',
  Dozens = 'doz',
  Drams = 'dr',
  'Fluid Ounce' = 'fl oz',
  Feet = 'ft',
  'Forty-foot Container' = '40ft container',
  Furlong = 'fur',
  Gallon = 'gal',
  Gill = 'gi',
  Grain = 'gr',
  Gram = 'g',
  Gross = 'gross',
  Hectare = 'ha',
  Hertz = 'Hz',
  Inche = 'in',
  Kiloampere = 'kA',
  Kilogram = 'kg',
  Kilohertz = 'kHz',
  Kilometer = 'km',
  Kiloohm = 'kΩ',
  Kilovolt = 'kV',
  Kilowatt = 'kW',
  Liter = 'L',
  // 'Long Ton' = 'long ton',
  Megahertz = 'MHz',
  Meter = 'm',
  // 'Metric Ton' = 'metric ton',
  Mile = 'mi',
  Milliampere = 'mA',
  Milligram = 'mg',
  Millihertz = 'mHz',
  Milliliter = 'mL',
  Millimeter = 'mm',
  Milliohm = 'mΩ',
  Millivolt = 'mV',
  Milliwatt = 'mW',
  'Nautical Mile' = 'nmi',
  Ohms = 'Ω',
  Ounce = 'oz',
  // Pack = 'pack',
  Pair = 'pr',
  // Pallet = 'pallet',
  // Panel = 'panel',
  // Parcel = 'parcel',
  // Perche = 'perch',
  Piece = 'pc',
  Pint = 'pt',
  // Pipe = 'pipe',
  // Plant = 'plant',
  // Pole = 'pole',
  Pound = 'lb',
  Quart = 'qt',
  Quarter = 'qtr',
  // Rod = 'rod',
  // Roll = 'roll',
  // Set = 'set',
  // Sheet = 'sheet',
  // 'Short Ton' = 'short ton',
  'Square Centimeter' = 'cm²',
  'Square Feet' = 'ft²',
  'Square Inche' = 'in²',
  'Square Meter' = 'm²',
  'Square Mile' = 'mi²',
  'Square Yard' = 'yd²',
  Stone = 'st',
  // Strand = 'strand',
  // Ton = 'tons',
  Tonne = 't',
  // Tray = 'tray',
  'Twenty-foot Container' = '20ft container',
  // Unit = 'unit',
  Volt = 'V',
  Watt = 'W',
  Yard = 'yd',
}
