import {
  SubmitContactSuccess,
  MessageCenterChannel,
  SubmitSuccess,
  TermsPage,
  MessageCenter,
  AboutOtrovato,
  VendorDetails,
  PrivacyPolicy,
  RFQList,
  ContactUs,
  ErrorPage,
  Category,
  RFQDetails,
  SignUp,
  Profile,
  VerifiedProfile,
  ProductDetails,
  Blogs,
  Blog,
  AllCategories,
  Home,
  Products,
  RfqFirstStep,
  RfqSecondStep,
  RfqThirdStep,
  ExpiredRfq,
} from 'pages';
import { Login, Callback, ReCaptchaProvider, Layout, ProtectedAuthenticatedRoute } from 'components';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { InstantSearch } from 'react-instantsearch';
import { useInitializeTagManager } from 'hooks';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { searchClient } from 'utils';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'styles/slider.scss';
import 'swiper/css';

export const App = () => {
  useInitializeTagManager();

  const indexName = process.env.REACT_APP_ALGOLIA_INDEX!;

  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          errorElement: <Navigate to="/error" />,
          children: [
            // Common Routes
            { path: '/', element: <Navigate to="/home" /> },
            { path: 'login', element: <Login /> },
            { path: 'callback', element: <Callback /> },
            { path: 'signup', element: <SignUp /> },
            { path: 'message-center', element: <MessageCenter /> },
            { path: 'channels/:channelUrl', element: <MessageCenterChannel /> },
            { path: 'all-categories', element: <AllCategories /> },

            // New Routes
            {
              path: '/',
              element: <Layout />,
              children: [
                {
                  path: 'rfq',
                  element: <ProtectedAuthenticatedRoute component={<RFQList />} url={'/login'} state="rfq" />,
                },
                { path: 'rfq/submit-success', element: <SubmitSuccess /> },
                { path: 'rfq/email-expired', element: <ExpiredRfq /> },
                {
                  path: 'rfq/details/:id',
                  element: <ProtectedAuthenticatedRoute component={<RFQDetails />} url={'/rfq/submit-success'} />,
                },
                {
                  path: 'profile/verified',
                  element: <ProtectedAuthenticatedRoute component={<VerifiedProfile />} url={'/home'} />,
                },
                { path: 'contact-us/submit-success', element: <SubmitContactSuccess /> },
                { path: 'product-details/:productId/:seName?', element: <ProductDetails /> },
                { path: 'category/:categoryId/:seName?', element: <Category key={1} /> },
                { path: 'vendor-details/:vendorId', element: <VendorDetails /> },
                { path: 'blogs', element: <Blogs /> },
                { path: 'blogs/blog/:id', element: <Blog /> },
                { path: 'about-otrovato', element: <AboutOtrovato /> },
                { path: 'terms-and-conditions', element: <TermsPage /> },
                { path: 'privacy-policy', element: <PrivacyPolicy /> },

                {
                  path: 'contact-us',
                  element: (
                    <ReCaptchaProvider siteKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
                      <ContactUs />
                    </ReCaptchaProvider>
                  ),
                },
                {
                  path: 'profile',
                  element: (
                    <ProtectedAuthenticatedRoute
                      component={
                        <ReCaptchaProvider siteKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
                          <Profile />
                        </ReCaptchaProvider>
                      }
                      url={'/login'}
                      state="profile"
                    />
                  ),
                },
                {
                  path: 'products/',
                  element: (
                    <InstantSearch searchClient={searchClient} indexName={indexName}>
                      <Products />
                    </InstantSearch>
                  ),
                },
                { path: '*', element: <ErrorPage /> },
              ],
            },
            {
              path: '/',
              element: <Layout containerFloating />,
              children: [
                {
                  path: 'home',
                  element: (
                    <ReCaptchaProvider siteKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
                      <Home />
                    </ReCaptchaProvider>
                  ),
                },
              ],
            },
            {
              path: '/',
              element: <Layout hasFooter={false} containerFloating />,
              children: [
                {
                  path: 'rfq/submit/first-step',
                  element: (
                    <ReCaptchaProvider siteKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
                      <RfqFirstStep />
                    </ReCaptchaProvider>
                  ),
                },
                { path: 'rfq/submit/second-step/:token', element: <RfqSecondStep /> },
                { path: 'rfq/submit/third-step/:token', element: <RfqThirdStep /> },
              ],
            },
          ],
        },
      ])}
    />
  );
};
