import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { ALGOLIA_PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME } from '../utils';
import { searchClient } from 'utils';

export const popularPlugin = createQuerySuggestionsPlugin({
  // @ts-ignore
  searchClient,
  indexName: ALGOLIA_PRODUCTS_QUERY_SUGGESTIONS_INDEX_NAME,
  getSearchParams() {
    return {
      query: '',
      hitsPerPage: 6,
    };
  },
  transformSource({ source }) {
    return {
      ...source,
      sourceId: 'popularPlugin',
      getItemInputValue({ item }) {
        return item.query;
      },
      onSelect({ setIsOpen }) {
        setIsOpen(true);
      },
    };
  },
});
