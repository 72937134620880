import { ReactComponent as ExpiredIcon } from 'design-system/public/icons/quotation-expired.svg';
import { ReactComponent as LostIcon } from 'design-system/public/icons/quotation-lost.svg';
import { ReactComponent as WonIcon } from 'design-system/public/icons/quotation-won.svg';
import { QuotationStatusEnum } from 'services/types/generic/enums';
import { Typography } from 'design-system';
import { Box } from '@mui/material';

export const QuotationStatus = ({ status }: { status: QuotationStatusEnum }) => {
  let textColor: string = '';
  let icon: JSX.Element | undefined;

  switch (status) {
    case QuotationStatusEnum.Won:
      textColor = 'text-green';
      icon = <WonIcon />;
      break;

    case QuotationStatusEnum.Expired:
      textColor = 'text-midDarkGray';
      icon = <ExpiredIcon />;
      break;

    case QuotationStatusEnum.Lost:
      textColor = 'text-red';
      icon = <LostIcon />;
      break;

    default:
      break;
  }
  return (
    <Box className="ms-1 flex items-center px-3">
      {icon && <Box className="me-1">{icon}</Box>}
      <Typography variant="overline" className={`${textColor} `}>
        {status}
      </Typography>
    </Box>
  );
};
