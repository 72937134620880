import { Table, TableBody, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { HtmlParser } from 'components';

type Props = {
  keysValues: { key: string; value: string }[] | undefined;
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--antiFlashWhite)',
    color: 'var(--darkGray)',
    textAlign: 'left',
    border: '1px solid var(--lightGray) !important',
    padding: '10px 12px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left',
    border: '1px solid var(--lightGray) !important',
    padding: '10px 12px ',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'transparent',
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'transparent',
  },
}));

export const KeyValueTable = ({ keysValues }: Props) => {
  return (
    <TableContainer sx={{ scrollbarGutter: 'unset' }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ width: '30%' }}>
              <Typography variant="subtitle2" className="text-darkGray">
                Key
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ width: '70%' }}>
              <Typography variant="subtitle2" className="text-darkGray">
                Value
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {keysValues &&
            keysValues.map((kv, idx) => (
              <StyledTableRow key={`${kv.key}#${idx}`}>
                <StyledTableCell style={{ width: '30%' }}>{kv.key}</StyledTableCell>
                {kv.value && (
                  <StyledTableCell style={{ width: '70%' }}>
                    <Typography variant="subtitle2">
                      <HtmlParser className={styles.value__content} htmlString={kv.value} />
                    </Typography>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
