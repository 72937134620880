import { ReactComponent as RfqArt1 } from 'assets/images/rfq-arts/first-step-rfq-art.svg';
import { Typography, TextInput, AutocompleteSelect } from 'design-system';
import { UnitEnum } from 'services/types/generic/enums';
import { Box, Grid, Hidden } from '@mui/material';
import { Stepper } from '../components/stepper';
import { useSubmitRfq } from './useSubmitRfq';
import styles from './styles.module.scss';
import { LoadingButton } from '@mui/lab';

export const RfqFirstStep = () => {
  const { rfqCategoryItems, onSubmit, control, findOtherCategory, isDownMd, isValid, userEmail, loading, toListItems } =
    useSubmitRfq();
  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  return (
    <Box className={styles.container}>
      <Hidden mdDown>
        <Box className={styles.rfq__art}>
          <Typography variant="h6" className="text-violet">
            Just few steps to find your product.
          </Typography>
          <RfqArt1 />
          <Typography variant="subtitle2" className="text-midDarkGray pt-3">
            Provide the product details to meet your specific requirements.
          </Typography>
        </Box>
      </Hidden>

      <Box className={styles.rfq__form}>
        <Grid container spacing={isDownMd ? 2.5 : 5} className="justify-center w-full">
          <Grid item xs={12} md={9}>
            <Stepper currentStep={1} />
          </Grid>

          <Hidden mdUp>
            <Grid item xs={12} md={9}>
              <Typography variant="h6" className="text-violet">
                Just a few steps to find your product.
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={9}>
            <AutocompleteSelect
              name="categoryId"
              label="Product Category"
              placeholder="Select product category"
              options={rfqCategoryItems}
              control={control}
              required
            />
          </Grid>

          {findOtherCategory() && (
            <Grid item xs={12} md={9}>
              <TextInput
                name="otherCategory"
                label="Please specify other"
                placeholder="Specify the other category"
                control={control}
              />
            </Grid>
          )}

          <Grid item xs={12} md={9}>
            <TextInput
              name="productName"
              label="Product Name"
              placeholder="Enter or specify product name"
              control={control}
              InputProps={{ inputProps: { maxLength: 40 } }}
              required
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <Grid container spacing={isDownMd ? 2 : 4}>
              <Grid item xs={8}>
                <TextInput
                  name="quantity"
                  label="Quantity"
                  placeholder="Enter the quantity"
                  control={control}
                  type="number"
                  required
                  InputProps={{ inputProps: { min: 1, pattern: '\\d*' } }}
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteSelect
                  name="unit"
                  label="Units"
                  placeholder="Select unit"
                  options={toListItems(UnitEnum)}
                  control={control}
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={9}>
            <TextInput
              name="email"
              label="Email"
              placeholder="Enter your email"
              control={control}
              disabled={!!userEmail}
              required={!userEmail}
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <LoadingButton
              size="large"
              variant="contained"
              className="w-full"
              onClick={onSubmit}
              disabled={!isValid}
              loading={loading}
            >
              Next
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
