import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GetBlogsDto } from 'services/dtos/hubspot/GetBlogs.dto';
import { Link, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { HTMLAttributes } from 'react';
import { BlogGrid } from 'components';
import { useBlogs } from 'hooks';

type Props = {
  title: string;
  slice: number;
} & HTMLAttributes<HTMLDivElement>;

export const BlogsSection = ({ title, slice, ...props }: Props) => {
  const { id } = useParams();
  const { blogs } = useBlogs();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let blogsFinal: GetBlogsDto = { ...blogs };

  const getFinalBlogs = () => {
    if (id) {
      const filtered = blogs?.results?.filter(blog => blog.id !== id);
      blogsFinal.results = filtered;
      blogsFinal.total = filtered?.length;
    } else if (isSmallScreen) {
      const filtered = blogs?.results?.slice(0, 2);
      blogsFinal.results = filtered;
      blogsFinal.total = filtered?.length;
    } else {
      const filtered = blogs?.results?.slice(0, slice);
      blogsFinal.results = filtered;
      blogsFinal.total = filtered?.length;
    }
    return blogsFinal;
  };

  if (getFinalBlogs().total === 0) return <></>;

  return (
    <div {...props}>
      <Box className={styles.blogs__section__container}>
        <Typography variant="h4" component="h4">
          {title}
        </Typography>

        <Link to="/blogs" className={styles.read__more}>
          <Typography>Read more</Typography>
        </Link>
      </Box>

      <BlogGrid blogResults={getFinalBlogs()?.results} />
    </div>
  );
};
