import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, Loader, Typography, VendorCard } from 'design-system';
import { VendorProductsTab } from './components/vendor-products-tab';
import { useVendorDetails } from './useVendorDetails';
import { ArrowForward } from '@mui/icons-material';
import vrImg from '../../assets/images/VR.svg';
import { Navigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { Box } from '@mui/material';
import { htmlToText } from 'utils';

export const VendorDetails = () => {
  const {
    vendor,
    onContactSeller,
    isDownMd,
    descriptionTextResp,
    fullDescriptionOpen,
    setFullDescriptionOpen,
    isLoading,
    errorState,
  } = useVendorDetails();

  if (isLoading) return <Loader />;
  if (errorState) return <Navigate to="/error" />;

  return (
    <>
      {vendor && (
        <div className={styles.vendor__section} key={`Vendor ${vendor?.id}`}>
          <div className={styles.vendor__section__info}>
            <Box className={styles.vendor__card}>
              <VendorCard
                vendor={{
                  id: vendor.id,
                  pictureModel: {
                    fullSizeImageUrl: vendor.pictureModel.fullSizeImageUrl || '',
                    alternateText: vendor.pictureModel.alternateText || '',
                  },
                }}
                sx={{ borderRadius: '8px !important', padding: '0 !important', border: '0 !important' }}
              />
            </Box>

            <Box>
              <div className="mb-3 max-sm:mb-1">
                <Typography variant="h4" component="h4" className="text-white max-sm:mb-1 line-clamp-1">
                  {vendor?.name}
                </Typography>

                {vendor?.since && (
                  <Typography variant="caption" className="text-secondaryLight">
                    Since {vendor?.since}
                  </Typography>
                )}
              </div>

              {vendor?.description && (
                <>
                  {!isDownMd && (
                    <Typography variant="body2" className="text-white">
                      {htmlToText(vendor?.description)}
                    </Typography>
                  )}

                  {isDownMd && (
                    <>
                      <Typography variant="body2" className="text-white">
                        {descriptionTextResp}
                        <Box className="inline-flex items-center">
                          <Typography
                            variant="body2"
                            className="underline text-white cursor-pointer font-bold ms-2"
                            onClick={() => setFullDescriptionOpen(!fullDescriptionOpen)}
                            component="span"
                          >
                            {fullDescriptionOpen ? 'View Less' : 'View More'}
                          </Typography>
                          <Box component="span">
                            {fullDescriptionOpen ? (
                              <KeyboardArrowUp style={{ width: '15px', height: '15px' }} />
                            ) : (
                              <KeyboardArrowDown style={{ width: '15px', height: '15px' }} />
                            )}
                          </Box>
                        </Box>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
          </div>

          <Box className={styles.vendor__section__actions}>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              onClick={onContactSeller}
              endIcon={<ArrowForward />}
              className={styles.vendor__contact}
            >
              Negotiate
            </Button>
            {vendor.vrLink && (
              <a href={vendor.vrLink} target="_blank" rel="noopener noreferrer">
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  className={styles.vendor__vr}
                  endIcon={<img src={vrImg} alt="vr" />}
                >
                  View VR
                </Button>
              </a>
            )}
          </Box>
        </div>
      )}
      {vendor && <VendorProductsTab />}
    </>
  );
};
