import {
  getSendbirdSessionToken,
  getVendorById,
  registerMessage,
  unregisterMessage,
  removeUserFromChannel,
} from 'services/orchestrator.service';
import { GroupChannel, MessageModule, ModuleNamespaces, PollModule } from '@sendbird/chat/lib/__definition';
import { ChannelListProvider, useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import GroupChannelHandler from '@sendbird/uikit-react/handlers/GroupChannelHandler';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { SendBirdProvider } from '@sendbird/uikit-react';
import SendbirdChat from '@sendbird/chat';
import { toast } from 'react-toastify';
import { useCustomAuth } from 'hooks';

type HandleContactSellerParams = {
  message?: string;
  vendorName: string;
  vendorRepresentativeEmail: string;
  vendorId?: number;
};

type createChannelParams = {
  vendorRepresentativeEmail: string;
  vendorName: string;
  vendorId?: number;
};

type MessageCenter = {
  APP_ID: string;
  sessionToken: string;
  isChatPopupOpen: boolean;
  setIsChatPopupOpen: Dispatch<SetStateAction<boolean>>;
  currentChannelUrl: string;
  setCurrentChannelUrl: (url: string) => void;
  sb?: SendbirdChat &
    ModuleNamespaces<
      [...GroupChannelModule[], MessageModule, PollModule],
      GroupChannelModule | MessageModule | PollModule
    >;
  hasChannels: boolean;
  showMessageCenter: boolean;
  handleContactSeller: (params: HandleContactSellerParams) => void;
  unreadMessages: number;
  loader: boolean;
};
type HandleMembersProps = {
  channel: GroupChannel;
  vendorRepresentativeEmail: string;
  user: any;
};

const MessageCenterContext = createContext<MessageCenter | undefined>(undefined);

export const MessageCenterProvider = ({ children }) => {
  const APP_ID = process.env.REACT_APP_SEND_BIRD_APP_ID!;
  const { user, isAuthenticated, getAccessTokenSilently } = useCustomAuth();
  const [isChatPopupOpen, setIsChatPopupOpen] = useState<boolean>(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState<string>('');
  const [hasChannels, setHasChannels] = useState<boolean>(false);
  const [showMessageCenter, setShowMessageCenter] = useState<boolean>(false);
  const [sessionToken, setSessionToken] = useState<string>('');
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [lastCallTime, setLastCallTime] = useState(0);
  const [sb, setSb] = useState<
    | (SendbirdChat &
        ModuleNamespaces<
          [...GroupChannelModule[], MessageModule, PollModule],
          GroupChannelModule | MessageModule | PollModule
        >)
    | undefined
  >();

  useEffect(() => {
    if (!user || !isAuthenticated) return;
    const getSendbirdChat = async () => {
      try {
        const sb = SendbirdChat.init({
          appId: APP_ID,
          modules: [new GroupChannelModule()],
        });
        setSb(() => {
          return sb;
        });
      } catch (error) {
        toast.error('Un-expected Error.');
      }
    };
    getSendbirdChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!sb) return;
    const sbConnection = async () => {
      await connectUser();
      const channelsCount = await sb.groupChannel.getGroupChannelCount({});
      if (channelsCount === 0) {
        setLoader(false);
        return;
      }
      setHasChannels(true);
      setLoader(false);
    };
    sbConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sb]);

  const updateUnreadMessages = async () => {
    const newMessages = await sb!.groupChannel.getTotalUnreadMessageCount();
    setUnreadMessages(newMessages);
  };

  const connectUser = async () => {
    if (!user || !sb) return;
    const auth0Token = await getAccessTokenSilently();
    const { token } = await getSendbirdSessionToken({
      auth0Token,
    });
    setSessionToken(token);
    const sbUser = await sb.connect(user.email!, token);
    setShowMessageCenter(true);
    if (sbUser.metaData['hasInfo'] === 'true') return;
    sbUser.createMetaData({ hasInfo: 'true' });
    await sb.updateCurrentUserInfo({
      nickname: user.name,
      profileUrl: user.picture,
    });
  };

  const handleContactSeller = async ({
    message,
    vendorName,
    vendorRepresentativeEmail,
    vendorId,
  }: {
    message?: string;
    vendorName: string;
    vendorRepresentativeEmail: string;
    vendorId?: number;
  }) => {
    if (!user || !sb || !vendorRepresentativeEmail) return;
    let channel = await getChannelByVendorName(vendorName);
    const params: createChannelParams = {
      vendorRepresentativeEmail,
      vendorName,
      vendorId,
    };
    if (!channel) channel = await createChannel(params);
    else {
      await removeMembers({ channel, vendorRepresentativeEmail, user });
      await inviteMembers({ channel, vendorRepresentativeEmail, user });
    }
    if (message)
      setTimeout(() => {
        channel!.sendUserMessage({
          message,
        });
      }, 200);

    await new Promise(f => setTimeout(f, 400));
    setHasChannels(true);
    if (!isChatPopupOpen) setIsChatPopupOpen(!isChatPopupOpen);
    setCurrentChannelUrl(channel.url);
  };

  const getChannelByVendorName = async (vendorName: string) => {
    const params = {
      includeEmpty: true,
      channelNameContainsFilter: vendorName + '-' + user!.email,
    };
    const query = sb!.groupChannel.createMyGroupChannelListQuery(params);

    const channels: GroupChannel[] = await query.next();
    if (channels.length > 0) return channels[0];
  };

  const splitEmailString = (email: string) => {
    const emails = email.split(',').map(e => {
      return e.trim();
    });
    return emails;
  };

  const createChannel = async ({ vendorRepresentativeEmail, vendorName, vendorId }: createChannelParams) => {
    const emails = splitEmailString(vendorRepresentativeEmail);
    const channel = await sb!.groupChannel.createChannelWithUserIds(
      [user?.email!, ...emails],
      undefined,
      vendorName + '-' + user!.email,
      undefined,
      vendorName,
    );
    const vendor = await getVendorById(vendorId!);
    if (vendorId) {
      channel.createMetaData({
        vendorID: vendorId.toString(),
        vendorProfile: vendor?.data.pictureModel.fullSizeImageUrl!,
      });
    }
    return channel;
  };

  const removeMembers = async ({ channel, vendorRepresentativeEmail, user }: HandleMembersProps) => {
    const existingMembers = channel.members.map(member => member.userId);
    const newMembers = vendorRepresentativeEmail.split(',').map(email => email.trim());
    const auth0Token = await getAccessTokenSilently();
    newMembers.push(user.email!);
    const membersToRemove = existingMembers.filter(existingMember => !newMembers.includes(existingMember));
    if (membersToRemove.length > 0) {
      try {
        membersToRemove.map(m => removeUserFromChannel(auth0Token, channel.url, m));
      } catch (error) {
        toast.error('Unexpected Error while removing members.');
      }
    }
  };
  const inviteMembers = async ({ channel, vendorRepresentativeEmail, user }: HandleMembersProps) => {
    const existingMembers = channel.members.map(member => member.userId);
    const newMembers = vendorRepresentativeEmail.split(',').map(email => email.trim());
    newMembers.push(user.email!);
    let membersToInvite = newMembers.filter(newMember => !existingMembers.includes(newMember));
    try {
      if (membersToInvite.length > 0) await channel.inviteWithUserIds(membersToInvite);
    } catch (error) {
      toast.error('Unexpected Error while inviting new members.');
    }
  };

  const groupChannelHandler: GroupChannelHandler = new GroupChannelHandler({
    onChannelChanged: async channel => {
      const { vendorID } = await channel.getAllMetaData();
      const auth0Token = await getAccessTokenSilently();
      let others: string[] = [];
      for (const member of (channel as GroupChannel).members) {
        if (user?.email !== member.userId) others.push(member.userId);
      }
      if ((channel as any)?.lastMessage.sender.userId === user?.email) {
        others.forEach(receiver => {
          registerMessage(
            {
              sender: user?.email as string,
              receiver,
              channel: (channel as any)._name,
              receiverType: receiver === channel.creator?.userId ? 'buyer' : 'vendor',
            },
            auth0Token,
          );
        });
      } else if ((channel as GroupChannel)?.unreadMessageCount === 0) {
        const currentTime = Date.now();
        if (currentTime - lastCallTime >= 500) {
          others.forEach(sender => {
            unregisterMessage(
              {
                sender,
                receiver: user?.email as string,
                channel: (channel as any)._name,
                receiverType: (user?.email as string) === channel.creator?.userId ? 'buyer' : 'vendor',
              },
              auth0Token,
            );
          });
          setLastCallTime(currentTime);
        }
      }
      // this condition is to avoid adding or removing emails in case of negotiate in quotation cause at this time we didn't have sellers registered on the system except for otltradecompany
      if (vendorID) {
        let vendor = await getVendorById(+vendorID);
        await removeMembers({
          channel: channel as GroupChannel,
          vendorRepresentativeEmail: vendor.data.email,
          user,
        });
        await inviteMembers({
          channel: channel as GroupChannel,
          vendorRepresentativeEmail: vendor.data.email,
          user,
        });
      }
    },
  });

  sb?.groupChannel.addGroupChannelHandler('UNIQUE_HANDLER_ID', groupChannelHandler);

  return (
    <MessageCenterContext.Provider
      value={{
        APP_ID,
        sessionToken,
        currentChannelUrl,
        setCurrentChannelUrl,
        isChatPopupOpen,
        setIsChatPopupOpen,
        sb,
        hasChannels,
        showMessageCenter,
        handleContactSeller,
        unreadMessages,
        loader,
      }}
    >
      {children}
      {sb && sb.connectionState === 'OPEN' && (
        <SendBirdProvider appId={APP_ID} accessToken={sessionToken} userId={user?.email!} isVoiceMessageEnabled>
          <ChannelListProvider>
            <NotificationUpdater updateUnreadMessages={updateUnreadMessages} />
          </ChannelListProvider>
        </SendBirdProvider>
      )}
    </MessageCenterContext.Provider>
  );
};

const NotificationUpdater = ({ updateUnreadMessages }) => {
  const { allChannels } = useChannelListContext();

  useEffect(() => {
    updateUnreadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChannels]);

  return <></>;
};

export const useMessageCenterContext = () => {
  const messageCenterContext = useContext(MessageCenterContext);
  if (!messageCenterContext) throw new Error('useMessageCenterContext should be used inside the provider');
  return messageCenterContext;
};
