import { createRfq, getRfqCategory, gRecaptchaVerify } from 'services/orchestrator.service';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useMediaQuery, useTheme } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCustomAuth } from 'hooks';
import * as Yup from 'yup';
import _ from 'lodash';

type SelectItem = { label: string; value: string };

export const useSubmitRfq = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const [rfqCategoryItems, setRfqCategoryItems] = useState<SelectItem[]>([]);
  const [userEmail, setUserEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const { user } = useCustomAuth();

  const formSchema = Yup.object().shape({
    categoryId: Yup.string().required('This field is required'),
    otherCategory: Yup.string(),
    productName: Yup.string()
      .trim()
      .required('This field is required')
      .max(40, 'Product Name must be at most 40 characters'),
    quantity: Yup.number()
      .min(1, 'Quantity must be at least 1')
      .typeError('This field is required')
      .required('This field is required'),
    unit: Yup.string().required('This field is required'),
    email: Yup.string().required('This field is required'),
  });
  const {
    formState: { isValid },
    getValues,
    setValue,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const findOtherCategory = () => {
    const result = rfqCategoryItems.find(item => {
      return item.value === getValues('categoryId');
    });
    return result?.label === 'Other';
  };

  const onSubmit = async () => {
    if (isValid) {
      setLoading(true);
      if (!executeRecaptcha) return;
      executeRecaptcha('enquiryFormSubmit').then(async gRecaptchaResponse => {
        try {
          await gRecaptchaVerify({ gRecaptchaResponse });
          submitRfq();
        } catch (error) {
          toast.error('Un-expected Error.');
        }
      });
    }
  };
  const submitRfq = async () => {
    let otherCategory: string | undefined;
    let categoryId: string | undefined;
    const values = getValues();
    if (values.categoryId === 'Other') {
      if (!values.otherCategory || !values.otherCategory.trim()) {
        otherCategory = 'Other';
      } else otherCategory = values.otherCategory;
    } else categoryId = values!.categoryId;
    try {
      const { data } = await createRfq({
        productName: values.productName,
        email: values.email,
        quantity: +values.quantity,
        categoryId,
        otherCategory,
        unit: values.unit,
      });
      navigate(`/rfq/submit/second-step/${data}`, { replace: true });
    } catch (error: any) {
      if (error.response.data.message[0] === 'email must be an email') {
        toast.error('Please enter a valid email address “example@mail.com“');
      }
      setLoading(false);
    }
  };
  const toListItems = (obj: Record<string, string>) => {
    const listItems: { label: string; value: string }[] = [];
    for (const key in obj) {
      const label = key;
      const value: string = obj[key];
      listItems.push({ label, value });
    }
    return _.sortBy(listItems, ['label']);
  };

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ Submission', link: '/rfq/submit/first-step' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRfqCategory().then(({ data: categories }) => {
      const mappedData = categories.data.map(d => ({
        label: d.name,
        value: d.id,
      }));
      mappedData.push({ label: 'Other', value: 'Other' });
      setRfqCategoryItems(mappedData);
    });
  }, [setValue]);

  useEffect(() => {
    const elements = document.getElementsByTagName('input');
    for (const element of elements) {
      //@ts-ignore
      element.autocomplete = 'new-password';
    }
  }, []);

  useEffect(() => {
    if (user) {
      setUserEmail(user?.email!);
      setValue('email', user?.email!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    findOtherCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()]);

  return {
    rfqCategoryItems,
    onSubmit,
    control,
    isValid,
    findOtherCategory,
    isDownMd,
    userEmail,
    loading,
    toListItems,
  };
};
