import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { ALGOLIA_PRODUCTS_INDEX_NAME } from '../utils';
import { ProductHit } from '../types';
import { searchClient } from 'utils';

export const productsPlugin = {
  getSources({ query }) {
    return [
      {
        sourceId: 'productsPlugin',
        getItems({ setContext }) {
          return getAlgoliaResults({
            // @ts-ignore
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_PRODUCTS_INDEX_NAME,
                query,
                params: {
                  ruleContexts: ['enable-redirect-url'],
                  hitsPerPage: 2,
                },
              },
            ],
            transformResponse({ hits, results }) {
              setContext({
                nbProducts: (results[0] as SearchResponse<ProductHit>).nbHits,
              });

              return hits;
            },
          });
        },
      },
    ];
  },
};
