import { useMessageCenterContext } from 'contexts/message-center-context';
import { ChannelListTitle } from './components/channel-list-title';
import { ChannelHeader } from './components/channel-header';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { ChannelList } from './components/channel-list';
import { Channel } from './components/channel';
import styles from './styles.module.scss';
import { useCustomAuth } from 'hooks';
import { useEffect } from 'react';
import './styles.scss';

export const MessageCenterPopup = () => {
  const { APP_ID, isChatPopupOpen, hasChannels, sessionToken } = useMessageCenterContext();
  const { user } = useCustomAuth();
  useEffect(() => {
    isChatPopupOpen
      ? document.querySelector('body')?.classList.add('overflow-hidden')
      : document.querySelector('body')?.classList.remove('overflow-hidden');
  }, [isChatPopupOpen]);

  if (!hasChannels) return <></>;
  return (
    <SendBirdProvider appId={APP_ID} accessToken={sessionToken} userId={user?.email!} isVoiceMessageEnabled>
      <div className={`${styles.message__center__popup} ${isChatPopupOpen && ' h-[568px]'}`}>
        <div className="w-[280px] flex flex-col">
          <ChannelListTitle />
          {isChatPopupOpen && <ChannelList />}
        </div>

        {isChatPopupOpen && (
          <div className="w-[553px] bg-white flex flex-col">
            <ChannelHeader />
            <div className="h-[515px]">
              <Channel />
            </div>
          </div>
        )}
      </div>
    </SendBirdProvider>
  );
};
