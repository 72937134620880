import { createContext, useState, useContext } from 'react';

type LoadingContextProps = {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
};

const LoadingContext = createContext<LoadingContextProps | null>(null);

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return <LoadingContext.Provider value={{ isLoading, startLoading, stopLoading }}>{children}</LoadingContext.Provider>;
};

export const useLoading = () => {
  const loadingContext = useContext(LoadingContext);
  if (!loadingContext) {
    throw new Error('useBreadCrumb must be used within BreadcrumbsProvider');
  }
  return loadingContext;
};
