import { Navigate } from 'react-router-dom';
import { Loader } from 'design-system';
import { useCustomAuth } from 'hooks';

type ProtectedAuthenticatedRouteProps = {
  component: any;
  url: string;
  state?: string;
};

export const ProtectedAuthenticatedRoute = ({ component, url, state }: ProtectedAuthenticatedRouteProps) => {
  const { isAuthenticated, isLoading } = useCustomAuth();

  if (isLoading) return <Loader />;
  else if (isAuthenticated) return component;
  else if (state) return <Navigate to={url} replace state={{ route: state }} />;
  else return <Navigate to={url} />;
};
