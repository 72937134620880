import { CategorySimpleModelDto } from 'codegen';
import { BottomNavigation } from 'components';
import { Box, Divider } from '@mui/material';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useCategories } from 'hooks';

export const AllCategories = () => {
  const { rootCategories, subCategories, setSubCategories, currentCategory, setCurrentCategory } = useCategories();
  const onClickItem = (category: CategorySimpleModelDto) => {
    category.subCategories ? setSubCategories(category.subCategories) : setSubCategories(null);
    setCurrentCategory(category);
  };

  return (
    <>
      <Box className="inline-flex w-full h-[84vh]">
        <Box className={styles.categories__container}>
          {rootCategories?.map(category => (
            <Box
              onClick={() => onClickItem(category)}
              className={`${styles.category__item} ${currentCategory === category ? styles.selected : ''}`}
            >
              <Box className="flex items-center">
                {/* <img
                  src={category?.iconModel?.fullSizeImageUrl || ''}
                  alt={category?.iconModel?.alternateText || ''}
                  className={styles.category__icon}
                /> */}
                <Typography variant="body1" className="text-midDarkGray">
                  {category.name}
                </Typography>
              </Box>

              {currentCategory === category && (
                <Divider
                  orientation="vertical"
                  sx={{ backgroundColor: 'var(--deepYellow)', borderWidth: '2px', height: 'auto' }}
                />
              )}
            </Box>
          ))}
          <Box className="w-full h-[53px]"></Box>
        </Box>

        <Box className={styles.sub__categories__container}>
          {currentCategory && (
            <Link to={`/category/${currentCategory.id}/${currentCategory.seName}`}>
              <Typography variant="body1" className="mb-4 text-midDarkGray">
                View All
              </Typography>
            </Link>
          )}

          {subCategories?.map(subCategory => (
            <Link key={`sub-category-${subCategory.id}`} to={`/category/${subCategory.id}/${subCategory.seName}`}>
              <Typography variant="body1" className="mb-4 text-midDarkGray">
                {subCategory.name}
              </Typography>
            </Link>
          ))}
        </Box>
      </Box>
      <BottomNavigation />
    </>
  );
};
