import algoliasearch from 'algoliasearch';
// import { isIPInSubnet } from 'utils';
// import axios from 'axios';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID!;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY!;

export const searchClient = algoliasearch(appId, apiKey);
// export const algoliaClient = algoliasearch(appId, apiKey);

// export const searchClient: SearchClient = {
//   ...algoliaClient,
//   async search(requests) {
//     const newRequests = await Promise.all(
//       requests.map(async request => {
//         const response = await axios.get('https://api64.ipify.org?format=json');
//         const params = request.params || {};

//         const newParams = {
//           ...request.params,
//           clickAnalytics: isIPInSubnet(response.data.ip, '41.196.3.0/28') ? false : params,
//         };

//         return { ...request, params: newParams };
//       }),
//     );

//     return algoliaClient.search(newRequests as any);
//   },
// };
