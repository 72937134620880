import { ReactComponent as RfqArt2 } from 'assets/images/rfq-arts/second-step-rfq-art.svg';
import { ReactComponent as UploadIcon } from 'design-system/public/icons/upload.svg';
import { Typography, TextInput, FileDropzone, Loader } from 'design-system';
import { Box, Grid, Hidden } from '@mui/material';
import { Stepper } from '../components/stepper';
import { useSubmitRfq } from './useSubmitRfq';
import styles from './styles.module.scss';
import { LoadingButton } from '@mui/lab';

export const RfqSecondStep = () => {
  const { setFiles, onSubmit, control, acceptedFileTypes, isDownMd, pageLoading, requestLoading } = useSubmitRfq();
  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  if (pageLoading) return <Loader />;
  return (
    <>
      <Grid container spacing={0}>
        <Hidden mdDown>
          <Grid item xs={12} md={4.7}>
            <Box className={styles.rfq__art}>
              <Typography variant="h6" className="text-violet max-w-[358px] text-center">
                Do you have any specific requirement?!
              </Typography>
              <RfqArt2 />
              <Typography variant="subtitle2" className="text-midDarkGray pt-3">
                More data will help us provide you with the best experience
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={7.3}>
          <Box className={styles.rfq__form}>
            <Grid container spacing={isDownMd ? 2.5 : 5} className="justify-center w-full">
              <Grid item xs={12} md={9}>
                <Stepper currentStep={2} />
              </Grid>

              <Hidden mdUp>
                <Grid item xs={12} md={9}>
                  <Typography variant="h6" className="text-violet">
                    More data will help us provide you with the best experience
                  </Typography>
                </Grid>
              </Hidden>

              <Grid item xs={isDownMd ? 12 : 9}>
                <Typography variant="subtitle1" className="text-darkGray pb-2">
                  Upload files
                </Typography>
                <FileDropzone
                  multiple={true}
                  maxSize={5 * 1024 * 1024}
                  acceptedFileTypes={acceptedFileTypes}
                  setFiles={setFiles}
                  icon={<UploadIcon width={isDownMd ? 24 : 38} height={isDownMd ? 24 : 38} />}
                  helperText="or select max of 5 files to be uploaded with max size of 5MB each (supported types: .txt, .doc, .docx, .xls, xlsx, .pdf, .jpg, .jpeg, .png, .csv)"
                  maxFiles={5}
                />
              </Grid>

              <Grid item xs={isDownMd ? 12 : 9}>
                <TextInput
                  name="description"
                  label="Detailed requirements"
                  placeholder="I'm looking for..."
                  control={control}
                  rows={4}
                  multiline
                />
              </Grid>

              <Grid item xs={12} md={9}>
                <LoadingButton
                  size="large"
                  variant="contained"
                  className="w-full"
                  onClick={onSubmit}
                  loading={requestLoading}
                >
                  Next
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
