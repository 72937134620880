import { useMessageCenterContext } from 'contexts/message-center-context';
import { getVendorById } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { htmlToText, sendEventToGTM } from 'utils';
import { useEffect, useState } from 'react';
import { VendorModelDto } from 'codegen';
import { useCustomAuth } from 'hooks';

export const useVendorDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { handleContactSeller, currentChannelUrl } = useMessageCenterContext();
  const { isAuthenticated, loginWithRedirect } = useCustomAuth();
  const { updateBreadcrumbs } = useBreadcrumbs();

  const [vendor, setVendor] = useState<VendorModelDto>();
  const [contactSellerClicked, setContactSellerClicked] = useState(false);
  const [descriptionTextResp, setDescriptionTextResp] = useState<string | null | undefined>();
  const [fullDescriptionOpen, setFullDescriptionOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<boolean>(false);

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    updateBreadcrumbs([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vendor) {
      sendEventToGTM('view_vendor_profile', {
        vendor_id: vendor?.id,
        vendor_name: vendor?.name,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor?.id]);

  useEffect(() => {
    vendor?.description && setDescriptionTextResp(htmlToText(vendor?.description)?.substring(0, 65).concat('...'));
  }, [vendor]);

  useEffect(() => {
    if (vendor?.description)
      if (fullDescriptionOpen) {
        setDescriptionTextResp(htmlToText(vendor?.description));
      } else setDescriptionTextResp(htmlToText(vendor?.description)?.substring(0, 65).concat('...'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullDescriptionOpen]);

  useEffect(() => {
    if (vendor) {
      updateBreadcrumbs([
        {
          label: `${vendor.name}`,
          link: `vendor-details/${vendor?.id}`,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  useEffect(() => {
    setIsLoading(true);
    getVendorById(+params.vendorId!)
      .then(res => {
        setVendor(res?.data);
        setErrorState(false);
      })
      .catch(() => {
        setErrorState(true);
      });
    setIsLoading(false);
  }, [params.vendorId]);

  useEffect(() => {
    if (isDownMd && currentChannelUrl !== '' && contactSellerClicked) {
      navigate(`/channels/${currentChannelUrl}`);
      setContactSellerClicked(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelUrl, contactSellerClicked]);

  const onContactSeller = () => {
    if (isAuthenticated && vendor) {
      setContactSellerClicked(true);
      const message = 'Hi, I am interested in some products you listed and need more details.';
      handleContactSeller({
        message,
        vendorName: vendor.name,
        vendorRepresentativeEmail: vendor.email,
        vendorId: vendor.id,
      });
      sendEventToGTM('vendor_details_page_negotiate', {
        vendor_id: vendor.id,
        vendor_name: vendor.name,
      });
    } else loginWithRedirect({ redirectUri: window.location.pathname });
  };

  return {
    vendor,
    onContactSeller,
    isDownMd,
    descriptionTextResp,
    fullDescriptionOpen,
    setFullDescriptionOpen,
    isLoading,
    errorState,
  };
};
