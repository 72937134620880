import { Box, Hidden, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { Typography } from 'design-system';

export const PromotionsBannersSkeleton = () => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Box sx={{ marginBottom: { xs: '24px', md: '60px' } }}>
      <Hidden mdUp>
        <Typography variant="h3" className="w-1/3 mb-4 max-sm:mb-3">
          <Skeleton variant="text" animation="wave" />
        </Typography>
      </Hidden>

      <Skeleton
        variant="rectangular"
        height={isDownMd ? 115 : 285}
        sx={{ marginBottom: { xs: '24px', md: '60px' } }}
        animation="wave"
      />
    </Box>
  );
};
