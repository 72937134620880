import { useCustomAuth } from 'hooks';
import { useEffect } from 'react';
import './styles.scss';

export const ChatBot = () => {
  const { isAuthenticated } = useCustomAuth();

  useEffect(() => {
    let timeOutID = setTimeout(() => {
      if (!isAuthenticated) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.src = '//js-eu1.hs-scripts.com/139844242.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      }
    }, 2000);
    return () => {
      clearTimeout(timeOutID);
    };
  }, [isAuthenticated]);

  return null;
};
