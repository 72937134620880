import { GetPromotionsDto } from 'services/dtos/hubspot/GetPromotions.dto';
import { Box, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { FreeMode, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

type PromotionsProps = {
  promotions: GetPromotionsDto;
};

export const PromotionsBanners = ({ promotions }: PromotionsProps) => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const promotionsSwiperProps: SwiperProps = {
    direction: 'horizontal',
    slidesPerView: 1,
    modules: [FreeMode, Pagination, Autoplay],
    slideToClickedSlide: true,
    pagination: {
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };

  if (!promotions.results || promotions.total === 0) return <></>;
  else if (promotions.results)
    return (
      <>
        {promotions.total && promotions.total > 0 && (
          <Box sx={{ marginBottom: { xs: '24px', md: '60px' } }}>
            <Hidden mdUp>
              <Typography variant="h3" component="h3" className="text-darkGray mb-4 max-sm:mb-3">
                Hey, Welcome
              </Typography>
            </Hidden>

            <Swiper {...promotionsSwiperProps}>
              {promotions.results.map(promotion => {
                const redirectUrl = promotion.values?.redirect_url;
                const imageUrl = isDownMd ? promotion.values?.image_responsive?.url : promotion.values?.image?.url;

                return (
                  <SwiperSlide
                    key={`hero_banner_${promotion.id}`}
                    style={{ backgroundImage: `url(${imageUrl})` }}
                    className={styles.banner__slide}
                  >
                    {redirectUrl && <Link to={redirectUrl} />}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        )}
      </>
    );
};
