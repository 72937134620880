import { ReactComponent as ShopIcon } from 'design-system/public/icons/shop.svg';
import { Button, Typography } from 'design-system';
import { Link } from 'react-router-dom';
import { sendEventToGTM } from 'utils';

export const BecomeASeller = () => {
  const backstoreURL = process.env.REACT_APP_BACKSTORE_BASE_URL;

  return (
    <Button
      variant="text"
      startIcon={<ShopIcon />}
      className="text-midDarkGray"
      color="inherit"
      onClick={() => sendEventToGTM('become_seller')}
    >
      <Link to={`https://${backstoreURL}/redirecting-signup`}>
        <Typography variant="body1">Become a Seller</Typography>
      </Link>
    </Button>
  );
};
