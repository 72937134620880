import { getRfqByToken, getRfqSignedUrl, updateSecondStepRfq } from 'services/orchestrator.service';
import { RfqStatusEnum } from 'services/types/generic/enums';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { RFQ } from 'services/types/generic/rfq-entity';
import { useMediaQuery, useTheme } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import axios from 'axios';

export const useSubmitRfq = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const [files, setFiles] = useState<File[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const formSchema = Yup.object().shape({
    description: Yup.string().optional(),
  });
  const { getValues, control } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(formSchema),
  });

  const acceptedFileTypes = {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    'text/plain': ['.txt'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'text/csv': ['.csv'],
  };
  const token = pathname.split('/').pop();

  const onSubmit = async () => {
    const values = getValues();
    setRequestLoading(true);

    for (const file of files) {
      if (token) {
        const { data: url } = await getRfqSignedUrl({
          token,
          fileName: file?.name!,
        });
        await axios.put(url, file, {
          headers: { 'Content-Type': 'application/octet-stream' },
        });
      }
    }
    try {
      await updateSecondStepRfq({ body: { description: values.description || '' }, token: token! });
      navigate(`/rfq/submit/third-step/${token}`, { replace: true });
    } catch {
      setRequestLoading(false);
    }
  };

  const checkRfq = async (rfq: RFQ) => {
    if (rfq?.status !== RfqStatusEnum.Draft) {
      navigate(`/rfq/details/${rfq.id}`, { replace: true });
    } else if (rfq.description !== null) {
      navigate(`/rfq/submit/third-step/${token}`, { replace: true });
    }
  };

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ Submission', link: '/rfq/submit/second-step' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const elements = document.getElementsByTagName('input');
    for (const element of elements) {
      //@ts-ignore
      element.autocomplete = 'new-password';
    }
  }, []);

  useEffect(() => {
    if (token) {
      const decodedToken: JwtPayload = jwtDecode(token);
      if (decodedToken.exp! * 1000 < Date.now()) {
        navigate(`/rfq/email-expired`);
        setPageLoading(false);
      }
      getRfqByToken(token).then(rfqData => {
        checkRfq(rfqData.data);
        setPageLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    setFiles,
    onSubmit,
    control,
    acceptedFileTypes,
    isDownMd,
    pageLoading,
    requestLoading,
  };
};
