import { Card, CardActionArea, CardMedia, CardContent, Typography, Box, Chip, Hidden } from '@mui/material';
import { Blog } from 'services/dtos/hubspot/GetBlogs.dto';
import styles from './styles.module.scss';
import moment from 'moment';

type Props = {
  blog: Blog;
};

export const BlogCard = ({ blog }: Props) => {
  const publishedAt = moment(blog.values?.publish_date).format('MMMM DD, YYYY');

  return (
    <Card className={styles.card} elevation={0}>
      <CardActionArea className={styles.card__action__area}>
        <CardMedia
          id="card-media"
          component="img"
          image={blog.values?.image?.url}
          alt={blog.values?.image?.altText}
          className={styles.card__media}
        />
        <CardContent className={styles.card__content}>
          {blog.values?.tags &&
            blog.values?.tags.map(tag => {
              return <Chip key={tag.id} className={styles.chip} label={tag.label} color="primary" size="small" />;
            })}

          <Typography variant="h4" component="h4" className="text-darkGray line-clamp-2 max-sm:line-clamp-3">
            {blog.values?.title}
          </Typography>

          <Hidden mdDown>
            <Box className={styles.card__footer}>
              <Box className={styles.card__avatar__name}>
                <img id="author-image" src={blog.values?.author_image?.url} alt={blog.values?.author_image?.altText} />
                <Typography variant="body1" id="author" className="text-midLightGray line-clamp-1">
                  {blog.values?.author}
                </Typography>
              </Box>
              <Typography variant="body1" id="published-at" className="text-midLightGray line-clamp-1">
                {publishedAt}
              </Typography>
            </Box>
          </Hidden>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
