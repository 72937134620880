import { MessageCenterProvider } from 'contexts/message-center-context';
import { BreadcrumbsProvider } from 'contexts/breadcrumbContext';
import { LoadingProvider } from 'contexts/loadingContext';
import { ToastWrapper, baseTheme } from 'design-system';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import 'design-system/public/tailwind.css';
import ReactDOM from 'react-dom/client';
import { ChatBot } from 'components';
import * as React from 'react';
import { App } from './App';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <LoadingProvider>
      <ThemeProvider theme={baseTheme}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
          cacheLocation={'localstorage'}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/callback`,
            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN!}/api/v2/`,
          }}
        >
          <ChatBot />
          <MessageCenterProvider>
            <BreadcrumbsProvider>
              <ToastWrapper>
                <App />
                <ToastContainer />
              </ToastWrapper>
            </BreadcrumbsProvider>
          </MessageCenterProvider>
        </Auth0Provider>
      </ThemeProvider>
    </LoadingProvider>
  </React.StrictMode>,
);

reportWebVitals();
