import { createContext, useContext, useState } from 'react';

type Breadcrumb = {
  breadcrumbItems: BreadcrumbItem[];
  updateBreadcrumbs: (newBreadcrumbs: BreadcrumbItem[]) => void;
};

export type BreadcrumbItem = {
  label: string | null;
  link: string;
};

const BreadcrumbsContext = createContext<Breadcrumb | null>(null);

export const BreadcrumbsProvider = ({ children }) => {
  const baseBreadCrumbItem = { label: 'Home', link: '/' };

  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([baseBreadCrumbItem]);

  const updateBreadcrumbs = (newBreadcrumbs: BreadcrumbItem[]) => {
    setBreadcrumbItems([baseBreadCrumbItem, ...newBreadcrumbs]);
  };

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbItems, updateBreadcrumbs }}>{children}</BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  const breadCrumbContext = useContext(BreadcrumbsContext);
  if (!breadCrumbContext) {
    throw new Error('useBreadCrumb must be used within BreadcrumbsProvider');
  }
  return breadCrumbContext;
};
