import styles from './styles.module.scss';
import { useState } from 'react';

export const ImageMagnifier = ({ imageUrl }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseHover = e => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setCursorPosition({ x, y });
  };

  return (
    <div className={styles.img__magnifier__container} onMouseMove={handleMouseHover}>
      <img
        className={styles.magnifier__img}
        src={imageUrl}
        alt=""
        style={{
          transformOrigin: `${cursorPosition.x}% ${cursorPosition.y}%`,
        }}
      />
    </div>
  );
};
