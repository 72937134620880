import { ChannelList as SendBirdChannelList } from '@sendbird/uikit-react';
import { ChannelPreview } from '../channel-preview';
import '@sendbird/uikit-react/dist/index.css';
import styles from './styles.module.scss';
import './styles.scss';

export const ChannelList = () => {
  return (
    <div className={`${styles.custom__channel__list}`}>
      <SendBirdChannelList
        renderHeader={() => <></>}
        renderChannelPreview={props => <ChannelPreview channel={props.channel} />}
      />
    </div>
  );
};
