import { ReactComponent as SearchIcon } from 'design-system/public/icons/search.svg';
import { AutocompleteState } from '@algolia/autocomplete-core';
import { getCollectionBySourceId } from '../../functions';
import { SectionHeader } from '../section-header';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'design-system';
import { PopularHit } from '../../types';
import './styles.scss';

type PopularItemProps = {
  hit: PopularHit;
};
type Props = {
  autocomplete: any;
  autocompleteState: AutocompleteState<any>;
};

const PopularItem = ({ hit }: PopularItemProps) => {
  return (
    <div className="aa-ItemWrapper">
      <div className="aa-ItemIcon aa-ItemIcon--noBorder">
        <SearchIcon fill="var(--midLightGray)" width={12} height={12} />
      </div>
      <div className="aa-ItemContent">
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <Typography variant="subtitle2" className="text-midDarkGray">
              {hit.query}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PopularSearches = ({ autocomplete, autocompleteState }: Props) => {
  const popular = getCollectionBySourceId(autocompleteState, 'popularPlugin');
  const navigate = useNavigate();

  return (
    <>
      {popular && popular?.items.length > 0 && (
        <>
          <SectionHeader title="Popular Searches" />

          <div className="aa-PanelSection--popular">
            <section className="aa-Source">
              <ul className="aa-List" {...autocomplete.getListProps()}>
                {popular?.items.map(item => {
                  return (
                    <li
                      key={item?.objectID}
                      className="aa-Item"
                      onClick={() => navigate(`/products?search=${item.query}`, { state: { searchName: item.query } })}
                    >
                      <PopularItem hit={item} />
                    </li>
                  );
                })}
              </ul>
            </section>
          </div>
        </>
      )}
    </>
  );
};
