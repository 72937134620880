import { InputLabelProps as MuiInputLabelProps, InputLabel as MuiInputLabel } from '@mui/material';
import { Typography } from 'src/index';

type InputLabelProps = {
  label?: string;
  labelColor?: string;
} & MuiInputLabelProps;

export const InputLabel = (props: InputLabelProps) => {
  return (
    <MuiInputLabel className="mb-[6px] flex" {...props} style={{ color: props.labelColor }}>
      <Typography variant="subtitle1" style={{ color: props.labelColor }}>
        {props.label}
      </Typography>
    </MuiInputLabel>
  );
};
