import React, { PropsWithChildren } from 'react';
import './styles.css';

export const AppContainer: React.FC<PropsWithChildren<any>> = ({ children, ...props }) => {
  return (
    <div {...props} className="container-custom">
      {children}
    </div>
  );
};
