import { AutocompleteState } from '@algolia/autocomplete-core';
import { getCollectionBySourceId } from '../../functions';
import { SectionHeader } from '../section-header';
import { PopularCategoryHit } from '../../types';
import { Typography } from 'design-system';
import './styles.scss';

type CategoryItemProps = {
  hit: PopularCategoryHit;
};

const CategoryItem = ({ hit }: CategoryItemProps) => {
  return (
    <div className="aa-ItemWrapper aa-PopularCategoryItem">
      <div className="aa-ItemContent">
        <div className="aa-ItemPicture">{/* <img src={images[hit.label]} alt={hit.label} /> */}</div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <Typography variant="body1" className="line-clamp-1 text-midDarkGray">
              {hit.label}&nbsp;<span>({hit.count})</span>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

type Props = {
  autocomplete: any;
  autocompleteState: AutocompleteState<any>;
};

export const PopularCategories = ({ autocomplete, autocompleteState }: Props) => {
  const popularCategories = getCollectionBySourceId(autocompleteState, 'popularCategoriesPlugin');

  return (
    <>
      {popularCategories && popularCategories.items.length > 0 && (
        <>
          <SectionHeader title="Popular Categories" />

          <div className="aa-Source">
            <ul className="aa-List" {...autocomplete.getListProps()}>
              {popularCategories.items.map((item, idx) => (
                <li
                  key={`popular-category-item-#${idx}`}
                  className="aa-Item"
                  {...autocomplete.getItemProps({
                    item,
                    source: popularCategories.source,
                  })}
                >
                  <CategoryItem hit={item} />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};
