import { ChannelHeaderResponsive } from 'pages/messaging-center/components/channel-header-responsive';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { AppBar } from '@mui/material';
import { useCustomAuth } from 'hooks';
import { Channel } from 'components';

export const MessageCenterChannel = () => {
  const { APP_ID, sessionToken } = useMessageCenterContext();
  const { user } = useCustomAuth();
  return (
    <SendBirdProvider appId={APP_ID} accessToken={sessionToken} userId={user?.email!} isVoiceMessageEnabled>
      <>
        <AppBar position="sticky" elevation={1}>
          <ChannelHeaderResponsive />
        </AppBar>

        <div className="h-[94vh]">
          <Channel />
        </div>
      </>
    </SendBirdProvider>
  );
};
