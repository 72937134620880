import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import styles from '../../styles.module.scss';
import { HTMLAttributes } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

type Props = {
  stroke: string;
} & HTMLAttributes<HTMLDivElement>;

export const VerticalArrowPrev = ({ stroke, ...props }: Props) => {
  return (
    <Box className={classNames(styles.arrow__circle, props.className)}>
      <CaretRight stroke={stroke} strokeWidth={1} width={24} height={24} style={{ transform: 'rotate(270deg)' }} />
    </Box>
  );
};
