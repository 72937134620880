import { HTMLAttributes } from 'react';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
} & HTMLAttributes<HTMLDivElement>;

export const TabPanel = ({ children, index, value, ...props }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <>{children}</>}
    </div>
  );
};
