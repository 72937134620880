import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { GetFaqsDto } from 'services/dtos/hubspot/GetFaqs.dto';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { HtmlParser } from 'components';
import { useState } from 'react';

type FaqsProps = {
  faqs: GetFaqsDto;
};

export const Faqs = ({ faqs }: FaqsProps) => {
  const [expanded, setExpanded] = useState<number | false>(0);
  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!faqs.results) return <></>;

  return (
    <>
      <Typography variant="h3" component="h3" className="text-darkGray mb-4 max-sm:mb-3">
        FAQ
      </Typography>

      {faqs.results.map((faq, idx) => (
        <Accordion
          key={`faq-item-${faq.id}`}
          elevation={0}
          className="mb-4"
          expanded={expanded === idx}
          onChange={handleChange(idx)}
        >
          <AccordionSummary
            expandIcon={<CaretRight className={styles.accordion__icon} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6" component="h6" className="text-darkGray">
              {faq.values?.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <HtmlParser htmlString={faq.values?.description} className={styles.faq__description} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
