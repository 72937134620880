import { ReactComponent as UnCheckedIcon } from 'design-system/public/icons/check_box_disabled.svg';
import { ReactComponent as CheckedIcon } from 'design-system/public/icons/check_box_enabled.svg';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Typography } from 'design-system';

type Props = {
  items: any[];
  onChangeCheckBox: any;
  canToggleShowMore: boolean;
  toggleShowMore: any;
  isShowingMore: boolean;
};

export const AlgoliaCheckBoxFilter = ({
  items,
  onChangeCheckBox,
  canToggleShowMore,
  toggleShowMore,
  isShowingMore,
}: Props) => {
  return (
    <FormGroup>
      {items?.map((item: any, idx: number) => (
        <FormControlLabel
          key={`item ${idx}`}
          control={
            <Checkbox
              icon={<UnCheckedIcon />}
              checkedIcon={<CheckedIcon fill="var(--magentaPink)" />}
              onChange={(e, v) => onChangeCheckBox(e, v)}
              value={item.value || ''}
              checked={item.isRefined}
            />
          }
          label={
            <Typography
              variant="body1"
              className={`line-clamp-1 ${item.isRefined ? 'text-midDarkGray' : 'text-midLightGray'}`}
            >
              {item.label}
            </Typography>
          }
          sx={{ display: 'flex', alignItems: 'center' }}
        />
      ))}

      {canToggleShowMore && (
        <button onClick={toggleShowMore} className="w-24 text-deepYellow mt-4 text-start">
          {isShowingMore ? 'Show less' : 'Show more'}
        </button>
      )}
    </FormGroup>
  );
};
