export class Pagination {
  skip?: number;
  take?: number;
}

export enum SortPropertyEnum {
  CreatedAt = 'CreatedAt',
}

export enum SortOptionEnum {
  asc = 'asc',
  desc = 'desc',
}

export class Query {
  pagination?: Pagination;
  sortProperty?: SortPropertyEnum;
  productName?: string;
  description?: string;
  sortValue?: SortOptionEnum;
}
