import { HTMLAttributes } from 'react';
import parse from 'html-react-parser';

type Props = {
  htmlString: any;
} & HTMLAttributes<HTMLDivElement>;

export const HtmlParser = ({ htmlString, ...props }: Props) => {
  let parsedHTML: any;

  if (htmlString) {
    parsedHTML = parse(htmlString);
  }

  return <div {...props}>{parsedHTML}</div>;
};
