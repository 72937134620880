import { useLocation } from 'react-router-dom';
import { Loader } from 'design-system';
import { useCustomAuth } from 'hooks';
import { useEffect } from 'react';

export const Login = () => {
  const { loginWithRedirect } = useCustomAuth();
  const location = useLocation();

  useEffect(() => {
    loginWithRedirect({ redirectUri: location.state?.route ? `/${location.state.route}` : undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
};
