import { capitalCasePattern, letterNumberSymbolPattern } from 'utils';
import * as Yup from 'yup';

export const passwordValidation = (message: string) =>
  Yup.string()
    .required('Password is required')
    .min(6, message)
    .max(20, message)
    .test({
      name: 'isValidLetterNumberSymbolPattern',
      message,
      test: function (value: string | undefined) {
        if (!value) {
          return true;
        }
        if (letterNumberSymbolPattern.test(value)) return true;
        else return false;
      },
    })
    .test({
      name: 'hasACapitalLetter',
      message,
      test: function (value: string | undefined) {
        if (!value) {
          return true;
        }
        if (capitalCasePattern.test(value)) return true;
        else return false;
      },
    });
