import { ReactComponent as InstagramLogo } from 'design-system/public/logo/instagram.svg';
import { ReactComponent as FacebookLogo } from 'design-system/public/logo/facebook.svg';
import { ReactComponent as LinkedinLogo } from 'design-system/public/logo/linkedin.svg';

type SiteMap = {
  title: string;
  links: { title: string; link?: string; options?: Record<string, string>; click?: any; register?: boolean }[];
};

export const siteMap: SiteMap[] = [
  {
    title: 'Get to know us',
    links: [
      {
        title: 'About otrovato',
        link: '/about-otrovato',
        options: { target: '_blank' },
      },
      {
        title: 'Terms & Conditions',
        link: '/terms-and-conditions',
        options: { target: '_blank' },
      },
      {
        title: 'Privacy Policy',
        link: '/privacy-policy',
        options: { target: '_blank' },
      },
      {
        title: 'Contact Us',
        link: '/contact-us',
      },
      {
        title: 'Blogs',
        link: '/blogs',
      },
    ],
  },
];

export const socialMediaLinks = [
  {
    icon: <LinkedinLogo />,
    link: 'https://www.linkedin.com/company/otrovato',
  },
  {
    icon: <FacebookLogo />,
    link: 'https://www.facebook.com/otrovatocom',
  },
  {
    icon: <InstagramLogo />,
    link: 'https://www.instagram.com/otrovatocom/',
  },
];
