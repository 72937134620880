import { ReactComponent as CompanyLogo } from 'design-system/public/logo/Logo.svg';
import { RequestQuotation } from '../request-quotation';
import { BecomeASeller } from '../../become-a-seller';
import { AccountMenu } from '../../account-menu';
import { AppContainer } from 'design-system';
import { CategoriesMenu } from 'components';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export const HomeMainHeader = () => {
  return (
    <AppContainer>
      <Box className={styles.home__main__header__container}>
        <Box className={styles.nav__left}>
          <Link to={'/home'}>
            <CompanyLogo className="h-[40px]" />
          </Link>
          <CategoriesMenu />
          <BecomeASeller />
          <RequestQuotation />
        </Box>

        <Box className={styles.nav__right}>
          <AccountMenu className={styles.account__menu} />
        </Box>
      </Box>
    </AppContainer>
  );
};
