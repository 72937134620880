import { Box, Hidden, MenuItem, Select, Typography } from '@mui/material';
import { ExpandLess, ExpandMore, Sort } from '@mui/icons-material';
import { useSortBy } from 'react-instantsearch';
import { useState } from 'react';

export const AlgoliaSort = () => {
  const [open, setOpen] = useState(false);

  const sortOptions = [
    {
      label: 'Default',
      value: process.env.REACT_APP_ALGOLIA_INDEX!,
      default: true,
    },
    {
      label: 'Price: Low to High',
      value: process.env.REACT_APP_ALGOLIA_INDEX_PRICE_ASC!,
    },
    {
      label: 'Price: High to Low',
      value: process.env.REACT_APP_ALGOLIA_INDEX_PRICE_DESC!,
    },
    {
      label: 'Name: AtoZ',
      value: process.env.REACT_APP_ALGOLIA_INDEX_NAME_ASC!,
    },
    {
      label: 'Name: ZtoA',
      value: process.env.REACT_APP_ALGOLIA_INDEX_NAME_DESC!,
    },
  ];
  const { options, refine, canRefine, currentRefinement } = useSortBy({
    items: sortOptions,
  });

  const foundOption = options.find(option => option.value === currentRefinement);

  return (
    <Select
      open={open}
      sx={{ width: 'auto', fontSize: '13px', height: '40px' }}
      defaultValue={process.env.REACT_APP_ALGOLIA_INDEX}
      variant="standard"
      disableUnderline
      renderValue={() => {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Sort className="text-midLightGray" />

            <Hidden mdDown>
              <Typography className="text-midLightGray" variant="body1">
                Sort by:
              </Typography>{' '}
              <Typography className="text-darkGray" variant="body1">
                {foundOption && foundOption.label}
              </Typography>
            </Hidden>
          </Box>
        );
      }}
      onChange={event => {
        refine(event.target.value);
      }}
      disabled={!canRefine}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      IconComponent={() => (
        <div className="cursor-pointer absolute right-0 text-midLightGray" onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      )}
      MenuProps={{
        sx: {
          '&& .Mui-selected': {
            backgroundColor: 'var(--secondaryLight)',
          },
        },
      }}
    >
      {options &&
        options.map(
          option =>
            option.value && (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ),
        )}
    </Select>
  );
};
