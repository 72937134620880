import { Pagination as MuiPagination } from '@mui/material';
import { usePagination } from 'react-instantsearch';

export const AlgoliaPagination = () => {
  const { nbPages, currentRefinement, refine, nbHits } = usePagination();

  const onChangePage = (value: number) => {
    refine(value - 1);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' if you want instant scrolling
      });
    }, 150);
  };

  return (
    <>
      {nbHits > 0 && (
        <MuiPagination
          count={nbPages}
          size="large"
          variant="text"
          page={currentRefinement + 1 || 1}
          onChange={(e, value) => onChangePage(value)}
        />
      )}
    </>
  );
};
