import {
  AlgoliaClearRefinements,
  AlgoliaRefinementList,
  AlgoliaResultsHeader,
  AlgoliaHitsPerPage,
  AlgoliaPagination,
  AlgoliaSlider,
  AlgoliaHits,
  AlgoliaSort,
} from 'components';
import { useClearRefinements, useInstantSearch, useSearchBox } from 'react-instantsearch';
import { ReactComponent as CloseIcon } from 'design-system/public/icons/close.svg';
import FilterButtons from 'pages/product-details/components/filter-buttons';
import { Box, Grid, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { Button, Typography } from 'design-system';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { HasNoResult } from 'components';

export const Products = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const { refine: setQuery } = useSearchBox();
  const { results } = useInstantSearch();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { refine } = useClearRefinements();

  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Search Results', link: '/products' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state?.searchName) {
      setQuery(location.state?.searchName);
    } else if (search) {
      setQuery(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setQuery]);

  const getHasNoResults = () => {
    return results.nbHits === 0 || (!location.state?.searchName && !search);
  };

  return (
    <>
      {getHasNoResults() && (
        <HasNoResult
          title="It seems like your search is not available right now!"
          description="Please provide us with more details by submitting a"
          buttonComponent={
            <Link to="/rfq/submit/first-step" id="RequestForQuotationLink">
              <Button variant="text">Request for Quotation</Button>
            </Link>
          }
        />
      )}

      {!getHasNoResults() && (
        <>
          <Grid container spacing={{ xs: 0, md: 3 }} className="mt-6 max-sm:mt-5">
            <Grid
              item
              xs={12}
              md={2.5}
              className={`pt-0 ${isDownMd && !filterDrawerOpen && 'hidden'} ${
                isDownMd && filterDrawerOpen && 'pb-[72px]'
              }`}
            >
              <Box className={styles.filter__box}>
                <Box className="flex justify-between items-center mb-4">
                  <Typography variant="h5" component="h5" className="capitalize text-darkGray">
                    Filter items
                  </Typography>
                  <Hidden mdDown>
                    <AlgoliaClearRefinements />
                  </Hidden>
                  <Hidden mdUp>
                    <CloseIcon className="w-6 h-6 fill-midLightGray" onClick={() => setFilterDrawerOpen(false)} />
                  </Hidden>
                </Box>
                <AlgoliaSlider attribute="price" />
                <AlgoliaRefinementList
                  attribute="category"
                  searchable={true}
                  showMore={true}
                  limit={5}
                  sortBy={['name:asc']}
                  operator="or"
                  showMoreLimit={9999}
                />
                <AlgoliaRefinementList
                  attribute="vendor"
                  searchable={true}
                  showMore={true}
                  limit={5}
                  sortBy={['name:asc']}
                  operator="or"
                  showMoreLimit={9999}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={9.5} className={`pt-0 ${isDownMd && filterDrawerOpen ? 'hidden' : ''}`}>
              <AlgoliaResultsHeader
                query={location.state?.searchName}
                itemsCount={+results.nbHits}
                pageSizeComponent={<AlgoliaHitsPerPage />}
                sortComponent={<AlgoliaSort />}
                filterDrawerOpen={filterDrawerOpen}
                setFilterDrawerOpen={setFilterDrawerOpen}
              />
              <Box>
                <AlgoliaHits />
                <Box className="flex justify-end my-10">
                  <AlgoliaPagination />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {isDownMd && filterDrawerOpen && (
            <>
              <Box className="fixed w-full start-0 bottom-0 py-4 px-5 bg-white z-[999]">
                <Grid container spacing={1.5}>
                  <Grid item xs={6}>
                    <Button variant="outlined" className="w-full" onClick={refine}>
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" className="w-full" onClick={() => setFilterDrawerOpen(false)}>
                      See Results
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <FilterButtons onReset={refine} onApply={() => setFilterDrawerOpen(false)} />
            </>
          )}
        </>
      )}
    </>
  );
};
