import { ReactComponent as RFQIcon } from 'design-system/public/icons/rfq.svg';
import { Button, Typography } from 'design-system';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { sendEventToGTM } from 'utils';

export const RequestQuotation = () => {
  const navigate = useNavigate();
  const handleRequestForQuotationClick = () => {
    navigate('/rfq/submit/first-step');
    sendEventToGTM('request_quotation_click');
  };

  return (
    <Button
      variant="text"
      startIcon={<RFQIcon className={styles.rfq__icon} />}
      className="text-midDarkGray"
      color="inherit"
      onClick={() => handleRequestForQuotationClick()}
    >
      <Typography variant="body1">Request a Quotation</Typography>
    </Button>
  );
};
