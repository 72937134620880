import { getAlgoliaFacets } from '@algolia/autocomplete-js';
import { ALGOLIA_PRODUCTS_INDEX_NAME } from '../utils';
import { searchClient } from 'utils';

export const popularCategoriesPlugin = {
  getSources() {
    return [
      {
        sourceId: 'popularCategoriesPlugin',
        getItems() {
          return getAlgoliaFacets({
            // @ts-ignore
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_PRODUCTS_INDEX_NAME,
                facet: 'category',
                params: {
                  facetQuery: '',
                  maxFacetHits: 4,
                },
              },
            ],
          });
        },
        getItemInputValue({ item }) {
          return item.label;
        },
        onSelect({ setIsOpen }) {
          setIsOpen(true);
        },
      },
    ];
  },
};
