import { Grid, List, ListItem, Divider, useMediaQuery, useTheme, Box, Hidden } from '@mui/material';
import { AppContainer, Typography } from 'design-system';
import { siteMap, socialMediaLinks } from 'utils';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { sendEventToGTM } from 'utils';
import { useCustomAuth } from 'hooks';

const TypographyLink = ({ title }) => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <Typography
      variant={isDownMd ? 'body2' : 'body1'}
      className="hover:text-deepYellow hover:font-semibold text-lightGray cursor-pointer"
    >
      {title}
    </Typography>
  );
};

export const Footer = () => {
  const { loginWithRedirect } = useCustomAuth();
  const onBecomeABuyer = () => {
    loginWithRedirect({ authOptions: { authorizationParams: { screen_hint: 'signup' } } });
    sendEventToGTM('signup_click');
  };
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footer__container}>
      <AppContainer>
        <Grid container spacing={{ xs: 1.5, md: 3 }}>
          {siteMap.map((siteMapItem, idx) => (
            <Grid item xs={6} md={3} key={`siteMapItem-#${idx}`}>
              <Typography variant="h6" component="h6" className="text-white">
                {siteMapItem.title}
              </Typography>
              <List>
                {siteMapItem.links.map((linkItem, idx) => (
                  <ListItem key={`listItem #${idx}`} className="px-0">
                    {linkItem.link && (
                      <Link target={linkItem.options?.target} to={linkItem.link}>
                        <TypographyLink title={linkItem.title} />
                      </Link>
                    )}
                    {linkItem.register && (
                      <Box onClick={() => onBecomeABuyer()}>
                        <TypographyLink title={linkItem.title} />
                      </Box>
                    )}
                  </ListItem>
                ))}
              </List>
            </Grid>
          ))}
          <Hidden mdDown>
            <Grid item md={3}>
              <Typography variant="h6" component="h6" className="text-white">
                Seller
              </Typography>
              <List>
                <ListItem key={`listItem-seller`} className="px-0">
                  <Link to={`https://${process.env.REACT_APP_BACKSTORE_BASE_URL}/redirecting-signup`}>
                    <TypographyLink title={'Become a Seller'} />
                  </Link>
                </ListItem>
              </List>
            </Grid>

            <Grid item md={3}>
              <Typography variant="h6" component="h6" className="text-white">
                Buyer
              </Typography>
              <List>
                <ListItem key={`listItem-buyer`} className="px-0">
                  <Box onClick={() => onBecomeABuyer()}>
                    <TypographyLink title={'Become a Buyer'} />
                  </Box>
                </ListItem>
              </List>
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <Grid item md={6}>
              <Typography variant="h6" component="h6" className="text-white">
                Seller
              </Typography>
              <List>
                <ListItem key={`listItem-seller`} className="px-0">
                  <Link to={`https://${process.env.REACT_APP_BACKSTORE_BASE_URL}/redirecting-signup`}>
                    <TypographyLink title={'Become a Seller'} />
                  </Link>
                </ListItem>
              </List>

              <Typography variant="h6" component="h6" className="text-white">
                Buyer
              </Typography>
              <List>
                <ListItem key={`listItem-buyer`} className="px-0">
                  <Box onClick={() => onBecomeABuyer()}>
                    <TypographyLink title={'Become a Buyer'} />
                  </Box>
                </ListItem>
              </List>
            </Grid>
          </Hidden>
        </Grid>

        <Divider sx={{ width: '86%', marginInline: 'auto', borderColor: 'var(--gray)' }} />

        <Grid container className="pt-6 max-sm:pt-5">
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography variant="body2" className="mt-4 w-full text-gray max-sm:text-center max-sm:mt-6">
              Copyright © {currentYear} otrovato | All Rights Reserved
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Grid container spacing={1} justifyContent={{ xs: 'center', md: 'flex-end' }}>
              {socialMediaLinks.map((link, idx) => (
                <Grid item key={`socialMediaLink #${idx}`}>
                  <a href={link.link} target="_blank" rel="noopener noreferrer">
                    {link.icon}
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </AppContainer>
    </div>
  );
};
