import { ReactComponent as ArrowRight } from 'design-system/public/icons/arrow-right.svg';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { CardActions, useMediaQuery, useTheme } from '@mui/material';
import { ProductDetailsModelDto, VendorModelDto } from 'codegen';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button } from 'design-system';
import { sendEventToGTM } from 'utils';
import { useCustomAuth } from 'hooks';
import classNames from 'classnames';

type VendorCardActionsProps = {
  vendorDetails: VendorModelDto;
  productDetails: ProductDetailsModelDto;
  secondaryButtonText: string;
  isFloatCard?: boolean;
};

export const VendorCardActions = ({
  vendorDetails,
  productDetails,
  secondaryButtonText,
  isFloatCard,
}: VendorCardActionsProps) => {
  const [contactSellerClicked, setContactSellerClicked] = useState(false);
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const vendorId = vendorDetails.id;
  const vendorName = vendorDetails.name;
  const { isAuthenticated, loginWithRedirect } = useCustomAuth();
  const { handleContactSeller, currentChannelUrl } = useMessageCenterContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDownMd && currentChannelUrl !== '' && contactSellerClicked) {
      navigate(`/channels/${currentChannelUrl}`);
      setContactSellerClicked(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelUrl, contactSellerClicked]);

  const onContactSeller = () => {
    if (isAuthenticated) {
      setContactSellerClicked(true);
      let message = `Hi, I am interested in this "${productDetails?.name}" you listed. ${window.location.href}`;
      handleContactSeller({
        message,
        vendorName,
        vendorRepresentativeEmail: vendorDetails.email,
        vendorId: vendorDetails.id,
      });
      sendEventToGTM('product_details_page_negotiate', {
        product_id: productDetails.id,
        product_name: productDetails.name,
        vendor_id: vendorId,
        vendor_name: vendorName,
      });
    } else loginWithRedirect({ redirectUri: window.location.pathname });
  };

  return (
    <CardActions className="w-full p-0 max-sm:px-0">
      <Link to={`/vendor-details/${vendorId}`} className="w-full me-0">
        <Button variant="outlined" size="small" id="ViewProfileBTN" className="w-full">
          {secondaryButtonText}
        </Button>
      </Link>

      <Button
        onClick={onContactSeller}
        endIcon={<ArrowRight />}
        variant="contained"
        color="magentaPink"
        size="small"
        id="ChatNow"
        className={classNames('w-full', styles.animate__arrow, isFloatCard ? 'ms-2' : ' ms-3')}
      >
        Negotiate Now
      </Button>
    </CardActions>
  );
};
