import { completeProfile, gRecaptchaVerify, getUser } from 'services/orchestrator.service';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { defaultCountries } from 'react-international-phone';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Country } from 'react-phone-number-input';
import { useAuthToken, useMyUser } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { userHasMetadata } from 'utils';
import { phoneValidation } from 'utils';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import * as Yup from 'yup';

export const useProfile = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const { token } = useAuthToken();
  const { myUser, setMyUser } = useMyUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>('EG');
  const [successfulOpen, setSuccessfulOpen] = useState<boolean>(false);

  const countries = defaultCountries.map(item => {
    return { label: item[0], value: item[0] };
  });

  const profileFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    email: Yup.string(),
    country: Yup.string().nullable(),
    companyName: Yup.string().trim().required('Company name is required'),
    phone: phoneValidation('Invalid phone number'),
    businessType: Yup.array().max(3, 'Maximum number of options are 3').required('This field is required'),
  });

  const {
    formState: { dirtyFields },
    getValues,
    setValue,
    control,
    handleSubmit,
    trigger,
    reset,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(profileFormSchema),
  });

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Profile', link: '/profile' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]);

  useEffect(() => {
    const elements = document.getElementsByTagName('input');
    for (const element of elements) {
      //@ts-ignore
      element.autocomplete = 'new-password';
    }
  }, []);

  const handleCountryChange = (country: Country | undefined) => {
    setSelectedCountry(country);
  };

  const handleBusinessTypeChange = (val: string[]) => {
    setValue('businessType', val);
    trigger('businessType');
  };

  const getCurrentCountryFromCountryCode = () => {
    const currentCountry = defaultCountries.find(item => item[1] === selectedCountry?.toLowerCase());
    return currentCountry![0];
  };

  const onSubmit = async () => {
    const values = getValues();
    if (!executeRecaptcha) return;
    else {
      setLoading(true);
      executeRecaptcha('enquiryFormSubmit').then(async gRecaptchaResponse => {
        try {
          await gRecaptchaVerify({ gRecaptchaResponse });
          if (token) {
            await completeProfile({
              token,
              body: {
                ...values,
                country: getValues('country') ? getValues('country')! : getCurrentCountryFromCountryCode(),
              },
            });
          }
          if (userHasMetadata(myUser)) {
            setSuccessfulOpen(true);
          } else navigate('/profile/verified');
          if (token) {
            try {
              const user = await getUser(token);
              setMyUser(user?.data[0]);
              localStorage.setItem('myUser', JSON.stringify(user?.data[0]));
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          if (isAxiosError(error) && error.response?.data?.message) toast.error(error.response?.data?.message);
          else toast.error('Un-expected Error.');
        } finally {
          setLoading(false);
        }
      });
    }
  };

  const setUserData = () => {
    reset();
    setValue('email', myUser?.email);
    myUser?.user_metadata?.userName && setValue('name', myUser?.user_metadata?.userName);
    myUser?.user_metadata?.companyName && setValue('companyName', myUser?.user_metadata?.companyName);
    myUser?.user_metadata?.phone && setValue('phone', myUser?.user_metadata?.phone);
    myUser?.user_metadata?.country && setValue('country', myUser?.user_metadata?.country);
    myUser?.user_metadata?.businessType && setValue('businessType', myUser?.user_metadata?.businessType);
  };

  const checkIsFormDirty = () => Object.keys(dirtyFields).length > 0;

  const handleCloseDialog = () => {
    setSuccessfulOpen(false);
    navigate('/home');
  };

  return {
    control,
    onSubmit,
    getValues,
    countries,
    handleSubmit,
    loading,
    handleCountryChange,
    handleBusinessTypeChange,
    selectedCountry,
    setUserData,
    checkIsFormDirty,
    successfulOpen,
    handleCloseDialog,
    myUser,
  };
};
