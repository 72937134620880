import {
  Typography,
  Listing,
  Pagination,
  Loader,
  StatusChip,
  Button,
  getFileExtension,
  IconButton,
  isImageFormat,
} from 'design-system';
import { Box, Divider, Card, TableCell, TableRow, Hidden, Menu, MenuItem } from '@mui/material';
import LogoGrayScale from 'design-system/public/images/logo-Grayscale.svg';
import { RfqTableToolbar } from '../components/rfq-table-toolbar';
import { CancelModal } from '../components/modals/cancel-modal';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { mappedRfq, useRfqList } from './useRfqList';
import { MoreHoriz } from '@mui/icons-material';
import styles from './styles.module.scss';
import { HasNoResult } from 'components';
import { Link } from 'react-router-dom';
import React from 'react';

export const RFQList = () => {
  const {
    myRfq,
    take,
    takeResponsive,
    skip,
    count,
    orderBy,
    setOrderBy,
    setSearchQuery,
    onChangePage,
    handleViewDetails,
    setSelectedItem,
    loading,
    cancelModalOpen,
    setCancelModalOpen,
    handleCancelRfq,
    headerColumns,
    selectColumns,
    showHasNoSearchResult,
    showHasNoTableData,
    showPagination,
    showTableData,
    tableContainerHeight,
  } = useRfqList();

  const renderIndexColumn = ({ item, select }) => {
    return (
      <Box className="flex items-center px-10">
        <Box className="file__container">
          {item && item.file && (
            <>
              {isImageFormat(getFileExtension(item.file.fileName)) && (
                <img src={item.file.url} alt={item.file.filename} />
              )}
              {!isImageFormat(getFileExtension(item.file.fileName)) && (
                <FileIcon
                  extension={getFileExtension(item.file.fileName)}
                  {...defaultStyles[getFileExtension(item.file.fileName)]}
                />
              )}
            </>
          )}

          {!(item && item.file) && <img src={LogoGrayScale} alt="logo-grayscale" />}
        </Box>
        <Box className="flex flex-col items-start">
          <Typography variant="caption" className="text-darkGray font-bold line-clamp-1">
            {item[select]}
          </Typography>

          <Typography variant="body2" className="text-midLightGray">
            {item?.hint}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderOtherCells = ({ item, select }) => {
    if (select === 'status')
      return (
        <Box className="flex justify-center">
          <StatusChip
            status={item.status}
            sx={{
              width: '83px',
              height: '28px',
            }}
          />
        </Box>
      );
    return (
      <Typography variant="body2" className="text-midLightGray">
        {item[select]}
      </Typography>
    );
  };

  const HeaderRow = () => {
    return (
      <>
        {headerColumns.map((column, idx) => (
          <TableCell
            key={`products-listing-header-cell-${idx}`}
            sx={{ color: showHasNoTableData ? 'var(--gray)' : '' }}
          >
            {column}
          </TableCell>
        ))}
      </>
    );
  };

  const TableBody = () => {
    return (
      <>
        {myRfq &&
          myRfq.map((item, idx) => (
            <TableRow key={`products-table-row-${idx}`}>
              {selectColumns.map((select, idx) => (
                <TableCell key={`products-table-cell-${idx}`}>
                  {idx === 0 && renderIndexColumn({ item, select })}
                  {idx !== 0 && renderOtherCells({ item, select })}
                </TableCell>
              ))}

              <TableCell>
                <Button
                  onClick={() => handleViewDetails(item.id)}
                  color="violet"
                  variant="outlined"
                  size="small"
                  className="w-[92px]"
                >
                  View
                </Button>
                <ThreeDotsMenu item={item} />
              </TableCell>
            </TableRow>
          ))}
      </>
    );
  };

  const ThreeDotsMenu = ({ item }: { item: mappedRfq }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const anchorRef = React.useRef(null);

    const handleMenuItemClick = () => {
      setSelectedItem(item);
      setCancelModalOpen(true);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <>
        <IconButton
          ref={anchorRef}
          onClick={handleClick}
          size="small"
          className="ms-11"
          aria-haspopup="true"
          aria-controls={open ? `table-menu-${item.id}` : undefined}
          aria-expanded={open ? 'true' : undefined}
          disabled={item.status === 'Closed'}
          icon={
            <MoreHoriz
              sx={{
                color: item.status === 'Closed' ? 'var(--midLightGray)' : 'var(--darkGray)',
              }}
            />
          }
        />
        <Menu
          id={`table-menu-${item.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            style: {
              width: 125,
            },
          }}
          disableScrollLock
        >
          <MenuItem onClick={handleMenuItemClick}>Close</MenuItem>
        </Menu>
      </>
    );
  };

  const ResponsiveListItem = ({ rfq }) => {
    return (
      <Card className={styles.rfq__card} onClick={() => handleViewDetails(rfq.id)}>
        <Typography variant="subtitle2" className="text-darkGray mb-1">
          {rfq.productName}
        </Typography>

        <Typography variant="body2" className="text-midLightGray">
          {rfq.hint}
        </Typography>

        <Divider className="my-4" />

        <Box className="flex items-center justify-between mb-3">
          <Typography variant="body1" className="text-midLightGray">
            Status
          </Typography>
          <StatusChip
            status={rfq?.status}
            sx={{
              width: { xs: '74px', md: '94px' },
              height: { xs: '28px', md: '34px' },
            }}
          />
        </Box>

        <Box className="flex items-center justify-between mb-3">
          <Typography variant="body1" className="text-midLightGray">
            Creation Date
          </Typography>
          <Typography variant="body2" className="text-darkGray">
            {rfq.createdAt}
          </Typography>
        </Box>

        <Box className="flex items-center justify-between">
          <Typography variant="body1" className="text-midLightGray">
            Expiration Date
          </Typography>
          <Typography variant="body2" className="text-darkGray">
            {rfq.expDate}
          </Typography>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <CancelModal
        open={cancelModalOpen}
        handlePrimaryClick={handleCancelRfq}
        handleClose={() => setCancelModalOpen(false)}
        loading={loading}
      />

      {/* Web */}
      <Hidden mdDown>
        <Listing
          tableHeader={<HeaderRow />}
          tableBody={<TableBody />}
          tableToolbar={
            <RfqTableToolbar
              setSearchQuery={setSearchQuery}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              disabled={showHasNoTableData}
            />
          }
          showPagination={showPagination}
          tablePagination={
            <>
              {count && (
                <Pagination
                  pagesCount={Math.ceil(count / take)}
                  pageNumber={skip / take + 1}
                  handleChangePage={onChangePage}
                />
              )}
            </>
          }
          tableContainerProps={{ sx: { height: tableContainerHeight } }}
          loadingData={loading}
          showTableData={showTableData}
          showHasNoTableData={showHasNoTableData}
          hasNoTableData={
            <HasNoResult
              title="Ready to take the next step?"
              description="Submit an RFQ with all the details of the products you're seeking, and
    we'll contact you!"
              buttonComponent={
                <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                  <Button className="w-36">Create RFQ</Button>
                </Link>
              }
            />
          }
          hasNoSearchResults={
            <HasNoResult
              title="It seems like your search is not available right now!"
              description=""
              buttonComponent={
                <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                  <Button className="w-36 hidden">Create RFQ</Button>
                </Link>
              }
            />
          }
          showHasNoSearchResults={showHasNoSearchResult}
        />
      </Hidden>

      {/* Responsive */}
      <Hidden mdUp>
        <RfqTableToolbar
          setSearchQuery={setSearchQuery}
          setOrderBy={setOrderBy}
          orderBy={orderBy}
          disabled={showHasNoTableData}
        />

        {loading && <Loader />}

        {!loading && (
          <>
            {(count ?? 0) > 0 && (
              <Box className="px-5">
                {myRfq && myRfq.map(rfq => <ResponsiveListItem key={`responsive-list-item${rfq.id}`} rfq={rfq} />)}
              </Box>
            )}

            {showPagination && (count ?? 0) > 0 && (
              <Box className="my-10">
                <Pagination
                  pagesCount={Math.ceil((count ?? 0) / takeResponsive)}
                  pageNumber={skip / takeResponsive + 1}
                  handleChangePage={onChangePage}
                />
              </Box>
            )}
          </>
        )}

        {showHasNoTableData && (
          <HasNoResult
            title="Ready to take the next step?"
            description="Submit an RFQ with all the details of the products you're seeking, and
          we'll contact you!"
            buttonComponent={
              <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                <Button className="w-36">Create RFQ</Button>
              </Link>
            }
          />
        )}

        {showHasNoSearchResult && (
          <HasNoResult
            title="It seems like your search is not available right now!"
            description=""
            buttonComponent={
              <Link to="/rfq/submit/first-step" id="CreateRFQLink">
                <Button className="w-36 hidden">Create RFQ</Button>
              </Link>
            }
          />
        )}
      </Hidden>
    </>
  );
};
