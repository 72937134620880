import { getRfqDetails, getRfqFiles, getBuyerRfqQuotations, cancelRfq } from 'services/orchestrator.service';
import { QuotationsResponse } from 'services/dtos/quotation/quotations.response';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { RFQFile } from 'services/types/rfq/RfqFile.type';
import { useNavigate, useParams } from 'react-router-dom';
import { RFQ } from 'services/types/generic/rfq-entity';
import { useEffect, useRef, useState } from 'react';
import { isImageFormat } from 'design-system';
import { sendEventToGTM } from 'utils';
import { useAuthToken } from 'hooks';

export const useRfq = () => {
  const { id } = useParams();
  const { token } = useAuthToken();
  const { updateBreadcrumbs } = useBreadcrumbs();
  const quotationRef = useRef(null);
  const [rfqDetails, setRfqDetails] = useState<RFQ>();
  const [rfqFiles, setRfqFiles] = useState<RFQFile[]>();
  const [rfqQuotations, setRfqQuotations] = useState<QuotationsResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (rfqDetails) sendEventToGTM('view_rfq', { rfq_short_id: rfqDetails?.shortID });
  }, [rfqDetails]);

  useEffect(() => {
    updateBreadcrumbs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rfqDetails && rfqDetails?.shortID) {
      updateBreadcrumbs([
        { label: 'RFQ List', link: '/rfq' },
        {
          label: `RFQ #${rfqDetails?.shortID}`,
          link: `/rfq/details/${id}`,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfqDetails]);

  useEffect(() => {
    fetchRfqDetails()?.catch(e => {
      navigate('/error');
    });
    fetchQuotationsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id]);

  const fetchRfqDetails = () => {
    if (token && id) {
      return Promise.all([getRfqDetails(token, id), getRfqFiles(token, id), getBuyerRfqQuotations(token, id)]).then(
        ([{ data: myRfq }, { data: files }, { data: quotations }]) => {
          setRfqDetails(myRfq);
          setRfqFiles(files.sort(compareExtensions));
          setRfqQuotations(quotations);
          setLoading(false);
        },
      );
    }
  };

  const fetchQuotationsData = () => {
    if (token && id) {
      getBuyerRfqQuotations(token, id).then(({ data: quotations }) => {
        setRfqQuotations(quotations);
      });
    }
  };

  const compareExtensions = (a, b) => {
    const extA = a.fileName.split('.').pop().toLowerCase();
    const extB = b.fileName.split('.').pop().toLowerCase();
    const isImageA = isImageFormat(extA);
    const isImageB = isImageFormat(extB);
    if (isImageA && !isImageB) return -1;
    if (!isImageA && isImageB) return 1;
    if (extA < extB) return -1;
    if (extA > extB) return 1;
    if (a.fileName < b.fileName) return -1;
    if (a.fileName > b.fileName) return 1;
    return 0;
  };

  const cancelMyRfq = async () => {
    if (id) {
      await cancelRfq(token!, id);
    }
  };

  const handleCancelRfq = async () => {
    setLoading(true);
    await cancelMyRfq();
    setCancelModalOpen(false);
    fetchRfqDetails();
  };

  return {
    id,
    rfqDetails,
    rfqFiles,
    rfqQuotations,
    loading,
    quotationRef,
    handleCancelRfq,
    cancelModalOpen,
    setCancelModalOpen,
  };
};
