import { useMessageCenterContext } from 'contexts/message-center-context';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useEffect, useState } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { useCustomAuth } from 'hooks';
import { Box } from '@mui/material';

export const ChannelHeader = () => {
  const { currentChannelUrl, sb } = useMessageCenterContext();
  const [channel, setChannel] = useState<GroupChannel>();
  const [secondUser, setSecondUser] = useState<any>();
  const { user } = useCustomAuth();

  useEffect(() => {
    const getChannel = async () => {
      const channel = await sb?.groupChannel.getChannel(currentChannelUrl);
      setChannel(channel);
      setSecondUser(channel?.members.filter(member => member.userId !== user?.email && member.role !== 'operator')[0]);
    };
    getChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelUrl]);

  return (
    <Box className={`${styles.message__center__channel__header}`}>
      {secondUser && (
        <Box className="flex items-center">
          <img src={secondUser?.profileUrl} className="rounded-full w-8 h-8 mr-3" alt="profile" />
          <Typography variant="subtitle1" className="text-darkGray">
            {channel?.data}
          </Typography>
        </Box>
      )}

      {!secondUser && (
        <Box className="flex items-center">
          <Typography variant="subtitle1" className="text-darkGray">
            Choose a chat
          </Typography>
        </Box>
      )}
    </Box>
  );
};
