import { useCurrentRefinements } from 'react-instantsearch';
import { useLocation } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

export const AlgoliaCurrentRefinements = () => {
  const { items, refine } = useCurrentRefinements();
  const [currentRefinements, setCurrentRefinements] = useState<any[]>([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchKeyword = searchParams.get('search');

  useEffect(() => {
    const refinedItems: any = [];
    items.forEach(item => {
      item.refinements.forEach(refinement => {
        refinedItems.push(refinement);
      });
    });
    setCurrentRefinements(refinedItems);
  }, [items]);

  useEffect(() => {
    items.forEach(item => {
      item.refinements.forEach(refinement => {
        refine(refinement);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  const handleClick = (refinement: any) => {
    refine(refinement);
  };

  return (
    <>
      {currentRefinements.length > 0 && (
        <div className="mb-6">
          <Box className="flex flex-wrap gap-x-5 gap-y-3 max-sm:gap-x-2 max-sm:gap-y-2">
            {currentRefinements.map((refinement: any) => (
              <Box key={`refinement-${refinement.label}`} className={styles.refinement__chip}>
                <Typography variant="body2" className="me-3 font-normal">
                  {refinement.label}
                </Typography>
                <Clear onClick={() => handleClick(refinement)} />
              </Box>
            ))}
          </Box>
        </div>
      )}
    </>
  );
};
