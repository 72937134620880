import { ProductDetailsModelDto, VendorModelDto } from 'codegen';
import { VendorCardActions } from '../vendor-card-actions';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Box, Card } from '@mui/material';
import { HtmlParser } from 'components';
import { HTMLAttributes } from 'react';

type Props = {
  vendorDetails: VendorModelDto;
  productDetails: ProductDetailsModelDto;
} & HTMLAttributes<HTMLDivElement>;

export const ProductVendorCardFloat = ({ vendorDetails, productDetails, ...props }: Props) => {
  return (
    <Card className={styles.vendor__card__float} elevation={0} key={`vendor-#${vendorDetails?.id}`} {...props}>
      <Box className="flex">
        <div className={styles.vendor__card__float__image__container} id="VendorImage">
          <div
            style={{
              backgroundImage: `url(${vendorDetails?.pictureModel?.fullSizeImageUrl})`,
            }}
          />
        </div>

        <Box className="flex flex-col justify-center">
          <Typography variant="h5" component="h5" className="text-darkGray leading-[30px]" id="VendorName">
            {vendorDetails?.name}
          </Typography>

          {vendorDetails?.since && (
            <Typography variant="caption" className="text-midLightGray" id="Since">
              Since {vendorDetails?.since}
            </Typography>
          )}
        </Box>
      </Box>

      <Box className="mt-3">
        {vendorDetails?.description && (
          <Typography variant="caption">
            <HtmlParser
              className={`line-clamp-2 ${styles.vendor__card__float__description}`}
              htmlString={vendorDetails?.description}
              id="VendorShortDescription"
            />
          </Typography>
        )}

        <Box className={styles.vendor__card__float__actions__float__container}>
          <VendorCardActions
            vendorDetails={vendorDetails}
            productDetails={productDetails}
            secondaryButtonText="View supplier profile"
            isFloatCard
          />
        </Box>
      </Box>
    </Card>
  );
};
