import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { Box, Card, useMediaQuery, useTheme } from '@mui/material';
import { FreeMode, Navigation } from 'swiper/modules';
import { CategorySimpleModelDto } from 'codegen';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useRef } from 'react';

type Props = {
  categories: CategorySimpleModelDto[];
};

export const CategoryBestSeller = ({ categories }: Props) => {
  const sliderRef = useRef<SwiperClass>();

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const bestSellerCategoriesSwiperProps: SwiperProps = {
    direction: 'horizontal',
    spaceBetween: isDownMd ? 12 : 24,
    slidesPerView: 'auto',
    modules: [FreeMode, Navigation],
    slideToClickedSlide: true,
    navigation: {
      prevEl: '.category-swiper.custom-swiper-button-prev',
      nextEl: '.category-swiper.custom-swiper-button-next',
      disabledClass: 'disabled-swiper',
    },
    onSwiper: swiper => {
      sliderRef.current = swiper;
    },
    loop: false,
  };

  return (
    <>
      {categories.length >= 3 && (
        <>
          <Typography variant="h3" component="h3" className="text-darkGray mb-4 max-sm:mb-3">
            Categories
          </Typography>

          <div className="swiper-with-arrows">
            <div className="category-swiper custom-swiper-button-prev" onClick={() => sliderRef?.current?.slidePrev()}>
              <CaretRight />
            </div>

            <Swiper {...bestSellerCategoriesSwiperProps}>
              {categories.map(category => (
                <SwiperSlide
                  style={{ width: 'auto', padding: '2px' }}
                  key={`best-selling-category-slide-${category?.id}`}
                >
                  <Link
                    key={`category-#${category.id}`}
                    to={`/category/${category.id}/${category.seName}`}
                    state={{ fromSearch: false }}
                  >
                    <Card className={styles.category__card}>
                      <Box
                        className={styles.category__image}
                        sx={{ backgroundImage: `url(${category.pictureModel.fullSizeImageUrl})` }}
                      >
                        <Box className={styles.category__tile__button}>
                          {/* <img src={category?.iconModel?.fullSizeImageUrl || ''} alt="" /> */}
                          <Typography variant="subtitle1" className="line-clamp-1 text-violet">
                            {category.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="category-swiper custom-swiper-button-next" onClick={() => sliderRef?.current?.slideNext()}>
              <CaretRight />
            </div>
          </div>
        </>
      )}
    </>
  );
};
