import { ReactComponent as ChevronLeft } from 'design-system/public/icons/chevron-left.svg';
import { HTMLAttributes } from 'react';
import './styles.scss';

type Props = {
  customClassName?: string;
  onClick?: any;
} & HTMLAttributes<HTMLDivElement>;

export const PrevArrow = ({ className, onClick, customClassName }: Props) => {
  return (
    <div className={`${className} ${customClassName}`} onClick={onClick}>
      <ChevronLeft />
    </div>
  );
};
