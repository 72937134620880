import { ReactComponent as SortIcon } from 'design-system/public/icons/sort.svg';
import { Box, MenuItem, Select, useMediaQuery, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SelectListItemDto } from 'codegen';
import { useState } from 'react';

type Props = {
  orderBy: number;
  availableSortOptions: SelectListItemDto[];
  setOrderBy: (value: number) => void;
};

export const Sort = ({ orderBy, availableSortOptions, setOrderBy }: Props) => {
  const [open, setOpen] = useState(false);
  const orderByElement = availableSortOptions.find(element => element.value === orderBy.toString() || '');
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Select
      open={open}
      sx={{ width: 'auto', fontSize: '13px', height: '40px' }}
      defaultValue={orderBy}
      variant="standard"
      disableUnderline
      renderValue={value => {
        setOrderBy(+value);
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <SortIcon />
            {!isDownMd && `Sort by: ${orderByElement?.text}`}
          </Box>
        );
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      IconComponent={() => (
        <div className="cursor-pointer absolute right-0 text-midLightGray" onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      )}
      MenuProps={{
        sx: {
          '&& .Mui-selected': {
            backgroundColor: 'var(--secondaryLight)',
          },
        },
      }}
    >
      {availableSortOptions &&
        availableSortOptions.map(
          option =>
            option.value && (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ),
        )}
    </Select>
  );
};
