import { Button, TextInput, Typography, PhoneInput } from 'design-system';
import { useContactUs } from './useContactUs';
import { Grid, Box } from '@mui/material';

export const ContactUs = () => {
  const { isValid, control, handleSubmit, onSubmit } = useContactUs();

  return (
    <Box className="bg-white p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0">
      <Box className="mb-6">
        <Typography variant="h3" component="h3" className="text-darkGray">
          Contact Us
        </Typography>
        <Typography variant="body1" className="text-midLightGray">
          Let us know how we can help!
        </Typography>
      </Box>

      <Grid container spacing={3} className="mt-6 max-sm:mt-0">
        <Grid item xs={12} md={6}>
          <TextInput
            name="firstname"
            label="First Name"
            placeholder="Enter your first name"
            control={control}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextInput name="lastname" label="Last Name" placeholder="Enter your Last name" control={control} required />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextInput name="email" label="Email" placeholder="Enter your Email" control={control} required />
        </Grid>

        <Grid item xs={12} md={6}>
          <PhoneInput
            name="phone"
            label="Phone Number"
            placeholder="Enter your phone number"
            control={control}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextInput
            name="company"
            label="Company Name"
            placeholder="Enter your Company name"
            control={control}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            name="message"
            label="Message"
            placeholder="Leave your message"
            control={control}
            rows={5}
            multiline
          />
        </Grid>
      </Grid>

      <Box className="flex justify-center mt-12">
        <Button className="my-4 w-[390px]" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};
