import { Typography, ProfileVerified } from 'design-system';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect } from 'react';

export const VerifiedProfile = () => {
  document.querySelector('#verified-icon')?.classList.add('show');
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/home');
    }, 3000);

    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <Box className="bg-white p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0 text-center min-h-[514px] flex flex-col justify-center">
      <Box className="w-[120px] h-[110px] mx-auto mb-4">
        <ProfileVerified />
      </Box>
      <Typography variant="h4" component="h4" className="text-darkGray mb-3">
        Your profile has been updated
      </Typography>
      <Typography variant="body1" className="text-midDarkGray">
        Congratulations you are now a verified user in our platform
      </Typography>
    </Box>
  );
};
