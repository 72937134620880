import { ChannelList as SendBirdChannelList } from '@sendbird/uikit-react';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { useNavigate } from 'react-router-dom';
import '@sendbird/uikit-react/dist/index.css';
import { ChannelPreview } from 'components';
import styles from './styles.module.scss';
import { Loader } from 'design-system';
import { useCustomAuth } from 'hooks';
import './styles.scss';

export const ChannelListMobile = () => {
  const navigate = useNavigate();
  const { loader } = useMessageCenterContext();
  const { isAuthenticated } = useCustomAuth();

  return (
    <>
      {loader && isAuthenticated && <Loader />}

      {!(loader && isAuthenticated) && (
        <div className={`${styles.custom__channel__list}`}>
          <SendBirdChannelList
            disableAutoSelect
            renderHeader={() => <></>}
            renderChannelPreview={props => <ChannelPreview channel={props.channel} />}
            onChannelSelect={channel => {
              channel && navigate(`/channels/${channel.url}`);
            }}
          />
        </div>
      )}
    </>
  );
};
