import { Query, SortOptionEnum, SortPropertyEnum } from 'services/types/generic/Query.dto';
import { cancelRfq, getMyRfq } from 'services/orchestrator.service';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { RFQ } from 'services/types/generic/rfq-entity';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sendEventToGTM } from 'utils';
import { useAuthToken } from 'hooks';
import moment from 'moment';

export type mappedRfq = {
  id: string;
  productName: string;
  createdAt: string;
  expDate: string;
  status: string;
  hint: string;
};

export const useRfqList = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const { token } = useAuthToken();
  const [myRfq, setMyRfq] = useState<mappedRfq[]>();
  const [count, setCount] = useState<number>();
  const [take] = useState<number>(10);
  const [takeResponsive] = useState<number>(5);
  const [skip, setSkip] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<'Newest' | 'Oldest'>('Newest');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<mappedRfq>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showTableData, setShowTableData] = useState<boolean>(false);
  const [showPagination, setShowPagination] = useState<boolean>(false);
  const [showHasNoTableData, setShowHasNoTableData] = useState<boolean>(false);
  const [showHasNoSearchResult, setShowHasNoSearchResult] = useState<boolean>(false);
  const [tableContainerHeight, setTableContainerHeight] = useState<string>('');

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const headerColumns = ['Product', 'Status', 'Creation Date', 'Expiry Date', ''];
  const selectColumns = ['productName', 'status', 'createdAt', 'expDate'];

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchRfqList();
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, skip, take, orderBy, searchQuery]);

  useEffect(() => {
    setSkip(0);
  }, [searchQuery, orderBy]);

  useEffect(() => {
    if (!loading && myRfq && count && count > 0) setShowTableData(true);
    else setShowTableData(false);

    if (!loading && myRfq && count && count > (isDownMd ? takeResponsive : take)) setShowPagination(true);
    else setShowPagination(false);

    if (!loading && myRfq && count === 0 && searchQuery !== '') setShowHasNoSearchResult(true);
    else setShowHasNoSearchResult(false);

    if (!loading && myRfq && count === 0 && searchQuery === '') setShowHasNoTableData(true);
    else setShowHasNoTableData(false);
  }, [count, isDownMd, loading, myRfq, searchQuery, take, takeResponsive]);

  useEffect(() => {
    if (showPagination) setTableContainerHeight('calc(100vh - 145px - 24px - 22px - 115px)');
    else if (!showPagination) setTableContainerHeight('calc(100vh - 145px - 24px - 22px - 115px)');
  }, [showPagination, showHasNoSearchResult, showHasNoTableData]);

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ List', link: '/rfq' }]);
    sendEventToGTM('manage_rfq');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewDetails = (id: string | number) => {
    navigate(`/rfq/details/${id}`);
  };

  const mapRfq = (rfq: RFQ[]) => {
    return rfq.map(item => {
      let mainFile: any;
      if (item.files && item.files[0]) mainFile = item.files[0];
      return {
        id: item.id,
        productName: item.productName,
        createdAt: moment(item.CreatedAt).utc().format('MMMM DD, YYYY'),
        expDate: moment(item.expDate).utc().format('MMMM DD, YYYY'),
        status: item.status,
        hint: `${item.quantity} / ${item.unit}`,
        file: mainFile,
      };
    });
  };

  const fetchRfqList = async () => {
    const body: Query = {
      pagination: {
        skip,
        take: isDownMd ? takeResponsive : take,
      },
      sortProperty: SortPropertyEnum.CreatedAt,
      sortValue: orderBy === 'Newest' ? SortOptionEnum.desc : SortOptionEnum.asc,
      productName: searchQuery,
      description: searchQuery,
    };

    if (token) {
      setLoading(true);
      const { data: myRfq } = await getMyRfq({ body, token });
      setMyRfq(mapRfq(myRfq.data));
      setCount(myRfq.count);
      setLoading(false);
    }
  };

  const onChangePage = (val: number) => {
    isDownMd ? setSkip((val - 1) * takeResponsive) : setSkip((val - 1) * take);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 150);
  };

  const handleCancelRfq = async () => {
    if (selectedItem && selectedItem.id) {
      setLoading(true);
      await cancelRfq(token!, selectedItem.id);
      setCancelModalOpen(false);
      fetchRfqList();
    }
  };

  return {
    myRfq,
    take,
    takeResponsive,
    skip,
    count,
    orderBy,
    setOrderBy,
    setSearchQuery,
    onChangePage,
    handleViewDetails,
    setSelectedItem,
    loading,
    cancelModalOpen,
    setCancelModalOpen,
    handleCancelRfq,
    headerColumns,
    selectColumns,
    showTableData,
    showPagination,
    showHasNoSearchResult,
    showHasNoTableData,
    tableContainerHeight,
  };
};
