import { Typography, Button, StatusChip, getFileExtension, isImageFormat } from 'design-system';
import { ReactComponent as DownloadIcon } from 'design-system/public/icons/Download.svg';
import { QuotationsResponse } from 'services/dtos/quotation/quotations.response';
import LogoGrayScale from 'design-system/public/images/logo-Grayscale.svg';
import { Grid, Box, Menu, MenuItem, Link, Hidden } from '@mui/material';
import { RFQFile } from 'services/types/rfq/RfqFile.type';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { RFQ } from 'services/types/generic/rfq-entity';
import { MoreHoriz } from '@mui/icons-material';
import { useRfqDetails } from './useRfqDetails';
import styles from './styles.module.scss';
import moment from 'moment';

type Props<T> = {
  rfqDetails: T;
  rfqFiles?: RFQFile[];
  quotations: QuotationsResponse;
  quotationRef?: any;
  setCancelModalOpen: Function;
};

type FileContainerProps = {
  handleMouseEnter?: Function;
  handleMouseLeave?: Function;
  idx: number;
};

type HoverDownloadButtonProps = {
  hoverStates: boolean[];
  idx: number;
  rfqFile: RFQFile;
} & HTMLAttributes<HTMLDivElement>;

const KeyValue: React.FC<PropsWithChildren<any>> = ({
  title,
  value,
  lineClamp = true,
  ...props
}: {
  title: string;
  value?: string;
  lineClamp?: boolean;
}) => {
  return (
    <Grid container {...props}>
      <Grid item xs={5} md={5} lg={4}>
        <Typography variant="body2" className="text-midLightGray">
          {title}:&nbsp;
        </Typography>
      </Grid>
      <Grid item xs={7} md={7} lg={8}>
        <Typography variant="body2" className={`text-darkGray ${lineClamp && 'line-clamp-1'}`}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const HoverDownloadButton = ({ hoverStates, idx, rfqFile }: HoverDownloadButtonProps) => {
  return (
    <>
      {hoverStates[idx] && (
        <Link href={rfqFile.url}>
          <div className={styles.download__button}>
            <Typography variant="overline" component="span">
              Download
            </Typography>
            <DownloadIcon />
          </div>
        </Link>
      )}
    </>
  );
};

const FileContainer = ({
  handleMouseEnter,
  handleMouseLeave,
  idx,
  children,
}: PropsWithChildren<FileContainerProps>) => {
  return (
    <Box
      className={styles.file__container}
      onMouseEnter={() => handleMouseEnter && handleMouseEnter(idx)}
      onMouseLeave={() => handleMouseLeave && handleMouseLeave(idx)}
    >
      {children}
    </Box>
  );
};

export const RFQDetails = <T extends RFQ>({
  rfqDetails,
  rfqFiles,
  setCancelModalOpen,
  quotations,
  quotationRef,
}: Props<T>) => {
  const {
    handleMenuClick,
    isDownMd,
    menuItems,
    open,
    anchorEl,
    handleCloseMenu,
    newStatusCount,
    scrollToTarget,
    handleMouseEnter,
    handleMouseLeave,
    hoverStates,
    isMainHovered,
    setIsMainHovered,
  } = useRfqDetails({
    setCancelModalOpen,
    quotations,
    quotationRef,
    rfqFiles,
  });

  return (
    <>
      <Box className="flex justify-between items-center mb-6">
        <Box className="flex items-center">
          <Typography variant="body1" className="text-midLightGray" component="span">
            RFQ ID:&nbsp;
          </Typography>

          <Typography variant="subtitle1" className="text-darkGray" component="span">
            {rfqDetails?.shortID}
          </Typography>

          <Box className="inline-block ms-3 cursor-pointer" onClick={scrollToTarget}>
            <StatusChip
              status={rfqDetails?.status}
              bubble={newStatusCount ? newStatusCount : undefined}
              sx={{
                width: { xs: '74px', md: '94px' },
                height: { xs: '28px', md: '34px' },
              }}
            />
          </Box>
        </Box>

        {!isDownMd && (
          <Button
            variant="text"
            className="text-darkGray hover:text-deepYellow hover:bg-white disabled:text-midLightGray"
            onClick={() => setCancelModalOpen(true)}
            disabled={rfqDetails.status === 'Closed' || rfqDetails.status === 'Expired'}
          >
            Close RFQ
          </Button>
        )}

        {isDownMd && (
          <>
            <button onClick={handleMenuClick} disabled={rfqDetails.status === 'Closed'}>
              <MoreHoriz />
            </button>
            {menuItems && menuItems.length > 0 && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  style: {
                    width: 125,
                    overflowX: 'hidden',
                  },
                }}
                disableScrollLock
              >
                {menuItems.map((menuItem, idx) => (
                  <MenuItem key={`menu-item-${idx}`} onClick={menuItem.onClick} disableRipple>
                    {menuItem.text}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </>
        )}
      </Box>

      <Box className={styles.rfq__content}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={2}>
            {rfqFiles && rfqFiles?.length > 0 && (
              <Box
                className={styles.main__file__container}
                onMouseEnter={() => setIsMainHovered(true)}
                onMouseLeave={() => setIsMainHovered(false)}
              >
                {isImageFormat(getFileExtension(rfqFiles[0].fileName)) && (
                  <img src={rfqFiles && rfqFiles[0].url} alt={rfqFiles && rfqFiles[0].fileName} />
                )}

                {!isImageFormat(getFileExtension(rfqFiles[0].fileName)) && (
                  <FileIcon
                    extension={getFileExtension(rfqFiles[0].fileName)}
                    {...defaultStyles[getFileExtension(rfqFiles[0].fileName)]}
                  />
                )}

                <Hidden mdUp>
                  <Link href={rfqFiles[0].url} className={styles.responsive__main__link} />
                </Hidden>

                <Hidden mdDown>
                  {isMainHovered && (
                    <a className={styles.download__button} href={rfqFiles[0].url}>
                      <Typography variant="overline" component="span">
                        Download
                      </Typography>
                      <DownloadIcon />
                    </a>
                  )}
                </Hidden>
              </Box>
            )}

            {rfqFiles && rfqFiles?.length === 0 && (
              <Box className={styles.main__file__container}>
                <img src={LogoGrayScale} alt="logo-grayscale" />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
            {rfqFiles && rfqFiles?.length > 1 && (
              <Box className="flex">
                {rfqFiles.slice(1).map((rfqFile, idx) => (
                  <Box key={`rfq-file-${rfqFile.fileName}`}>
                    <FileContainer idx={idx}>
                      <Link href={rfqFile.url}>
                        {isImageFormat(getFileExtension(rfqFile.fileName)) && (
                          <img src={rfqFile.url} alt={rfqFile.fileName} />
                        )}

                        {!isImageFormat(getFileExtension(rfqFile.fileName)) && (
                          <Box className={styles.file}>
                            <FileIcon
                              extension={getFileExtension(rfqFile.fileName)}
                              {...defaultStyles[getFileExtension(rfqFile.fileName)]}
                            />
                          </Box>
                        )}
                      </Link>
                    </FileContainer>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={5}>
            <Typography variant="subtitle1" className="text-darkGray mb-3">
              Product Details :
            </Typography>

            <KeyValue
              title="Product Category"
              value={rfqDetails?.category?.name ? rfqDetails?.category?.name : 'Other'}
              className="mb-2"
            />
            {rfqDetails?.otherCategory && (
              <KeyValue title="Other Category" value={rfqDetails?.otherCategory} className="mb-2" />
            )}
            <KeyValue title="Product Name" value={rfqDetails?.productName} className="mb-2" />
            <KeyValue title="Quantity" value={`${rfqDetails?.quantity} / ${rfqDetails?.unit}`} className="mb-2" />
            <KeyValue
              title="Expiry Date"
              value={moment(rfqDetails?.expDate).utc().format('MMMM DD, YYYY')}
              className="mb-2"
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <Typography variant="subtitle1" className="text-darkGray mb-3">
              Shipping Details :
            </Typography>

            <KeyValue title="Shipping Terms" value={rfqDetails?.shipmentTerm} className="mb-2" />
            <KeyValue title="Shipping Destination" value={rfqDetails?.country} className="mb-2" />
            <KeyValue title="Shipping State" value={rfqDetails?.state} className="mb-2" />
            <KeyValue title="Full Address" value={rfqDetails?.address} lineClamp={false} className="mb-2" />
          </Grid>
        </Grid>

        <Hidden mdDown>
          {rfqFiles && rfqFiles?.length > 1 && (
            <Box className="mt-4 flex">
              {rfqFiles.slice(1).map((rfqFile, idx) => (
                <Box key={`rfq-file-${rfqFile.fileName}`}>
                  <FileContainer handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} idx={idx}>
                    {isImageFormat(getFileExtension(rfqFile.fileName)) && (
                      <img src={rfqFile.url} alt={rfqFile.fileName} />
                    )}

                    {!isImageFormat(getFileExtension(rfqFile.fileName)) && (
                      <Box className={styles.file}>
                        <FileIcon
                          extension={getFileExtension(rfqFile.fileName)}
                          {...defaultStyles[getFileExtension(rfqFile.fileName)]}
                        />
                      </Box>
                    )}

                    <HoverDownloadButton hoverStates={hoverStates} idx={idx} rfqFile={rfqFile} />
                  </FileContainer>
                </Box>
              ))}
            </Box>
          )}
        </Hidden>

        {rfqDetails?.description && (
          <Box className="mt-4">
            <Typography variant="subtitle2" className="mb-3 text-darkGray">
              Additional Information
            </Typography>

            <Typography variant="body2" className="text-midLightGray">
              {rfqDetails?.description}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
