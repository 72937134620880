import { Skeleton, Grid, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Typography, SkeletonProductCard } from 'design-system';

export const BestSellingProductsSkeleton = () => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <>
      <Typography variant="h3" className="w-1/3 mb-4 max-sm:mb-3">
        <Skeleton variant="text" animation="wave" />
      </Typography>

      <Grid container spacing={isDownMd ? 1.5 : 3}>
        <Grid item>
          <SkeletonProductCard />
        </Grid>
        <Grid item>
          <SkeletonProductCard />
        </Grid>

        <Hidden mdDown>
          <Grid item>
            <SkeletonProductCard />
          </Grid>
          <Grid item>
            <SkeletonProductCard />
          </Grid>
          <Grid item>
            <SkeletonProductCard />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};
