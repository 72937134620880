import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { Blog } from 'services/dtos/hubspot/GetBlogs.dto';
import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import moment from 'moment';

type Props = {
  blog: Blog;
};

export const BlogHero = ({ blog, ...props }: Props) => {
  const publishedAt = moment(blog.publishedAt).format('MMMM DD, YYYY');
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([
      { label: 'Blogs', link: '/blogs' },
      { label: 'Blog Details', link: '' },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {blog && blog.values && (
        <Box className={styles.hero} {...props}>
          {blog.values.image && (
            <img className={styles.hero__image} src={blog.values?.image.url} alt={blog.values?.image.altText} />
          )}
          <div className={styles.hero__overlay} />

          <Box className={styles.hero__content}>
            <Typography variant="h2">{blog.values.title}</Typography>
            <Box className={styles.hero__footer}>
              <img id="author-image" src={blog.values?.author_image?.url} alt={blog.values?.author_image?.altText} />
              <Typography variant="body2" id="author" className={styles.hero__footer__author}>
                {blog.values?.author}
              </Typography>
              <Typography variant="body2" id="published-at">
                {publishedAt}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
