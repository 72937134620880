import { Modal, TextInput, Typography } from 'design-system';
import { Box } from '@mui/material';

type Props = {
  control: any;
  isValid: boolean;
  open: boolean;
  handlePrimaryClick: () => void;
  handleSecondaryClick: () => void;
  loading: boolean;
};

const RejectHeader = () => {
  return (
    <>
      <Typography variant="body2" className="text-midLightGray">
        Help us to give you best experience
      </Typography>
      <Typography variant="subtitle1" className="text-[#252535]">
        Choose what's you don't like in the RFQ
      </Typography>
    </>
  );
};

const RejectContent = ({ control }) => {
  return (
    <Box className="mt-2">
      <TextInput name="reason" placeholder="Note" control={control} rows={2} multiline required />
    </Box>
  );
};

export const RejectModal = ({ control, isValid, open, handlePrimaryClick, handleSecondaryClick, loading }: Props) => {
  return (
    <Modal
      open={open}
      primaryButtonText="Send"
      secondaryButtonText="Skip"
      primaryButtonProps={{ disabled: !isValid }}
      header={<RejectHeader />}
      content={<RejectContent control={control} />}
      handleClose={handleSecondaryClick}
      handlePrimary={handlePrimaryClick}
      loading={loading}
    />
  );
};
