import { BottomNavigation, Footer as FooterComponent } from 'components';
import { useLoading } from 'contexts/loadingContext';
import { MessageCenterPopup } from 'components';
import { Hidden } from '@mui/material';

export const FooterHandler = ({ hasFooter }: { hasFooter: boolean }) => {
  const { isLoading } = useLoading();
  return (
    <>
      {!isLoading && (
        <footer id="footer-box">
          {hasFooter && <FooterComponent />}
          <BottomNavigation />
          <Hidden mdDown>
            <MessageCenterPopup />
          </Hidden>
        </footer>
      )}
    </>
  );
};
