import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type ButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | any;
  isSocialMedia?: boolean;
} & MuiButtonProps;

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  size = 'medium',
  variant = 'contained',
  color = 'magentaPink',
  className,
  startIcon,
  endIcon,
  onClick,
  isSocialMedia = false,
  children,
  ...props
}) => {
  return (
    <MuiButton
      color={color}
      variant={variant}
      size={size}
      className={className}
      onClick={onClick}
      endIcon={endIcon}
      startIcon={startIcon}
      {...props}
    >
      {children}
    </MuiButton>
  );
};
