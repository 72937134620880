import { BlogsSection } from './components/blogs-section';
import { BlogHero } from './components/blog-hero';
import styles from './styles.module.scss';
import { HtmlParser } from 'components';
import { Loader } from 'design-system';
import { useBlog } from './useBlog';

export const Blog = () => {
  const { blog } = useBlog();

  if (!blog) return <Loader />;
  return (
    <>
      {blog && blog.values && (
        <>
          {blog?.values.image && <BlogHero blog={blog} />}
          <HtmlParser htmlString={blog?.values.content} className={styles.blog__html} />

          <BlogsSection id="you-may-also-read" title="You May Also Read" slice={7} />
        </>
      )}
    </>
  );
};
