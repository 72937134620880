import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { Loader } from 'src/core';
import './styles.css';

type ListingProps = {
  tableHeader?: React.ReactNode;
  tableBody?: React.ReactNode;
  tableToolbar?: React.ReactNode;
  tablePagination?: React.ReactNode;
  hasNoTableData?: React.ReactNode;
  hasNoSearchResults?: React.ReactNode;
  tableContainerProps?: TableContainerProps;
  loadingData?: boolean;
  showTableData?: boolean;
  showHasNoTableData?: boolean;
  showHasNoSearchResults?: boolean;
  showPagination?: boolean;
};

export const Listing = ({
  tableHeader,
  tableBody,
  tableToolbar,
  tablePagination,
  hasNoSearchResults,
  hasNoTableData,
  tableContainerProps,
  loadingData,
  showTableData,
  showHasNoTableData,
  showHasNoSearchResults,
  showPagination,
}: ListingProps) => {
  return (
    <>
      <Box sx={{ boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.08)', paddingBlock: '1px' }}>
        <Box sx={{ boxShadow: '0px 1px 4px 0px rgba(69, 75, 87, 0.12)' }}>
          <Box sx={{ boxShadow: '0px 0px 0px 1px rgba(152, 161, 178, 0.1)' }}>
            {tableToolbar}

            <Divider sx={{ borderColor: 'var(--secondaryLight)' }} />

            <TableContainer {...tableContainerProps}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>{tableHeader}</TableRow>
                </TableHead>

                {loadingData && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: 'center', height: '100px' }}>
                      <Loader />
                    </TableCell>
                  </TableRow>
                )}

                {showTableData && <TableBody>{tableBody}</TableBody>}

                {showHasNoSearchResults && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: 'center', height: '100px' }}>
                      {hasNoSearchResults}
                    </TableCell>
                  </TableRow>
                )}

                {showHasNoTableData && (
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: 'center', height: '100px' }}>
                      {hasNoTableData}
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>

            <TableFooter
              children={<>{showPagination && <Box className="me-8 my-8 h-10">{tablePagination}</Box>}</>}
              sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
