import { Box, ButtonProps, Modal as MuiModal } from '@mui/material';
import { Button } from 'src/core/button';
import { LoadingButton } from '@mui/lab';
import { HTMLAttributes } from 'react';

type ModalProps = {
  open: boolean;
  image?: any;
  header?: any;
  content: any;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  handleClose?: () => void;
  handlePrimary?: () => void;
  modalProps?: HTMLAttributes<HTMLDivElement>;
  contentBoxProps?: HTMLAttributes<HTMLDivElement>;
  loading?: boolean;
};

export const Modal = ({
  open,
  image,
  header,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonProps,
  secondaryButtonProps,
  content,
  handleClose,
  handlePrimary,
  modalProps,
  contentBoxProps,
  loading,
}: ModalProps) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 310,
    bgcolor: 'background.paper',
    border: '1px solid white',
    boxShadow: 24,
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <MuiModal open={open} onClose={handleClose} disableAutoFocus>
      <Box sx={{ ...style, ...modalProps?.style }}>
        {image && <Box className="mx-auto mb-4">{image}</Box>}
        {header && <Box className="mb-4">{header}</Box>}
        {content && <Box {...contentBoxProps}>{content}</Box>}
        {(primaryButtonText || secondaryButtonText) && (
          <Box className="flex justify-end mt-6">
            {secondaryButtonText && (
              <Button variant="text" onClick={handleClose} className="me-2" {...secondaryButtonProps}>
                {secondaryButtonText}
              </Button>
            )}
            {primaryButtonText && (
              <LoadingButton variant="text" onClick={handlePrimary} loading={loading} {...primaryButtonProps}>
                {primaryButtonText}
              </LoadingButton>
            )}
          </Box>
        )}
      </Box>
    </MuiModal>
  );
};
