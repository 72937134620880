import { AppState, RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';

export const useCustomAuth = () => {
  const { loginWithRedirect, ...rest } = useAuth0();

  const customLoginWithRedirect = (
    options: { redirectUri?: string; authOptions?: RedirectLoginOptions<AppState> | undefined } = {},
  ) => {
    const { redirectUri = '/home', authOptions = {} } = options;
    localStorage.setItem('redirectUri', redirectUri);
    loginWithRedirect(authOptions);
  };

  return {
    loginWithRedirect: customLoginWithRedirect,
    ...rest,
  };
};
