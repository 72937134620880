import { RFQDetails as RFQDetailsComponent } from '../components/rfq-details';
import { CancelModal } from '../components/modals/cancel-modal';
import { Quotations } from '../components/quotations';
import { Loader } from 'design-system';
import { Box } from '@mui/material';
import { useRfq } from './useRfq';

export const RFQDetails = () => {
  const {
    id,
    rfqDetails,
    rfqFiles,
    loading,
    rfqQuotations,
    quotationRef,
    cancelModalOpen,
    setCancelModalOpen,
    handleCancelRfq,
  } = useRfq();

  if (!id || !rfqDetails || !rfqQuotations) return <Loader />;

  return (
    <>
      <CancelModal
        open={cancelModalOpen}
        handlePrimaryClick={handleCancelRfq}
        handleClose={() => setCancelModalOpen(false)}
        loading={loading}
      />

      <RFQDetailsComponent
        key={`rfq-details-${id}`}
        rfqDetails={rfqDetails}
        rfqFiles={rfqFiles}
        quotations={rfqQuotations}
        quotationRef={quotationRef}
        setCancelModalOpen={setCancelModalOpen}
      />

      <Box ref={quotationRef}>
        <Quotations rfqDetails={rfqDetails} quotations={rfqQuotations} />
      </Box>
    </>
  );
};
