import { NextArrow, PrevArrow } from 'components';
import Slider, { Settings } from 'react-slick';
import { TierPriceModelDto } from 'codegen';
import { formulateTierPrices } from 'utils';
import { Typography } from 'design-system';
import { HTMLAttributes } from 'react';
import { Box } from '@mui/material';
import './styles.scss';

type Props = {
  tierPrices: TierPriceModelDto[] | null;
  unit: string;
} & HTMLAttributes<HTMLDivElement>;

export const TierPrices: React.FC<Props> = ({ tierPrices, unit, ...props }) => {
  const tierPricesFinal = formulateTierPrices(tierPrices);
  const settings: Settings = {
    className: 'tier__prices__slider',
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    nextArrow: <NextArrow customClassName="w-6 h-6" />,
    prevArrow: <PrevArrow customClassName="w-6 h-6" />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box {...props}>
      <Slider {...settings}>
        {tierPricesFinal?.map((tierPrice, idx) => (
          <Box key={`tier-price-#${idx}`} id="slider-box">
            <Typography variant="body2" component="span" className="text-midLightGray">
              {tierPrice.tierRange}&nbsp;
            </Typography>
            <Typography variant="subtitle1" component="span" className="text-darkGray">
              {tierPrice.price}
            </Typography>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
