import { List, ListProps, styled } from '@mui/material';

export const CustomList = styled(List)<ListProps>(({ theme }) => ({
  listStyleType: 'disc',
  paddingLeft: 32,
  color: 'var(--midDarkGray)',
  paddingTop: 'unset',
  paddingBottom: 'unset',
  '& .MuiListItem-root': {
    display: 'list-item',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '20px',
  },
}));
