import { ReactComponent as WantToGetQuotationIcon } from '../../../../assets/images/want-to-get-quotation.svg';
import { Typography, TextInput, AutocompleteSelect } from 'design-system';
import { useSubmitRfq } from 'pages/rfq/first-step/useSubmitRfq';
import { UnitEnum } from 'services/types/generic/enums';
import { Box, Grid, Hidden } from '@mui/material';
import styles from './styles.module.scss';
import { LoadingButton } from '@mui/lab';

export const CallToAction = () => {
  const { rfqCategoryItems, onSubmit, control, findOtherCategory, userEmail, toListItems, loading, isValid, isDownMd } =
    useSubmitRfq();

  return (
    <Box className={styles.call__to__action__container}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6.5} className="z-10">
          <Box className={styles.rfq__content}>
            <Box className={styles.homepage__rfq__header}>
              <WantToGetQuotationIcon className={isDownMd ? 'w-6 h-6' : 'w-10 h-10'} />
              <Typography variant="h3" className="text-white">
                Get a custom quote in minutes!{' '}
              </Typography>
            </Box>
            <Hidden mdDown>
              <Typography variant="subtitle2" className="text-lightGray max-w-[579px] text-left">
                Can't find what you are looking for? Just a few steps to find your product.
              </Typography>
            </Hidden>
          </Box>
        </Grid>

        <Grid item xs={12} md={5.5}>
          <Grid container spacing={3} className={styles.homepage__rfq__fields}>
            <Grid item xs={12} md={12}>
              <AutocompleteSelect
                name="categoryId"
                label="Product Category"
                placeholder="Select product category"
                options={rfqCategoryItems}
                control={control}
                required
                labelColor="var(--white)"
                inputProps={{
                  arrowColor: 'var(--midDarkGray)',
                  sx: {
                    '&::placeholder': {
                      color: 'var(--midDarkGray)',
                      opacity: 1,
                    },
                    opacity: '0.7 !important',
                  },
                }}
                endAdornmentOpacity={0.7}
              />
            </Grid>

            {findOtherCategory() && (
              <Grid item xs={12} md={12}>
                <TextInput
                  name="otherCategory"
                  label="Please specify other"
                  placeholder="Specify the other category"
                  control={control}
                  InputProps={{
                    inputProps: {
                      maxLength: 40,
                      sx: {
                        '&::placeholder': {
                          color: 'var(--midDarkGray)',
                          opacity: 1,
                        },
                        opacity: 0.7,
                      },
                    },
                  }}
                  labelColor="var(--white)"
                />
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <TextInput
                name="productName"
                label="Product Name"
                placeholder="Enter or specify product name"
                control={control}
                InputProps={{
                  inputProps: {
                    maxLength: 40,
                    sx: {
                      '&::placeholder': {
                        color: 'var(--midDarkGray)',
                        opacity: 1,
                      },
                      opacity: 0.7,
                    },
                  },
                }}
                required
                labelColor="var(--white)"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextInput
                    name="quantity"
                    label="Quantity"
                    placeholder="Enter the quantity"
                    control={control}
                    type="number"
                    required
                    InputProps={{
                      inputProps: {
                        min: 1,
                        pattern: '\\d*',
                        sx: {
                          '&::placeholder': {
                            color: 'var(--midDarkGray)',
                            opacity: 1,
                          },
                          opacity: 0.7,
                        },
                      },
                    }}
                    labelColor="var(--white)"
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutocompleteSelect
                    name="unit"
                    label="Units"
                    placeholder="Select unit"
                    options={toListItems(UnitEnum)}
                    control={control}
                    required
                    labelColor="var(--white)"
                    inputProps={{
                      arrowColor: 'var(--midDarkGray)',
                      sx: {
                        '&::placeholder': {
                          color: 'var(--midDarkGray)',
                          opacity: 1,
                        },
                        opacity: '0.7 !important',
                      },
                    }}
                    endAdornmentOpacity={0.7}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextInput
                name="email"
                label="Email"
                placeholder="Enter your email"
                control={control}
                disabled={!!userEmail}
                required={!userEmail}
                labelColor={userEmail ? 'var(--midLightGray)' : 'var(--white)'}
                sx={{ opacity: userEmail ? 0.5 : '' }}
                InputProps={{
                  inputProps: {
                    sx: {
                      '&::placeholder': {
                        color: 'var(--midDarkGray)',
                        opacity: userEmail ? 0.5 : 1,
                      },

                      opacity: userEmail ? '' : '0.7 !important',
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <LoadingButton
                size="large"
                variant="contained"
                className="w-full"
                onClick={onSubmit}
                disabled={!isValid}
                loading={loading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
