import { RequestQuotation } from '../request-quotation';
import { CategoriesMenu } from '../../categories-menu';
import { BecomeASeller } from '../../become-a-seller';
import { AppContainer } from 'design-system';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

export const OtherSecondaryHeader = () => {
  return (
    <>
      <AppContainer>
        <Box className={styles.other__secondary__header}>
          <CategoriesMenu />
          <BecomeASeller />
          <RequestQuotation />
        </Box>
      </AppContainer>
    </>
  );
};
