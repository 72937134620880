import { ReactComponent as RecentIcon } from 'design-system/public/icons/search-recent.svg';
import { ReactComponent as TrashIcon } from 'design-system/public/icons/search-trash.svg';
import { ReactComponent as ArrowIcon } from 'design-system/public/icons/search-arrow.svg';
import { AutocompleteState } from '@algolia/autocomplete-core';
import { getCollectionBySourceId } from '../../functions';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'design-system';
import { Highlight } from '../highlight';
import { RecentHit } from '../../types';

type RecentSearchItemProps = {
  hit: RecentHit;
  removeItem: Function;
  autocomplete: any;
};
type Props = {
  autocomplete: any;
  autocompleteState: AutocompleteState<any>;
  filteredItems: any;
  removeItem: Function;
};

const RecentSearchItem = ({ hit, removeItem, autocomplete }: RecentSearchItemProps) => {
  return (
    <div className="aa-ItemWrapper">
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--noBorder">
          <RecentIcon />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <Typography variant="body1" className="text-midDarkGray line-clamp-1">
              <Highlight hit={hit} attribute="label" />
            </Typography>
          </div>
        </div>
      </div>
      <div className="aa-ItemActions">
        <button className="aa-ItemActionButton" onClick={e => removeItem(e, hit.label)}>
          <TrashIcon />
        </button>
        <button
          className="aa-ItemActionButton"
          onClick={e => {
            e.stopPropagation();
            autocomplete.setQuery(hit.label);
            autocomplete.refresh();
          }}
        >
          <ArrowIcon />
        </button>
      </div>
    </div>
  );
};

export const RecentSearches = ({ autocomplete, autocompleteState, filteredItems, removeItem }: Props) => {
  const recentSearches = getCollectionBySourceId(autocompleteState, 'recentSearchesPlugin');
  const navigate = useNavigate();

  return (
    <>
      {recentSearches && recentSearches.items.length > 0 && (
        <section className="aa-Source">
          <ul className="aa-List" {...autocomplete.getListProps()}>
            {filteredItems.slice(0, 4).map((item, idx) => {
              return (
                <li
                  key={`recent-search-item-${idx}`}
                  className="aa-Item"
                  onClick={() => navigate(`/products?search=${item.label}`, { state: { searchName: item.label } })}
                >
                  <RecentSearchItem hit={item} removeItem={removeItem} autocomplete={autocomplete} />
                </li>
              );
            })}
          </ul>
        </section>
      )}
    </>
  );
};
