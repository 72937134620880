import { getAlgoliaFacets } from '@algolia/autocomplete-js';
import { ALGOLIA_PRODUCTS_INDEX_NAME } from '../utils';
import { searchClient } from 'utils';

export const vendorsPlugin = {
  getSources({ query }) {
    return [
      {
        sourceId: 'vendorsPlugin',
        getItems() {
          return getAlgoliaFacets({
            // @ts-ignore
            searchClient,
            queries: [
              {
                indexName: ALGOLIA_PRODUCTS_INDEX_NAME,
                facet: 'vendor',
                params: {
                  facetQuery: query,
                  maxFacetHits: 2,
                },
              },
            ],
          });
        },
      },
    ];
  },
};
