import { ReactComponent as FilterIcon } from 'design-system/public/icons/filter-narrow.svg';
import { CheckboxFilter, ResultsHeader, NopHits, Slider, Sort, PageSize } from 'components';
import { Button, Loader, Pagination, Typography } from 'design-system';
import { CategorySlider } from './components/category-slider';
import { Box, Divider, Grid } from '@mui/material';
import { useCategory } from './useCategory';
import styles from './styles.module.scss';

export const Category = () => {
  const {
    categoryId,
    categoryProductsResponse,
    categoryDetails,
    pageSizeOptions,
    itemsCount,
    pagesCount,
    pageNumber,
    pageSize,
    setPageSize,
    orderBy,
    availableSortOptions,
    setOrderBy,
    handleChangePage,
    priceRange,
    setPriceRange,
    handleFilterRequest,
    manufacturerOptions,
    onChangeManufacturer,
    loading,
    isDownMd,
  } = useCategory();

  if (loading || !categoryProductsResponse) return <Loader />;

  return (
    <Grid container spacing={{ xs: 0, md: 3 }}>
      {!isDownMd && (
        <Grid item xs={12} md={2.5}>
          <Box className={styles.filter__box}>
            <Typography variant="h5" component="h5" className={styles.section__subtitle}>
              Filter Items
            </Typography>

            <Divider className="mb-6" />

            <Typography variant="subtitle1" className="text-darkGray capitalize mb-3">
              Price Range
            </Typography>

            <Slider
              min={0}
              max={categoryProductsResponse?.catalogProductsModel?.priceRangeFilter?.availablePriceRange.to || 10000}
              value={priceRange}
              onChange={setPriceRange}
            />

            <Divider className="my-8" />

            <CheckboxFilter manufacturerOptions={manufacturerOptions} onChangeManufacturer={onChangeManufacturer} />

            <Button
              className="mt-3 hover:bg-brand2"
              startIcon={<FilterIcon stroke="var(--white)" />}
              onClick={handleFilterRequest}
            >
              Filter
            </Button>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} md={9.5}>
        {categoryDetails && <CategorySlider categoryDetails={categoryDetails} />}

        <ResultsHeader
          itemsCount={itemsCount}
          pageSizeComponent={
            <PageSize
              key={`category-${categoryId}-page-size`}
              pageSize={pageSize.toString()}
              pageSizeOptions={pageSizeOptions}
              setPageSize={setPageSize}
            />
          }
          sortComponent={
            <Sort
              key={`category-${categoryId}-sort`}
              orderBy={orderBy}
              availableSortOptions={availableSortOptions}
              setOrderBy={setOrderBy}
            />
          }
          manufacturerOptions={manufacturerOptions}
          onChangeManufacturer={onChangeManufacturer}
          onFilter={handleFilterRequest}
          categoryProductsResponse={categoryProductsResponse}
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />

        <NopHits hits={categoryProductsResponse?.catalogProductsModel?.products} />

        {categoryProductsResponse.catalogProductsModel.totalItems > 0 && (
          <Pagination
            key={`category-${categoryId}-pagination`}
            pagesCount={pagesCount}
            pageNumber={pageNumber}
            handleChangePage={handleChangePage}
          />
        )}
      </Grid>
    </Grid>
  );
};
