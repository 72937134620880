import { useCustomAuth } from './useCustomAuth';
import { useEffect, useState } from 'react';

export const useAuthToken = () => {
  const [token, setToken] = useState<string>();
  const { getAccessTokenSilently } = useCustomAuth();

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.log(error);
      }
    };

    getToken();
  }, [getAccessTokenSilently]);

  return {
    token,
  };
};
