import { useMessageCenterContext } from 'contexts/message-center-context';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { BaseMessage } from '@sendbird/chat/message';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { useCustomAuth } from 'hooks';
import { Box } from '@mui/material';

export const ChannelPreview = (props: { channel: GroupChannel }) => {
  const { currentChannelUrl, setCurrentChannelUrl } = useMessageCenterContext();
  const { channel } = props;
  const [secondUser, setSecondUser] = useState<any>();
  const timeStamp = new Date(channel.lastMessage?.createdAt!);
  const { user } = useCustomAuth();

  useEffect(() => {
    setSecondUser(channel.members.filter(member => member.userId !== user?.email && member.role !== 'operator')[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatTimestamp = timestamp => {
    if (isNaN(timestamp)) return '';
    const now = new Date();
    const date = new Date(timestamp);
    const timeDiff = now.getTime() - date.getTime();
    const oneMinute = 60 * 1000;
    const oneHour = 60 * oneMinute;
    const oneDay = 24 * oneHour;

    if (timeDiff < oneDay && date.getDate() !== now.getDate()) {
      return 'Yesterday';
    } else if (timeDiff < oneDay) {
      return date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });
    } else {
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
      };
      return date.toLocaleDateString(undefined, options);
    }
  };

  const shortenMessage = (lastMessage: BaseMessage | null): string => {
    if (!lastMessage) return '';
    if (lastMessage.messageType !== 'user') {
      let type: string = (lastMessage as any).type.split('/')[0];
      if (type !== 'image' && type !== 'audio') type = lastMessage.messageType;
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
    const input = (lastMessage as any).message;
    const maxLength = 25;
    if (input.length > maxLength) {
      return input.substring(0, maxLength - 3) + '...';
    }
    return input;
  };

  const shortenText = (text: string | undefined): string | undefined => {
    if (!text) return;
    const maxLength = 15;
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + '...';
    }
    return text;
  };

  return (
    <div onClick={() => setCurrentChannelUrl(channel.url)}>
      <div
        className={`h-14 flex ps-6 pe-3 py-2 items-center justify-between ${
          channel.url === currentChannelUrl ? 'bg-antiFlashWhite' : 'bg-white'
        }`}
      >
        <img src={secondUser?.profileUrl} className="rounded-full w-10 h-10 me-2" alt="profile_image" />

        <Box className="w-full">
          <Box className="flex justify-between">
            <Typography variant="body2" className="text-darkGray">
              {shortenText(channel.data)}
            </Typography>
            <Typography variant="caption" className="text-midLightGray">
              {formatTimestamp(timeStamp)}
            </Typography>
          </Box>

          <Box className="flex justify-between">
            <Typography variant="caption" className="text-midLightGray">
              {shortenMessage(channel.lastMessage)}
            </Typography>

            {channel.unreadMessageCount > 0 && (
              <div className={styles.unread__bubble}>
                <Typography variant="caption">{channel.unreadMessageCount}</Typography>
              </div>
            )}
          </Box>
        </Box>
      </div>
      <Divider />
    </div>
  );
};
