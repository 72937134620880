import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { PropsWithChildren } from 'react';
import * as React from 'react';

export const ReCaptchaProvider: React.FC<PropsWithChildren<any>> = ({ children, siteKey }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={siteKey}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
      container={{
        parameters: {
          badge: 'bottomleft',
          theme: 'dark',
        },
      }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};
