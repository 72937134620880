import { CatalogProductsModelDto, CategoryModelDto, SelectListItemDto } from 'codegen';
import { getCategoryById, getCategoryProducts } from 'services/orchestrator.service';
import { BreadcrumbItem, useBreadcrumbs } from 'contexts/breadcrumbContext';
import { ChangeEvent, useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { sendEventToGTM } from 'utils';

export const useCategory = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { categoryId, seName } = useParams();
  const [categoryDetails, setCategoryDetails] = useState<{ categoryModelDto: CategoryModelDto }>();
  const [categoryProductsResponse, setCategoryProductsResponse] = useState<{
    catalogProductsModel: CatalogProductsModelDto;
  }>();
  const [pageSize, setPageSize] = useState<number>(40);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<number>(5);
  const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);
  const [manufacturerOptions, setManufacturerOptions] = useState<SelectListItemDto[] | null>();
  const [manufacturerIds, setManufacturerIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  const itemsCount = categoryProductsResponse?.catalogProductsModel.totalItems;
  const pagesCount = categoryProductsResponse?.catalogProductsModel.totalPages;
  const pageSizeOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: true,
      text: '40',
      value: '40',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '60',
      value: '60',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: '100',
      value: '100',
    },
  ];
  const availableSortOptions: SelectListItemDto[] = [
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: A to Z',
      value: '5',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Name: Z to A',
      value: '6',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: Low to High',
      value: '10',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Price: High to Low',
      value: '11',
    },
    {
      disabled: false,
      group: null,
      selected: false,
      text: 'Created on',
      value: '15',
    },
  ];

  const handleFilterRequest = () => {
    if (categoryId) {
      setLoading(true);
      getCategoryProducts({
        categoryId: +categoryId,
        body: {
          price: `${priceRange[0]} - ${priceRange[1]}`,
          pageSize,
          pageNumber,
          orderBy,
          manufacturerIds,
        },
      }).then(res => {
        setCategoryProductsResponse(res?.data);
        setPageNumber(res?.data?.catalogProductsModel?.pageNumber);
        setLoading(false);
        const rangeFrom = res?.data?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.from;
        const rangeTo = res?.data?.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.to;
        rangeFrom && rangeTo && setPriceRange([rangeFrom, rangeTo]);
      });
    }
  };

  const onChangeManufacturer = (e, v) => {
    if (v === true) {
      setManufacturerIds(() => [...manufacturerIds, +e.target.value]);
    } else {
      setManufacturerIds(manufacturerIds.filter(item => item !== +e.target.value));
    }
  };

  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      getCategoryProducts({
        categoryId: +categoryId,
        body: {
          price: `${priceRange[0]} - ${priceRange[1]}`,
          pageSize,
          pageNumber,
          orderBy,
          manufacturerIds,
        },
      }).then(res => {
        setLoading(false);
        setCategoryProductsResponse(res?.data);
        setPageNumber(res?.data?.catalogProductsModel?.pageNumber);
        setManufacturerOptions(res?.data?.catalogProductsModel?.manufacturerFilter?.manufacturers);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 150);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, pageSize, pageNumber, orderBy]);

  useEffect(() => {
    if (categoryId) {
      Promise.all([getCategoryById({ categoryId: +categoryId })]).then(([{ data: categoryDetails }]) => {
        setCategoryDetails(categoryDetails);

        const rangeFrom =
          categoryDetails.categoryModelDto.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.from;
        const rangeTo =
          categoryDetails.categoryModelDto.catalogProductsModel?.priceRangeFilter?.availablePriceRange?.to;
        setPriceRange([rangeFrom!, rangeTo!]);

        const breadcrumbs: BreadcrumbItem[] = [
          ...(categoryDetails.categoryModelDto.categoryBreadcrumb || []).map(item => {
            return {
              label: item.name,
              link: `/category/${item.id}/${item.seName}`,
            };
          }),
        ];

        updateBreadcrumbs(breadcrumbs);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    updateBreadcrumbs([]);
    sendEventToGTM('category_view', {
      category_id: categoryId,
      category_se_name: seName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isDownMd,
    categoryId,
    categoryProductsResponse,
    categoryDetails,
    loading,
    itemsCount,
    pagesCount,
    pageSize,
    pageSizeOptions,
    setPageSize: (val: number) => {
      setPageSize(val);
    },

    orderBy,
    availableSortOptions,
    setOrderBy: (val: number) => {
      setOrderBy(val);
    },

    pageNumber,
    handleChangePage: (val: number) => {
      setPageNumber(val);
    },

    priceRange,
    setPriceRange: (event: Event, newValue: number | number[]) => {
      setPriceRange(newValue as number[]);
    },

    handleFilterRequest: () => {
      handleFilterRequest();
    },

    manufacturerOptions,
    onChangeManufacturer: (e: ChangeEvent<HTMLInputElement>, v: boolean) => {
      onChangeManufacturer(e, v);
    },
  };
};
