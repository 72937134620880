import { Button, SentSuccessfully, Typography } from 'design-system';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect } from 'react';

export const SubmitSuccess = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ Submission', link: '/rfq/submit-success' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0 pt-2">
      <Box className="flex flex-col justify-center items-center">
        <Box className={styles.lottie__container}>
          <SentSuccessfully />
        </Box>
        <Typography variant="h4" component="h4" className="text-darkGray mb-2">
          Your request submitted successfully
        </Typography>
        <Typography variant="body1" className="text-midLightGray mb-4">
          to see all RFQs go to&nbsp;
        </Typography>
        <Link to="/rfq">
          <Button size="large">Manage RFQ</Button>
        </Link>
      </Box>
    </Box>
  );
};
