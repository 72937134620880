import { GetBlogsDto } from 'services/dtos/hubspot/GetBlogs.dto';
import { getBlogs } from 'services/orchestrator.service';
import { useEffect, useState } from 'react';

export const useBlogs = () => {
  const [blogs, setBlogs] = useState<GetBlogsDto>();

  useEffect(() => {
    Promise.all([getBlogs()]).then(([{ data: blogs }]) => {
      setBlogs(blogs);
    });
  }, []);

  return {
    blogs,
  };
};
