import { Typography } from 'src/core/typography';

type ErrorFeedbackProps = {
  fieldState: any;
};

export const ErrorFeedback = ({ fieldState }: ErrorFeedbackProps) => {
  return (
    <>
      {fieldState.error && (
        <Typography variant="body2" className="mt-3 text-red">
          {fieldState.error.message}
        </Typography>
      )}
    </>
  );
};
