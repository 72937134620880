import { ReactComponent as RfqArt3 } from 'assets/images/rfq-arts/third-step-rfq-art.svg';
import { Typography, TextInput, AutocompleteSelect, Loader } from 'design-system';
import { ShipmentTermEnum } from 'services/types/generic/enums';
import { Box, Divider, Grid, Hidden } from '@mui/material';
import { Stepper } from '../components/stepper';
import { useSubmitRfq } from './useSubmitRfq';
import styles from './styles.module.scss';
import { LoadingButton } from '@mui/lab';

export const RfqThirdStep = () => {
  const {
    onSubmit,
    control,
    countries,
    states,
    getValues,
    isDownMd,
    isValid,
    pageLoading,
    requestLoading,
    toListItems,
    paymentTerms,
  } = useSubmitRfq();

  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  if (pageLoading) return <Loader />;
  return (
    <>
      <Box className={styles.container}>
        <Hidden mdDown>
          <Box className={styles.rfq__art}>
            <Typography variant="h6" className="text-violet">
              Great, you have reached your final step!
            </Typography>
            <RfqArt3 />
            <Typography variant="subtitle2" className="text-midDarkGray pt-3 max-w-[358px] text-center">
              Please choose your preferred shipping method and click submit.
            </Typography>
          </Box>
        </Hidden>

        <Box className={styles.rfq__form}>
          <Grid container spacing={isDownMd ? 2.5 : 5} className="justify-center w-full">
            <Grid item xs={12} md={9}>
              <Stepper currentStep={3} />
            </Grid>

            <Hidden mdUp>
              <Grid item xs={12} md={9}>
                <Typography variant="h6" className="text-violet">
                  Great, you have reached your final step!
                </Typography>
              </Grid>
            </Hidden>

            <Grid item xs={12} md={9}>
              <AutocompleteSelect
                name="shipmentTerm"
                label="Shipping Terms"
                placeholder="Select shipping terms"
                options={toListItems(ShipmentTermEnum)}
                control={control}
                required
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <AutocompleteSelect
                name="country"
                label="Shipping Destination"
                placeholder="Select shipping destination"
                options={countries}
                control={control}
                required
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <AutocompleteSelect
                name="state"
                label="Shipping State"
                placeholder="Select shipping state"
                options={states}
                control={control}
                disabled={!getValues('country')}
                required
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <TextInput
                // changed from address to adrs to prevent browser autocomplete
                name="adrs"
                label="Full Address"
                control={control}
                // removed address to prevent browser autocomplete
                placeholder="Enter your full delivery information"
                InputProps={{ inputProps: { maxLength: 125 } }}
                required
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Divider className="pt-5" />
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={isDownMd ? 12 : 6}>
                  <TextInput
                    name="targetUnitPrice"
                    label="Target Unit Price"
                    placeholder="Enter the price you prefer / unit"
                    control={control}
                    type="number"
                    required
                    InputProps={{
                      inputProps: { min: 1, pattern: '\\d*' },
                      endAdornment: (
                        <Box className="flex items-center h-12 bg-white pe-3">
                          <Divider
                            orientation="vertical"
                            className="mr-2 h-8"
                            sx={{ borderColor: 'var(--lightGray)' }}
                          />
                          <Typography variant="caption">
                            <p className="w-12"> USD</p>
                          </Typography>
                        </Box>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={isDownMd ? 12 : 6}>
                  <AutocompleteSelect
                    name="paymentTerms"
                    label="Payment Terms"
                    placeholder="Choose the payment terms you prefer"
                    options={paymentTerms}
                    control={control}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={9}>
              <LoadingButton
                size="large"
                variant="contained"
                className="w-full"
                onClick={onSubmit}
                disabled={!isValid}
                loading={requestLoading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
