import { ReactComponent as Warning } from 'design-system/public/icons/warning.svg';
import { Modal, Typography } from 'design-system';

type Props = {
  open: boolean;
  handlePrimaryClick: () => void;
  handleClose: () => void;
  loading: boolean;
};

export const CancelModal = ({ open, handlePrimaryClick, handleClose, loading }: Props) => {
  return (
    <Modal
      open={open}
      image={<Warning />}
      primaryButtonText="Close RFQ"
      secondaryButtonText="Cancel"
      header={
        <Typography variant="h6" component="h6" className="text-center">
          Close the RFQ ?
        </Typography>
      }
      content={
        <Typography variant="body2" className="text-midDarkGray">
          Closing the RFQ will prevent you from receiving any quotations from our suppliers. Are you sure you want to
          proceed with the closure?
        </Typography>
      }
      handleClose={handleClose}
      handlePrimary={handlePrimaryClick}
      loading={loading}
    />
  );
};
