import { HeaderHandler } from './components/headers/header-handler';
import { FooterHandler } from './components/footers/footer-handler';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useCustomAuth, useScrollToTop } from 'hooks';
import { AppContainer } from 'design-system';
import { Outlet } from 'react-router-dom';

type LayoutProps = {
  containerFloating?: boolean;
  hasFooter?: boolean;
};

export const Layout = ({ containerFloating, hasFooter = true }: LayoutProps) => {
  useScrollToTop();
  const { isAuthenticated } = useCustomAuth();

  if (isAuthenticated) {
    document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  } else {
    document.querySelector('#hubspot-messages-iframe-container')?.classList.remove('translate-x-full');
  }

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <>
      {!containerFloating && (
        <>
          <Box className="pb-[100px] max-sm:pb-[50px] bg-offWhiteBg">
            <HeaderHandler />

            <AppContainer className="relative" id="router-outlet">
              <Box className="mt-6 min-h-[66vh] max-sm:min-h-[40vh] max-sm:mt-5">
                <Outlet />
              </Box>
            </AppContainer>
          </Box>
          <FooterHandler hasFooter={hasFooter} />
        </>
      )}

      {containerFloating && (
        <Box className={isDownMd ? ' bg-offWhiteBg' : ''}>
          <HeaderHandler />
          <Box id="router-outlet">
            <Outlet />
          </Box>
          <FooterHandler hasFooter={hasFooter} />
        </Box>
      )}
    </>
  );
};
