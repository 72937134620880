import { ReactComponent as CaretRight } from 'design-system/public/icons/caret-right.svg';
import { useMessageCenterContext } from 'contexts/message-center-context';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useEffect, useState } from 'react';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useCustomAuth } from 'hooks';
import { Box } from '@mui/material';

export const ChannelHeaderResponsive = () => {
  const { currentChannelUrl, sb } = useMessageCenterContext();
  const [channel, setChannel] = useState<GroupChannel>();
  const [secondUser, setSecondUser] = useState<any>();
  const { user } = useCustomAuth();

  useEffect(() => {
    const getChannel = async () => {
      const channel = await sb?.groupChannel.getChannel(currentChannelUrl);
      setChannel(channel);
      setSecondUser(channel?.members.filter(member => member.userId !== user?.email && member.role !== 'operator')[0]);
    };
    getChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannelUrl]);

  return (
    <Box className={`${styles.message__center__channel__header}`}>
      <Link to="/message-center">
        <CaretRight
          stroke="var(--midLightGray)"
          width="30px"
          height="30px"
          className="me-1"
          style={{ transform: 'rotate(180deg)' }}
        />
      </Link>
      {secondUser && (
        <Box className="flex items-center">
          <img src={secondUser?.profileUrl} className="rounded-full w-8 h-8 mr-3" alt="profile" />
          <Typography variant="subtitle1" className="text-darkGray">
            {channel?.data}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
