import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { Typography } from 'design-system';
import { BlogGrid } from 'components';
import { useEffect } from 'react';
import { useBlogs } from 'hooks';

export const Blogs = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();
  const { blogs } = useBlogs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'Blogs', link: '/blogs' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h4" component="h4" className="mb-6 max-sm:mb-3">
        Blogs
      </Typography>

      <BlogGrid blogResults={blogs?.results} />
    </>
  );
};
