import { default as ReactPhoneInput, isValidPhoneNumber, Country } from 'react-phone-number-input';
import { Controller, Control } from 'react-hook-form';
import { ErrorFeedback } from '../error-feedback';
import { TextFieldProps } from '@mui/material';
import 'react-international-phone/style.css';
import 'react-phone-number-input/style.css';
import { InputLabel } from '../input-label';
import './styles.css';

type PhoneInputProps = {
  name: string;
  control: Control<any>;
  label: string;
  selectedCountry?: Country | undefined;
  handleCountryChange?: any;
} & TextFieldProps;

export const PhoneInput: React.FC<PhoneInputProps> = ({
  name,
  control,
  handleCountryChange,
  label,
  selectedCountry,
  ...props
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ validate: isValidPhoneNumber }}
        render={({ field, fieldState }) => {
          const { onChange, value } = field;
          return (
            <>
              {label && <InputLabel label={label} required={props.required} disabled={props.disabled} />}

              <ReactPhoneInput
                {...field}
                name={`${name}-${Math.random()}`}
                international
                countryCallingCodeEditable={false}
                value={value}
                onChange={onChange}
                defaultCountry={selectedCountry || 'EG'}
                onCountryChange={handleCountryChange && handleCountryChange}
                className={`${fieldState.error ? 'field-error' : 'field'}`}
              />

              <ErrorFeedback fieldState={fieldState} />
            </>
          );
        }}
      />
    </>
  );
};
