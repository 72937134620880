export const Colors = {
  red: '#FF0000',
  magentaPink: '#CC3399',
  magentaHover: '#A02878',
  deepYellow: '#FF9933',
  deepYellowHover: '#E28D30',
  violet: '#6A52AA',
  green: '#3BB77E',
  darkGray: '#121212',
  midDarkGray: '#575757',
  midLightGray: '#999999',
  gray: '#cccccc',
  lightGray: '#E4E4E4',
  secondaryLight: '#F7F8F9',
  antiFlashWhite: '#F2F3F4',
  offWhiteBg: '#FAFAFA',
  white: '#FFFFFF',
};
