import { getRfqByToken, updateThirdStepRfq } from 'services/orchestrator.service';
import { RfqStatusEnum } from 'services/types/generic/enums';
import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { RFQ } from 'services/types/generic/rfq-entity';
import { useMediaQuery, useTheme } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Country, State } from 'country-state-city';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendEventToGTM } from 'utils';
import * as Yup from 'yup';
import _ from 'lodash';

export const useSubmitRfq = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { updateBreadcrumbs } = useBreadcrumbs();
  const [states, setStates] = useState<any[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  const token = pathname.split('/').pop();
  const paymentTerms = [
    { value: 'Pay on Delivery', label: 'Pay on Delivery' },
    { value: 'Letter of Credit', label: 'Letter of Credit' },
    { value: 'Bank Transfer', label: 'Bank Transfer' },
    { value: 'Pay Online', label: 'Pay Online' },
    { value: 'Others', label: 'Others' },
  ];

  const formSchema = Yup.object().shape({
    shipmentTerm: Yup.string().required('This field is required'),
    country: Yup.string().required('This field is required'),
    state: Yup.string().required('This field is required'),
    adrs: Yup.string().trim().required('This field is required'),
    paymentTerms: Yup.string().trim().required('This field is required'),
    targetUnitPrice: Yup.number()
      .min(1, 'Quantity must be at least 1')
      .typeError('This field is required')
      .required('This field is required'),
  });
  const {
    formState: { isValid },
    getValues,
    watch,
    control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const countries = Country.getAllCountries().map(item => {
    return { label: item.name, value: item.name };
  });

  const getCountryCodeByName = country => {
    return Country.getAllCountries().find(item => item.name === country)?.isoCode;
  };

  const onSubmit = async () => {
    const values = getValues();
    setRequestLoading(true);
    try {
      const { data } = await updateThirdStepRfq({
        body: {
          shipmentTerm: values.shipmentTerm,
          country: values.country,
          state: values.state,
          address: values.adrs,
          targetUnitPrice: values.targetUnitPrice,
          paymentTerms: values.paymentTerms,
        },
        token: token!,
      });
      sendEventToGTM('user_submit_rfq', { rfq_short_id: data.shortID });
      navigate('/rfq/submit-success', { replace: true });
    } catch {
      setRequestLoading(false);
    }
  };

  const checkRfq = async (rfq: RFQ) => {
    if (rfq.status !== RfqStatusEnum.Draft) navigate(`/rfq/details/${rfq.id}`, { replace: true });
  };

  const toListItems = (obj: Record<string, string>) => {
    const listItems: { label: string; value: string }[] = [];
    for (const key in obj) {
      const label = key;
      const value: string = obj[key];
      listItems.push({ label, value });
    }
    return _.sortBy(listItems, ['label']);
  };

  useEffect(() => {
    updateBreadcrumbs([{ label: 'RFQ Submission', link: '/rfq/submit/third-step' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch(form => {
      const countryCode = getCountryCodeByName(form.country);
      setStates(
        State.getStatesOfCountry(countryCode).map(item => {
          return { label: item.name, value: item.name };
        }),
      );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const elements = document.getElementsByTagName('input');
    for (const element of elements) {
      //@ts-ignore
      element.autocomplete = 'new-password';
    }
  }, []);

  useEffect(() => {
    if (token) {
      const decodedToken: JwtPayload = jwtDecode(token);
      if (decodedToken.exp! * 1000 < Date.now()) {
        navigate(`/rfq/email-expired`);
        setPageLoading(false);
      }
      getRfqByToken(token).then(rfqData => {
        checkRfq(rfqData.data);
        setPageLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    onSubmit,
    control,
    countries,
    states,
    isValid,
    getValues,
    isDownMd,
    pageLoading,
    requestLoading,
    toListItems,
    paymentTerms,
  };
};
