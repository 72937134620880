import { Tab as MUITab, TabProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Tab = styled(MUITab)<TabProps>(({ theme }) => ({
  fontFamily: 'Lato',
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '20px',
  color: 'var(--midLightGray)',
  textTransform: 'none',
  border: '1px solid var(--antiFlashWhite)',
  marginInlineEnd: 25,
  boxShadow: '5px 5px 15px rgba(24, 24, 24, 0.05)',
  padding: '12px',
  minHeight: '46px',
  height: '46px',
  backgroundColor: 'var(--white)',
  '&.Mui-selected': {
    color: 'var(--magentaPink)',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));
