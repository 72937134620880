import { PaletteColorOptions, createTheme } from '@mui/material';
import { Colors } from './colors';

const defaultTheme = createTheme({});
const { breakpoints, palette } = defaultTheme;

declare module '@mui/material/styles' {
  interface Palette {
    magentaPink: PaletteColorOptions;
    deepYellow: PaletteColorOptions;
    violet: PaletteColorOptions;
    green: PaletteColorOptions;
    white: PaletteColorOptions;
  }
  interface PaletteOptions {
    magentaPink: PaletteColorOptions;
    deepYellow: PaletteColorOptions;
    violet: PaletteColorOptions;
    green: PaletteColorOptions;
    white: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    red: true;
    magentaPink: true;
    deepYellow: true;
    violet: true;
    green: true;
    white: true;
  }
}

const font = "'Lato', GilroyBold";

export const baseTheme = createTheme({
  typography: {
    fontFamily: font,
    h1: {
      fontFamily: 'GilroyBold',
      fontSize: '64px',
      lineHeight: '80px',
      [breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: 'normal',
      },
    },
    h2: {
      fontFamily: 'GilroyBold',
      fontSize: '48px',
      lineHeight: '64px',
      [breakpoints.down('sm')]: {
        fontSize: '18px',
        lineHeight: 'normal',
      },
    },
    h3: {
      fontFamily: 'GilroyBold',
      fontSize: '32px',
      lineHeight: '48px',
      [breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: 'normal',
      },
    },
    h4: {
      fontFamily: 'GilroyBold',
      fontSize: '24px',
      lineHeight: '36px',
      [breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: 'normal',
      },
    },
    h5: {
      fontFamily: 'GilroyBold',
      fontSize: '20px',
      lineHeight: '30px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
    h6: {
      fontFamily: 'GilroyBold',
      fontSize: '18px',
      lineHeight: '28px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: 'normal',
      },
    },
    subtitle1: {
      fontFamily: 'Lato',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '24px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
    subtitle2: {
      fontFamily: 'Lato',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '22px',
      [breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    body1: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    body2: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '22px',
      [breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    caption: {
      fontFamily: 'Lato',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '18px',
      [breakpoints.down('sm')]: {
        fontSize: '10px',
        lineHeight: 'normal',
      },
    },
    overline: {
      fontFamily: 'Lato',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'none',
      [breakpoints.down('sm')]: {
        fontSize: '10px',
        lineHeight: 'normal',
      },
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: Colors.darkGray,
          '&.Mui-disabled': {
            color: Colors.gray,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          input: { padding: '16px 20px !important', color: 'var(--darkGray)', height: '100%' },
          paddingRight: '9px !important',
          '&.Mui-focused': {
            borderColor: 'violet',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0px',
          padding: '0px !important',
          '&.Mui-disabled': {
            color: 'var(--lightGray)',
            '-webkit-text-fill-color': 'var(--lightGray)',
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--lightGray)',
            },
          },
        },
        multiline: {
          padding: '16.5px 14px !important',
          backgroundColor: 'var(--white)',
        },
        input: {
          color: 'black',
          height: '16px',
          fontSize: '14px',
          backgroundColor: 'var(--white)',
          '-webkit-text-fill-color': 'unset !important',
          [breakpoints.down('sm')]: {
            fontSize: '12px',
          },
          '::placeholder': {
            color: 'var(--gray)',
            fontSize: '12px',
            opacity: '1 !important',
            [breakpoints.down('sm')]: {
              fontSize: '10px',
            },
          },
          ':disabled': {
            '-webkit-text-fill-color': 'var(--midLightGray) !important',
          },
        },
        notchedOutline: {
          borderColor: 'var(--lightGray)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            padding: '0px !Important',
            height: '100%',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        text: {
          '& .MuiPaginationItem-page, & .MuiPaginationItem-previousNext': {
            backgroundColor: 'var(--white)',
            color: 'var(--darkGray)',
            fontWeight: 'bolder',
            border: '1px solid var(--antiFlashWhite)',
          },
          '& .MuiPaginationItem-page:hover, & .Mui-selected, & .Mui-selected:hover, & .MuiPaginationItem-previousNext:hover':
            {
              backgroundColor: 'var(--magentaPink) !important',
              color: 'var(--white)',
              fontWeight: 'bolder',
            },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        text: {
          '&.Mui-selected': {
            backgroundColor: 'var(--magentaPink) !important',
            color: 'var(--white)',
            fontWeight: 'bolder',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'var(--body)',
          zIndex: 1,
        },
        select: {
          display: 'flex',
          alignItems: 'center',
          paddingRight: '16px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato',
          textTransform: 'none',
          borderRadius: '0px',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '20px',
          boxShadow: 'unset',
          height: '40px',
          ':hover': {
            boxShadow: 'unset',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            '&.Mui-disabled': {
              backgroundColor: `${Colors.lightGray} !important`,
              color: `${Colors.midLightGray} !important`,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderWidth: '1.5px',
            '&.Mui-disabled': {
              backgroundColor: `${Colors.white} !important`,
              color: `${Colors.gray} !important`,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'magentaPink' },
          style: {
            backgroundColor: 'var(--magentaPink) !important',
            ':hover': {
              backgroundColor: `${Colors.magentaHover} !important`,
              color: Colors.white,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'magentaPink' },
          style: {
            ':hover': {
              backgroundColor: Colors.magentaPink,
              color: Colors.white,
            },
          },
        },
        {
          props: { color: 'deepYellow' },
          style: {
            ':hover': {
              backgroundColor: Colors.deepYellowHover,
            },
          },
        },
        {
          props: { color: 'white' },
          style: {
            backgroundColor: Colors.white,
            color: Colors.magentaPink,
            ':hover': {
              backgroundColor: 'transparent',
              color: Colors.white,
              border: '1px solid var(--white)',
            },
          },
        },
        {
          props: { color: 'violet' },
          style: {
            ':hover': {
              backgroundColor: Colors.violet,
              color: Colors.white,
            },
          },
        },
        {
          props: { size: 'small' },
          style: { fontSize: '12px', paddingBlock: '4px !important', paddingInline: '10px !important' },
        },
        {
          props: { size: 'medium' },
          style: { fontSize: '14px', paddingBlock: '6px !important', paddingInline: '16px !important' },
        },
        {
          props: { size: 'large' },
          style: { fontSize: '16px', paddingBlock: '10px !important', paddingInline: '22px !important' },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 0,
          [breakpoints.down('sm')]: {
            boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.12)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: 'transparent',
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: Colors.deepYellow,
            backgroundColor: 'rgba(255, 153, 51, 0.05)',
          },
        },
      ],
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          padding: '12px',
          height: 'auto',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& .Mui-selected': {
            color: 'var(--darkGray)',
            fontWeight: '600',
            fontSize: '12px !important',
          },
          padding: '0 4px',
        },
        label: {
          marginTop: '2px',
          color: 'var(--midDarkGray)',
          fontSize: '12px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: Colors.midLightGray,
        },
      },
      variants: [
        {
          props: { color: 'error' },
          style: {
            color: Colors.red,
          },
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          height: '6px',
        },
        rail: {
          borderRadius: '0px',
          color: 'var(--antiFlashWhite)',
          height: '6px',
          opacity: '1',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { elevation: 1 },
          style: {
            boxShadow: '-0.5px 0.5px 2px 0px rgba(0, 0, 0, 0.24) !important',
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 0,
          [breakpoints.down('sm')]: {
            maxWidth: 260,
            padding: '20px !important',
          },
        },
        tooltipPlacementBottom: {
          [breakpoints.down('sm')]: {
            marginTop: '0 !important',
          },
        },
        tooltipPlacementTop: {
          [breakpoints.down('sm')]: {
            marginBottom: '0 !important',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--antiFlashWhite)',
          height: '43px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: 'none',
          backgroundColor: 'var(--antiFlashWhite)',
          // caption typography styles
          fontFamily: 'Lato',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '18px',
          color: 'var(--darkGray)',
          [breakpoints.down('sm')]: {
            fontSize: '10px',
            lineHeight: 'normal',
          },
        },
        root: {
          width: 'auto',
          textAlign: 'center',
          padding: '12px',
          borderBottom: 'none',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: 'var(--antiFlashWhite)',
        },
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: Colors.white,
          },
          '&:nth-of-type(even)': {
            backgroundColor: Colors.secondaryLight,
          },
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            width: '4px !important',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          padding: 'unset !important',
        },
        icon: {
          marginInlineEnd: '4px',
          padding: 'unset !important',
          color: 'unset !important',
        },
        root: {
          padding: '24px',
          [breakpoints.down('sm')]: {
            padding: '16px 8px',
          },
        },
      },
      variants: [
        {
          props: { severity: 'success' },
          style: {
            backgroundColor: 'rgba(59, 183, 126, 0.1)',
            color: Colors.green,
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: Colors.midLightGray,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '16px 12px',
          '&:hover': {
            backgroundColor: 'var(--secondaryLight) !important',
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--secondaryLight) !important',
            '&:hover': {
              backgroundColor: 'var(--secondaryLight) !important',
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          '&:before': {
            display: 'none',
          },
        },
        //@ts-ignore
        '&.Mui-expanded': {
          minHeight: '64px !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 40px !important',
          [breakpoints.down('sm')]: {
            padding: '0 24px !important',
          },
        },
        content: {
          margin: '18px 0',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 40px 16px !important',
          [breakpoints.down('sm')]: {
            padding: '0px 24px 16px !important',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--lightGray)',
        },
      },
    },
  },
  palette: {
    primary: {
      main: Colors.magentaPink,
    },
    error: {
      main: Colors.red,
    },
    magentaPink: palette.augmentColor({
      color: { main: Colors.magentaPink, dark: Colors.magentaHover },
    }),
    deepYellow: palette.augmentColor({
      color: { main: Colors.deepYellow, contrastText: Colors.white },
    }),
    violet: palette.augmentColor({
      color: { main: Colors.violet },
    }),
    green: palette.augmentColor({
      color: { main: Colors.green },
    }),
    white: palette.augmentColor({
      color: { main: Colors.white },
    }),
  },
});
