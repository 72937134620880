import { useProductDetails } from './useProductDetails';
import { KeyValueTable } from '../key-value-table';
import { ProductDetailsModelDto } from 'codegen';
import { Typography } from 'design-system';
import styles from './styles.module.scss';
import { HtmlParser } from 'components';
import { Box } from '@mui/material';

type Props = {
  productDetails: ProductDetailsModelDto | undefined;
};

export const ProductDetailsTab = ({ productDetails }: Props) => {
  const {
    essentialDetailsAttributes,
    hasEssentialDetails,
    supplyAbilityAttribute,
    hasSupplyAbility,
    dimensionsAttributes,
    hasDimensions,
    packagingDeliveryAttribute,
    hasPackagingDelivery,
    leadTimeAttribute,
    hasLeadTime,
  } = useProductDetails({ productDetails });

  return (
    <>
      {hasEssentialDetails && (
        <Box className="mt-12 max-sm:mt-5">
          <Typography variant="h5" component="h5" className="text-darkGray mb-3 max-sm:mb-1">
            Essential Details
          </Typography>
          <KeyValueTable keysValues={essentialDetailsAttributes} />
        </Box>
      )}

      {hasSupplyAbility && (
        <Box className="mt-12 max-sm:mt-5">
          <Typography variant="h5" component="h5" className="text-darkGray mb-3 max-sm:mb-1">
            Supply Ability
          </Typography>

          <Typography variant="body1" className="text-midDarkGray">
            {supplyAbilityAttribute}
          </Typography>
        </Box>
      )}

      {hasDimensions && (
        <Box className="mt-12 max-sm:mt-5">
          <Typography variant="h5" component="h5" className="text-darkGray mb-3 max-sm:mb-1">
            Dimensions
          </Typography>
          <KeyValueTable keysValues={dimensionsAttributes} />
        </Box>
      )}

      {hasPackagingDelivery && (
        <Box className="mt-12 max-sm:mt-5">
          <Typography variant="h5" component="h5" className="text-darkGray mb-3 max-sm:mb-1">
            Packaging and Delivery
          </Typography>
          <Typography variant="body1">
            <HtmlParser className={styles.packaging__delivery__body} htmlString={packagingDeliveryAttribute} />
          </Typography>
        </Box>
      )}

      {hasLeadTime && (
        <Box className="mt-12 max-sm:mt-5">
          <Typography variant="h5" component="h5" className="text-darkGray mb-3 max-sm:mb-1">
            Estimated Lead Time
          </Typography>

          <Typography variant="body1">
            <HtmlParser className={styles.lead__time} htmlString={leadTimeAttribute} />
          </Typography>
        </Box>
      )}
    </>
  );
};
