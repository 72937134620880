import { ReactComponent as CompanyLogo } from 'design-system/public/logo/Logo.svg';
import { Loader, Typography } from 'design-system';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { sendEventToGTM } from 'utils';
import { useCustomAuth } from 'hooks';
import { Box } from '@mui/material';

const WelcomePage = () => {
  return (
    <Box className=" bg-offWhiteBg h-[100vh]">
      <Box className={styles.page__container}>
        <CompanyLogo className=" h-[70px] max-md:h-10" />

        <Typography variant="h3" component="h3" className="text-darkGray">
          Welcome to otrovato
        </Typography>
      </Box>
    </Box>
  );
};

export const Callback = () => {
  const { isAuthenticated, getIdTokenClaims, user, isLoading } = useCustomAuth();
  const [isLoginSentGTM, setIsLoginSentGTM] = useState<boolean>(true);
  const [isSignUpSentGTM, setIsSignUpSentGTM] = useState<boolean>(true);
  const navigate = useNavigate();

  const redirectUri = localStorage.getItem('redirectUri');

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then(token => {
        if (token?.isFirstLogin) {
          sendSignUpToGTM();
        } else {
          sendLoginToGTM();
        }
      });
      setTimeout(() => {
        if (redirectUri) return navigate(redirectUri);
        else return navigate('/home');
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, redirectUri]);

  const sendLoginToGTM = () => {
    if (isLoginSentGTM) {
      setIsLoginSentGTM(false);
      sendEventToGTM('login_success', {
        user_name: user?.name,
        user_email: user?.email,
      });
    }
  };

  const sendSignUpToGTM = () => {
    if (isSignUpSentGTM) {
      setIsSignUpSentGTM(false);
      sendEventToGTM('signup_success', {
        user_name: user?.name,
        user_email: user?.email,
      });
    }
  };
  if (isLoading) return <Loader />;
  return <WelcomePage />;
};
