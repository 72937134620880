import { Typography as MuiTypography, TypographyProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export const Typography: React.FC<PropsWithChildren<TypographyProps>> = ({
  component = 'p',
  variant,
  children,
  ...props
}) => {
  return (
    <MuiTypography component={component} variant={variant} {...props}>
      {children}
    </MuiTypography>
  );
};
