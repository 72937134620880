import { Button, Card } from '@mui/material';
import styles from './styles.module.scss';

const FilterButtons = ({ onReset, onApply }) => {
  return (
    <Card className={styles.filter___actions}>
      <Button variant="contained" sx={{ width: 213 }} onClick={onApply}>
        Apply
      </Button>
      <Button variant="outlined" sx={{ ml: 1.5, width: 110 }} onClick={onReset}>
        Reset
      </Button>
    </Card>
  );
};

export default FilterButtons;
