export const aboutOtrovatoData = [
  {
    body: 'Welcome to otrovato, the ultimate B2B marketplace where trading is at your fingertips!',
  },
  {
    body: 'otrovato is a service offered by O Trade & Logistics (OTL), under the umbrella of Orascom Investment Holding (OIH).',
  },
  {
    body: 'Our mission is to connect buyers and sellers at one place to make the trading process easy, smooth, ensuring hassle-free transactions and less risk.',
  },
  {
    body: 'Our B2B marketplace is designed to connect buyers and sellers all over the world, with the primary objective of simplifying the process of trading, and to make trading accessible, efficient, and secure.',
  },
  {
    body: 'Whether a buyer is looking for products or a seller seeking new markets, otrovato will facilitate the trading needs.',
  },
  {
    body: 'We identify our Unique Selling Proposition as effortlessly discovering and connecting with trusted suppliers, Safeguarding the quality of your purchases through comprehensive inspections, and experiencing seamless end-to-end shipping solutions that streamline your business operations.',
  },
  {
    body: 'otrovato is your partner in advancing commerce.',
  },
  {
    body: 'Join now in building the future of global trade and logistics infrastructure by being part of the Trade Revolution.',
  },
];
