import { ReactComponent as Accept } from 'assets/images/accept.svg';
import { Modal, Typography } from 'design-system';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handlePrimaryClick: () => void;
  loading: boolean;
};

export const AcceptModal = ({ open, setOpen, handlePrimaryClick, loading }: Props) => {
  return (
    <Modal
      open={open}
      image={<Accept className="w-[50px] h-[50px]" />}
      primaryButtonText="Accept"
      secondaryButtonText="Cancel"
      header={
        <Typography variant="h6" component="h6" className="text-center text-darkGray">
          Accept the Quotation ?
        </Typography>
      }
      content={
        <Typography variant="body2" className="text-midDarkGray">
          Are you sure you want to accept the Quotation?
        </Typography>
      }
      handleClose={() => setOpen(false)}
      handlePrimary={handlePrimaryClick}
      loading={loading}
    />
  );
};
