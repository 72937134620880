import { Box, Card, CardProps } from '@mui/material';
import styles from './styles.module.css';

type VendorCardProps = {
  vendor: {
    id: number;
    pictureModel: {
      fullSizeImageUrl: string;
      alternateText: string;
    };
  };
} & CardProps;

export const VendorCard = ({ vendor, ...props }: VendorCardProps) => {
  const { id, pictureModel } = vendor;
  return (
    <Card className={styles.vendor__card} key={id} elevation={0} {...props}>
      <Box
        className={styles.vendor__media__container}
        style={{
          backgroundImage: `url(${pictureModel.fullSizeImageUrl})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        {...props}
      />
    </Card>
  );
};
