import { useBreadcrumbs } from 'contexts/breadcrumbContext';
import { Typography } from 'design-system';
import { aboutOtrovatoData } from './data';
import styles from './styles.module.scss';
import { useEffect } from 'react';

export const AboutOtrovato = () => {
  const { updateBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ label: 'About otrovato', link: '/about-otrovato' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={`${styles.about__img} max-sm:h-[26vh] max-sm:min-h-[160px]`}>
        <div className={styles.about__layout} />
        <div className={styles.about__img__header}>
          <Typography variant="h1" component="h1">
            Now, Trading is at your fingertips
          </Typography>
        </div>
      </div>
      <div className={`${styles.about__info} max-sm:py-6 `}>
        <Typography variant="h3" component="h3" className="text-darkGray">
          Trading at Your Fingertips
        </Typography>
        {aboutOtrovatoData &&
          aboutOtrovatoData.map(data => (
            <Typography variant="body1" className="text-midDarkGray pt-6 pb-0 leading-8 max-sm:pt-3">
              {data.body}
            </Typography>
          ))}
      </div>
    </>
  );
};
