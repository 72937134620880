import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

export const phoneValidation = (message: string) =>
  Yup.string()
    .required('Phone is required')
    .test({
      name: 'isValidPhoneNumber',
      message,
      test: function (value: string | undefined) {
        if (!value) {
          return true;
        }

        const isValid = isValidPhoneNumber(value);
        return isValid;
      },
    });
