import { FormControlLabel, CheckboxProps as MuiCheckboxProps, Checkbox as MuiCheckbox, Box } from '@mui/material';
import { CheckBoxOutlineBlankSharp, CheckBoxSharp } from '@mui/icons-material';
import { Controller, Control } from 'react-hook-form';
import { Typography } from 'src/core/typography';

type CheckboxProps = {
  name: string;
  control: Control<any>;
  label: any;
} & MuiCheckboxProps;

export const Checkbox: React.FC<CheckboxProps> = ({ name, label, control }) => {
  const icon = <CheckBoxOutlineBlankSharp />;
  const checkedIcon = <CheckBoxSharp />;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field, fieldState }) => (
        <Box className="flex flex-col">
          <Box className="flex items-center">
            <FormControlLabel
              control={
                <MuiCheckbox
                  {...field}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  color={fieldState.error ? 'error' : 'primary'}
                />
              }
              label=""
              className="me-1"
            />
            {label}
          </Box>
          {fieldState.error && (
            <Typography variant="body2" className="text-red">
              {fieldState.error.message}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};
