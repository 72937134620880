import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { TabPanel } from './tab-panel';
import { Tabs } from './tabs';
import { Tab } from './tab';

type Props = {
  value: number;
  handleChangeTab: (newValue: number) => void;
  tabs: { label: string; element: ReactJSXElement }[];
};

export const TabsComponent = ({ value = 0, handleChangeTab, tabs }: Props) => {
  const onChange = (e: React.SyntheticEvent, newValue: number) => {
    handleChangeTab(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={onChange}>
        {tabs.map((tab, idx) => (
          <Tab label={tab.label} key={`custom-tab-${idx}`} id={tab?.label} />
        ))}
      </Tabs>
      {tabs.map((tab, idx) => (
        <TabPanel value={value} index={idx} key={`custom-tab-panel-${idx}`}>
          {tab.element}
        </TabPanel>
      ))}
    </>
  );
};
