import { useClearRefinements } from 'react-instantsearch';
import { Typography } from 'design-system';

export const AlgoliaClearRefinements = () => {
  const { refine } = useClearRefinements();

  return (
    <div onClick={refine} className="flex cursor-pointer">
      <Typography variant="subtitle1" className="flex items-center text-midLightGray">
        Reset Filter
      </Typography>
    </div>
  );
};
