import {
  Tooltip as MuiTooltip,
  IconButton,
  styled,
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
  ClickAwayListener,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';

type TooltipProps = {
  infoIcon: any;
  title: any;
  placement: any;
};

const BootstrapTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'var(--midDarkGray)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--midDarkGray)',
    padding: '24px',
  },
}));

export const Tooltip = ({ infoIcon, placement, title }: TooltipProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const isTouchScreen = useMediaQuery('(pointer: coarse)');

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <>
      {isTouchScreen && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <BootstrapTooltip
              title={title}
              placement={placement}
              open={open}
              onClose={handleTooltipClose}
              onClick={handleTooltipToggle}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <IconButton>{infoIcon}</IconButton>
            </BootstrapTooltip>
          </div>
        </ClickAwayListener>
      )}

      {!isTouchScreen && (
        <BootstrapTooltip title={title} placement="right-start">
          <IconButton>{infoIcon}</IconButton>
        </BootstrapTooltip>
      )}
    </>
  );
};
