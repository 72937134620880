import { Box, Card, useMediaQuery, useTheme } from '@mui/material';
import { Typography } from 'src/core/typography';
import { HTMLAttributes } from 'react';
import './styles.css';

type CategoryCardProps = {
  category: {
    name: string;
    id: number;
    pictureModel: { fullSizeImageUrl: string; alternateText: string };
  };
} & HTMLAttributes<HTMLDivElement>;

export const CategoryCard = ({ category, ...props }: CategoryCardProps) => {
  const { id, pictureModel, name } = category;
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Card key={id} className={`category__card ${props.className} rounded-none`} elevation={0} {...props}>
      <div
        className="category__media__container"
        style={{
          backgroundImage: `url(${pictureModel.fullSizeImageUrl})`,
        }}
      />

      <Box className="overlay">
        <Typography variant={isDownMd ? 'subtitle2' : 'h6'} className="w-full">
          {name}
        </Typography>
      </Box>
    </Card>
  );
};
