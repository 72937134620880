import { Box, Pagination as MuiPagination } from '@mui/material';

type PaginationProps = {
  pagesCount: number | undefined;
  pageNumber: number;
  handleChangePage: (val: number) => void;
};
export const Pagination = ({ pagesCount, pageNumber, handleChangePage }: PaginationProps) => {
  return (
    <Box className="flex justify-end">
      <MuiPagination
        count={pagesCount}
        size="large"
        variant="text"
        page={pageNumber}
        onChange={(e, value) => handleChangePage(value)}
      />
    </Box>
  );
};
