import { ReactComponent as PerPageIcon } from 'design-system/public/icons/per-page.svg';
import { Box, Hidden, MenuItem, Select } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { SelectListItemDto } from 'codegen';
import { Typography } from 'design-system';
import { useState } from 'react';

type Props = {
  pageSize: string;
  pageSizeOptions: SelectListItemDto[];
  setPageSize: (value: number) => void;
};

export const PageSize = ({ pageSize, pageSizeOptions, setPageSize }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Select
      open={open}
      sx={{ width: 'auto', fontSize: '13px', height: '40px' }}
      defaultValue={pageSize}
      variant="standard"
      disableUnderline
      renderValue={value => {
        setPageSize(+value);
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <PerPageIcon />

            <Hidden mdDown>
              <Typography className="text-midLightGray" variant="body1">
                Show:
              </Typography>{' '}
              <Typography className="text-darkGray" variant="body1">
                {pageSize}
              </Typography>
            </Hidden>

            <Hidden mdUp>
              <Typography className="text-darkGray" variant="body1">
                {pageSize}
              </Typography>
            </Hidden>
          </Box>
        );
      }}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      IconComponent={() => (
        <div className="cursor-pointer absolute right-0 text-midLightGray" onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      )}
      MenuProps={{
        sx: {
          '&& .Mui-selected': {
            backgroundColor: 'var(--secondaryLight)',
          },
        },
      }}
    >
      {pageSizeOptions &&
        pageSizeOptions.map(
          option =>
            option.value && (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ),
        )}
    </Select>
  );
};
