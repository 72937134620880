import { TextField, Autocomplete as MuiAutocomplete, Popper, Box } from '@mui/material';
import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import { KeyboardArrowDown } from '@mui/icons-material';
import { ErrorFeedback } from '../error-feedback';
import { InputLabel } from '../input-label';
import { useState } from 'react';

interface RHFAutocompleteFieldProps<O extends { value: string; label: string }, TField extends FieldValues> {
  control: Control<TField>;
  label: string;
  name: Path<TField>;
  options: O[];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  labelColor?: string;
  inputProps?: any;
  textFieldProps?: any;
  endAdornmentOpacity?: number;
}
export const AutocompleteSelect = <O extends { value: string; label: string }, TField extends FieldValues>(
  props: RHFAutocompleteFieldProps<O, TField>,
) => {
  const { control, options, name, disabled, label, required, inputProps, textFieldProps, endAdornmentOpacity } = props;
  const [open, setOpen] = useState<boolean>(false);

  const EndAdornment = () => {
    return (
      <Box className="flex items-center h-12 bg-white pe-3" sx={{ opacity: endAdornmentOpacity }}>
        <KeyboardArrowDown onClick={() => setOpen(!open)} style={{ color: 'var(--midDarkGray)', cursor: 'pointer' }} />
      </Box>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;
        return (
          <>
            <InputLabel
              label={label}
              required={props.required}
              disabled={props.disabled}
              labelColor={props.labelColor}
            />

            <MuiAutocomplete
              PopperComponent={({ style, ...props }) => <Popper {...props} style={{ ...style }} />}
              open={open}
              options={options}
              value={
                value
                  ? options.find(option => {
                      return value === option.value;
                    }) ?? null
                  : null
              }
              getOptionLabel={option => option.label}
              renderInput={fieldParams => (
                <TextField
                  name={`${name}-${Math.random()}`}
                  {...fieldParams}
                  {...textFieldProps}
                  required={required}
                  placeholder={props.placeholder}
                  inputRef={ref}
                  error={!!fieldState.error}
                  InputProps={{
                    ...fieldParams.InputProps,
                    endAdornment: <EndAdornment />,
                    inputProps: {
                      ...fieldParams.inputProps,
                      ...inputProps,
                    },
                  }}
                />
              )}
              onChange={(event: any, newValue: any) => {
                onChange(newValue ? newValue.value : null);
              }}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              disabled={disabled}
            />
            <ErrorFeedback fieldState={fieldState} />
          </>
        );
      }}
    />
  );
};
