import { IconButton as MuiIconButton, SvgIconProps, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type IconButtonProps = {
  size: 'small' | 'medium' | 'large';
  isPrimary?: boolean;
  variant?: 'contained' | 'outlined';
  className?: string;
  icon?: React.ReactElement<SvgIconProps>;
  border?: boolean;
  onClick: (param?: any) => void;
} & MuiIconButtonProps;

const BASE_BUTTON_CLASSES = 'font-poppins font-medium';

export const IconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
  size = 'medium',
  isPrimary = true,
  className,
  icon,
  children,
  border = true,
  onClick,
  ...props
}: IconButtonProps) => {
  return (
    <MuiIconButton
      className={`${className} ${BASE_BUTTON_CLASSES}`}
      color={isPrimary ? 'primary' : 'secondary'}
      size={size}
      sx={{
        color: 'var(--midLightGray)',
        textTransform: 'none',
        border: border ? '1px solid var(--body)' : 'none',
        borderRadius: '4px',
        padding: '10px 8px',
      }}
      onClick={onClick}
      {...props}
    >
      {icon || children}
    </MuiIconButton>
  );
};
