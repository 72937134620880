import { ReactComponent as ArrowRight } from 'design-system/public/icons/arrow-right-purple.svg';
import { AppContainer, Typography } from 'design-system';
import { Link, useLocation } from 'react-router-dom';
import { useCustomAuth, useMyUser } from 'hooks';
import { Alert, Box } from '@mui/material';
import { userHasMetadata } from 'utils';

export const VerifiedHeader = () => {
  const { isAuthenticated } = useCustomAuth();
  const { myUser } = useMyUser();
  const { pathname } = useLocation();

  if (userHasMetadata(myUser) || !isAuthenticated || pathname !== '/home' || !myUser) return <></>;
  return (
    <Box
      sx={{ backgroundColor: 'rgba(106, 82, 170, 0.1)', width: '100%', color: 'var(--violet)', paddingBlock: '1rem' }}
    >
      <Link to="/profile">
        <AppContainer>
          <Alert
            severity="info"
            sx={{
              backgroundColor: 'unset',
              color: 'unset',
              textAlign: 'center',
              justifyContent: 'center',
              padding: 'unset !important',
            }}
          >
            <Box className="flex items-center">
              <Typography variant="body1" className="leading-5">
                Complete your profile for the best experience
              </Typography>
              <ArrowRight className="ms-1" />
            </Box>
          </Alert>
        </AppContainer>
      </Link>
    </Box>
  );
};
