import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { useEffect } from 'react';

export const useInitializeTagManager = () => {
  const initializeTagManager = () => {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
  };

  useEffect(() => {
    initializeTagManager();
  }, []);
};
