import {
  getFaqs,
  getHomePageCategories,
  getHomePageProducts,
  getPromotions,
  getTopVendors,
} from 'services/orchestrator.service';
import { CategorySimpleModelDto, ProductOverviewModelDto, VendorModelDto } from 'codegen';
import { GetPromotionsDto } from 'services/dtos/hubspot/GetPromotions.dto';
import { GetFaqsDto } from 'services/dtos/hubspot/GetFaqs.dto';
import { useLoading } from 'contexts/loadingContext';
import { useEffect, useState } from 'react';

export const useHomePage = () => {
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [promotions, setPromotions] = useState<GetPromotionsDto>();
  const [faqs, SetFaqs] = useState<GetFaqsDto>();
  const [bestSellingProducts, setBestSellingProducts] = useState<ProductOverviewModelDto[]>();
  const [homePageCategories, setHomePageCategories] = useState<CategorySimpleModelDto[]>();
  const [topVendors, setTopVendors] = useState<VendorModelDto[]>();

  useEffect(() => {
    Promise.all([getPromotions(), getHomePageProducts(), getHomePageCategories(), getTopVendors(), getFaqs()]).then(
      ([{ data: promotions }, { data: products }, { data: categories }, { data: topVendors }, { data: faqs }]) => {
        setPromotions(promotions);
        setBestSellingProducts(products);
        setHomePageCategories(categories);
        setTopVendors(topVendors);
        SetFaqs(faqs);
      },
    );
  }, [startLoading, stopLoading]);

  useEffect(() => {
    if (!promotions || !homePageCategories || !bestSellingProducts || !faqs) startLoading();
    else stopLoading();
  }, [promotions, bestSellingProducts, homePageCategories, topVendors, faqs, startLoading, stopLoading]);

  return {
    bestSellingProducts,
    homePageCategories,
    promotions,
    topVendors,
    faqs,
    isLoading,
  };
};
