import {
  AutocompleteSelect,
  BusinessTypesOptions,
  Button,
  CheckedSuccessfully,
  Modal,
  MultipleAutocomplete,
  PhoneInput,
  TextInput,
  Typography,
} from 'design-system';
import { Box, Grid } from '@mui/material';
import { useProfile } from './useProfile';
import { LoadingButton } from '@mui/lab';
import { userHasMetadata } from 'utils';

export const Profile = () => {
  const {
    control,
    onSubmit,
    getValues,
    countries,
    handleSubmit,
    loading,
    handleCountryChange,
    handleBusinessTypeChange,
    selectedCountry,
    setUserData,
    checkIsFormDirty,
    successfulOpen,
    myUser,
    handleCloseDialog,
  } = useProfile();

  return (
    <>
      <Modal
        open={successfulOpen}
        image={
          <Box className="w-16 h-16">
            <CheckedSuccessfully />
          </Box>
        }
        header={
          <Typography variant="h6" component="h6" className="text-center text-darkGray">
            Updated Successfully
          </Typography>
        }
        content={
          <Typography variant="body2" className="text-center text-midDarkGray">
            Your profile was updated successfully
          </Typography>
        }
        secondaryButtonText="Ok"
        handleClose={() => handleCloseDialog()}
      />

      <Box className="bg-white p-12 max-w-[1120px] m-auto max-sm:bg-transparent max-sm:px-0 max-sm:pt-0">
        <Typography component="h3" variant="h3" className="text-darkGray">
          Your Profile
        </Typography>

        <Grid container spacing={3} className="mt-6 max-sm:mt-0">
          <Grid item xs={12} md={6}>
            <TextInput name="name" label="Full Name" placeholder="Enter your name" control={control} required />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput name="email" label="Email" placeholder="Enter your Email" control={control} disabled />
          </Grid>

          <Grid item xs={12} md={6}>
            <AutocompleteSelect
              name="country"
              label="Country"
              placeholder="Choose your country"
              options={countries}
              control={control}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PhoneInput
              name="phone"
              label="Phone Number"
              placeholder="Enter your phone number"
              control={control}
              handleCountryChange={handleCountryChange}
              selectedCountry={selectedCountry}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              name="companyName"
              label="Company Name"
              placeholder="Enter your Company name"
              control={control}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MultipleAutocomplete
              name="businessType"
              label="Business Type"
              placeholder="Choose your business type"
              options={BusinessTypesOptions}
              setSelectedValues={handleBusinessTypeChange}
              control={control}
              limit={3}
              required
              defaultValues={getValues('businessType')}
            />
          </Grid>
        </Grid>

        <Box className="flex justify-end items-center mt-10">
          {userHasMetadata(myUser) && (
            <Button
              variant="outlined"
              className="me-3 w-40 text-midLightGray"
              color="inherit"
              onClick={() => setUserData()}
              disabled={!checkIsFormDirty()}
            >
              Cancel
            </Button>
          )}
          <LoadingButton
            size="large"
            className="w-40 max-sm:w-full"
            variant="contained"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            disabled={!checkIsFormDirty() && userHasMetadata(myUser)}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
