import { ReactComponent as ArrowIcon } from 'design-system/public/icons/search-arrow.svg';
import { ReactComponent as SearchIcon } from 'design-system/public/icons/search.svg';
import { AutocompleteState } from '@algolia/autocomplete-core';
import { getCollectionBySourceId } from '../../functions';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'design-system';
import { Highlight } from '../highlight';
import { PopularHit } from '../../types';
import './styles.scss';

type PopularItemProps = {
  hit: PopularHit;
  autocomplete: any;
};
type Props = {
  autocomplete: any;
  autocompleteState: AutocompleteState<any>;
};

const PopularItem = ({ hit, autocomplete }: PopularItemProps) => {
  return (
    <div className="aa-ItemWrapper">
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--noBorder">
          <SearchIcon fill="var(--midLightGray)" />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            <Typography variant="body1" className="line-clamp-1 text-midDarkGray">
              <Highlight hit={hit} attribute="query" />
            </Typography>
          </div>
        </div>
      </div>
      <div className="aa-ItemActions">
        <button
          className="aa-ItemActionButton"
          onClick={e => {
            e.stopPropagation();
            autocomplete.setQuery(hit.query);
            autocomplete.refresh();
          }}
        >
          <ArrowIcon />
        </button>
      </div>
    </div>
  );
};

export const KeywordSuggestion = ({ autocomplete, autocompleteState }: Props) => {
  const keywordSuggestions = getCollectionBySourceId(autocompleteState, 'querySuggestionsPlugin');
  const navigate = useNavigate();

  return (
    <section className="aa-Source">
      <ul className="aa-List" {...autocomplete.getListProps()}>
        {keywordSuggestions &&
          keywordSuggestions.items.map((item, idx) => {
            return (
              <li
                key={`keyword-suggestion-item-${idx}`}
                className="aa-Item"
                onClick={() => navigate(`/products?search=${item.query}`, { state: { searchName: item.query } })}
              >
                <PopularItem hit={item} autocomplete={autocomplete} />
              </li>
            );
          })}
      </ul>
    </section>
  );
};
