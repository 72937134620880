import { ReactComponent as CompanyLogo } from 'design-system/public/logo/Logo.svg';
import { AccountMenu } from '../../account-menu';
import { AlgoliaAutocomplete } from 'components';
import { AppContainer } from 'design-system';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export const OtherMainHeader = () => {
  return (
    <AppContainer>
      <Box className={styles.other__main__header__container}>
        <Link to={'/home'}>
          <CompanyLogo className="h-[40px]" />
        </Link>

        <Box className={styles.other__main__header__autocomplete__container}>
          <AlgoliaAutocomplete />
        </Box>

        <AccountMenu className={styles.account__menu} />
      </Box>
    </AppContainer>
  );
};
