import { getRootCategories } from 'services/orchestrator.service';
import { CategorySimpleModelDto } from 'codegen';
import { useEffect, useState } from 'react';

export const useCategories = () => {
  const [rootCategories, setRootCategories] = useState<CategorySimpleModelDto[]>();
  const [currentCategory, setCurrentCategory] = useState<CategorySimpleModelDto>();
  const [subCategories, setSubCategories] = useState<CategorySimpleModelDto[] | null>();

  useEffect(() => {
    getRootCategories().then(res => {
      const newFilteredCategories = res?.data && res?.data.filter(category => category.numberOfProducts !== 0);
      setRootCategories(newFilteredCategories);

      setSubCategories(rootCategories && rootCategories[0]?.subCategories);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    rootCategories,
    subCategories,
    setSubCategories,
    currentCategory,
    setCurrentCategory,
  };
};
