import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { FreeMode, Thumbs, Mousewheel, Controller } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { useState, useRef, useLayoutEffect } from 'react';
import type { Swiper as SwiperType } from 'swiper';
import { Box, Container } from '@mui/material';
import { PictureModelDto } from 'codegen';
import './styles.scss';

type Props = {
  pictureModels?: PictureModelDto[] | null;
  defaultPictureModel: PictureModelDto;
  handleClose: Function;
  currentSlideIndex: number;
};

export const MediaModalContent = ({ pictureModels, currentSlideIndex, defaultPictureModel }: Props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();
  const swiper1Ref = useRef<any>(null);
  const swiper2Ref = useRef<any>(null);

  const responsiveGallerySwiperParams: SwiperProps = {
    slidesPerView: 1,
    loop: true,
    modules: [FreeMode, Thumbs, Controller],
    onSwiper: swiper => {
      if (swiper1Ref.current !== null) {
        swiper1Ref.current = swiper;
      }
    },
    thumbs: {
      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
    },
    initialSlide: currentSlideIndex,
    className: 'responsive__gallery__swiper',
  };
  const responsiveThumbnailSwiperParams: SwiperProps = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 12,
    slidesPerView: 4.5,
    slideToClickedSlide: true,
    initialSlide: currentSlideIndex,
    modules: [Thumbs, Controller, Mousewheel],
    className: 'responsive__thumbs__swiper',
  };

  useLayoutEffect(() => {
    if (swiper1Ref.current !== null) {
      swiper1Ref.current.controller.control = swiper2Ref.current;
    }
  }, [currentSlideIndex]);

  return (
    <Box className="flex flex-col">
      <Container sx={{ paddingInline: 0 }}>
        <Swiper {...responsiveGallerySwiperParams}>
          {pictureModels &&
            pictureModels.map((slide, index) => {
              return (
                <SwiperSlide key={index}>
                  <TransformWrapper initialScale={1} smooth doubleClick={{ step: 1 }}>
                    <TransformComponent>
                      <img src={slide.fullSizeImageUrl || ''} alt={slide.alternateText || ''} />
                    </TransformComponent>
                  </TransformWrapper>
                </SwiperSlide>
              );
            })}
          {!pictureModels && (
            <SwiperSlide key="default-image">
              <img src={defaultPictureModel.fullSizeImageUrl || ''} alt={defaultPictureModel.alternateText || ''} />
            </SwiperSlide>
          )}
        </Swiper>
      </Container>
      <Container sx={{ paddingInline: 0 }}>
        <Swiper {...responsiveThumbnailSwiperParams}>
          {pictureModels &&
            pictureModels.map((slide, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="slider__image">
                    <img src={slide.fullSizeImageUrl || ''} alt={slide.alternateText || ''} />
                  </div>
                </SwiperSlide>
              );
            })}
          {!pictureModels && (
            <SwiperSlide key="default-image">
              <div className="slider__image">
                <img src={defaultPictureModel.fullSizeImageUrl || ''} alt={defaultPictureModel.alternateText || ''} />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </Container>
    </Box>
  );
};
