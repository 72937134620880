import { HTMLAttributes } from 'react';
import parse from 'html-react-parser';
import React from 'react';

type Props = {
  htmlString: string;
} & HTMLAttributes<HTMLDivElement>;

export const MediaHtmlParser = ({ htmlString, ...props }: Props) => {
  const imgPrefix = process.env.REACT_APP_OTROVATO_BASE_URL || '';

  const transform = (node: any) => {
    if (node.type === 'tag' && (node.name === 'img' || node.name === 'video')) {
      const src = node.attribs.src;
      if (src) {
        node.attribs.src = src.startsWith(imgPrefix) ? src : `${imgPrefix}${src}`;
      }

      if (node.name === 'video') {
        node.children = node.children.map((child: any) => {
          if (child.type === 'tag' && child.name === 'source') {
            const sourceSrc = child.attribs.src;
            if (sourceSrc) {
              child.attribs.src = sourceSrc.startsWith(imgPrefix) ? sourceSrc : `${imgPrefix}${sourceSrc}`;
            }
          }
          return child;
        });
      }
    }
    return node;
  };

  const transformedHTML = htmlString ? parse(htmlString, { replace: transform }) : null;

  return <div {...props}>{transformedHTML}</div>;
};
