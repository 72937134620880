import { CategoryCard, Loader, Typography } from 'design-system';
import { NextArrow, PrevArrow } from 'components';
import Slider, { Settings } from 'react-slick';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

export const CategorySlider = ({ categoryDetails }) => {
  const subCategoriesLength = categoryDetails?.categoryModelDto.subCategories?.length;

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    nextArrow: <NextArrow customClassName="w-9 h-9" />,
    prevArrow: <PrevArrow customClassName="w-9 h-9" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3.5,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  };

  if (!categoryDetails) return <Loader />;
  return (
    <>
      {categoryDetails.categoryModelDto.subCategories && subCategoriesLength! > 0 && (
        <Box className="mb-6 max-sm:mb-3">
          <Typography variant="subtitle1" className="mb-6 max-sm:mb-3 text-darkGray">
            Categories
          </Typography>

          <Slider {...settings} className="card-slider">
            {categoryDetails.categoryModelDto.subCategories.map(subCategory => (
              <Box key={`category-subcategories-#${subCategory.id}`}>
                <Link to={`/category/${subCategory.id}/${subCategory.seName}`}>
                  <CategoryCard
                    category={{
                      id: subCategory.id,
                      name: subCategory.name || '',
                      pictureModel: {
                        fullSizeImageUrl: subCategory.pictureModel.fullSizeImageUrl || '',
                        alternateText: subCategory.pictureModel.alternateText || '',
                      },
                    }}
                  />
                </Link>
              </Box>
            ))}
          </Slider>
        </Box>
      )}
    </>
  );
};
