import { Dialog as MUIDialog, DialogTitle, DialogContent } from '@mui/material';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  title: JSX.Element | string;
  content: JSX.Element | string;
};

export const Dialog = ({ open, onClose, title, content, ...props }: DialogProps) => {
  return (
    <MUIDialog onClose={onClose} open={open} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
    </MUIDialog>
  );
};
