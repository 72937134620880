import { Box, Divider, Slider as MuiSlider, SliderProps, TextField } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useAlgoliaSlider } from './useAlgoliaSlider';
import { Typography } from 'design-system';
import styles from './styles.module.scss';

type Props = {
  attribute: string;
} & SliderProps;

export const AlgoliaSlider = ({ attribute }: Props) => {
  const { min, max, value, handleChange, isListExpanded, setIsListExpanded, handleMinMaxFieldChange } =
    useAlgoliaSlider({ attribute });

  const handleKeyPress = event => {
    const invalidCharacters = ['e', '+', '-'];
    if (invalidCharacters.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Box className="mb-5">
      {isListExpanded && <Divider className="mb-8 mt-4 max-sm:mb-4 border-antiFlashWhite" />}

      <Box className="flex justify-between items-center mb-5 max-sm:mb-4">
        <Typography variant="subtitle1" className="text-darkGray capitalize">
          Price Range
        </Typography>
        <button onClick={() => setIsListExpanded(!isListExpanded)} className="cursor-pointer">
          {isListExpanded && <KeyboardArrowUp style={{ color: 'var(--midLightGray)' }} />}
          {!isListExpanded && <KeyboardArrowDown style={{ color: 'var(--midLightGray)' }} />}
        </button>
      </Box>

      {isListExpanded && (
        <>
          <Box className="flex justify-between items-center mb-4">
            <TextField
              value={value.start?.toString()}
              className={styles.max__min__input}
              type="number"
              placeholder={value && String(value.start)}
              sx={{ input: { color: 'gray', opacity: 1 } }}
              onChange={v => handleMinMaxFieldChange(v, 'min')}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <Typography variant="subtitle1" className="text-[10px] text-gray">
                    min
                  </Typography>
                ),
                style: { color: 'var(--darkGray)' },
                inputProps: { min, style: { padding: '0px' } },
              }}
              onKeyDown={handleKeyPress}
              size="small"
            />

            <Divider
              sx={{
                width: '12px',
                bgcolor: 'var(--antiFlashWhite)',
              }}
            />

            <TextField
              value={value.end?.toString()}
              className={styles.max__min__input}
              type="number"
              placeholder={value && String(value.end)}
              sx={{ input: { color: 'gray', opacity: 1 } }}
              onChange={v => handleMinMaxFieldChange(v, 'max')}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <Typography variant="subtitle1" className="text-[10px] text-gray">
                    max
                  </Typography>
                ),
                style: { color: 'var(--darkGray)' },
                inputProps: { max, style: { padding: '0px' } },
              }}
              onKeyDown={handleKeyPress}
              size="small"
            />
          </Box>

          <Box sx={{ px: 1 }}>
            <MuiSlider
              valueLabelDisplay="off"
              min={min}
              max={max}
              step={100}
              value={[+value.start!, +value?.end!]}
              onChange={handleChange}
              color="primary"
            />
          </Box>

          <Box className="flex justify-between">
            <Typography variant="body2" className="text-gray">
              {min} $
            </Typography>
            <Typography variant="body2" className="text-gray">
              {max} $
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
