import { TextField, TextFieldProps } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { ErrorFeedback } from '../error-feedback';
import { InputLabel } from '../input-label';

type TextInputProps = {
  name: string;
  label?: string;
  control: Control<any>;
  labelColor?: string;
} & TextFieldProps;

export const TextInput: React.FC<TextInputProps> = ({ name, label, control, ...props }) => {
  const handleKeyDown = (event: any) => {
    if (props.type === 'number' && (event.key === 'e' || event.key === 'E')) {
      event.preventDefault();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <>
          {label && (
            <InputLabel
              label={label}
              required={props.required}
              disabled={props.disabled}
              labelColor={props.labelColor}
            />
          )}
          <TextField
            variant="outlined"
            color="primary"
            error={!!fieldState.error}
            {...props}
            {...field}
            name={`${name}-${Math.random()}`}
            onKeyDown={handleKeyDown}
          />
          <ErrorFeedback fieldState={fieldState} />
        </>
      )}
    />
  );
};
