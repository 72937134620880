import { useHits } from 'react-instantsearch';
import { ProductCard } from 'design-system';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

export const AlgoliaHits = () => {
  const { hits } = useHits();

  return (
    <Grid container spacing={{ xs: 1.5, md: 3 }}>
      {hits?.map((product, idx) => (
        <Grid item xs={6} sm={4} md={3} key={idx}>
          <Link to={`/product-details/${product.id}/${product.seName}`} state={{ fromSearch: false }}>
            <ProductCard pName={product.name} image={product.image} price={`$${product.price}`} unit={product.unit} />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
