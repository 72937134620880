import { ChangeEvent, useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';

export const useAlgoliaSlider = ({ attribute }) => {
  const { start, range, refine } = useRange({ attribute });
  const { min, max } = range;
  const [value, setValue] = useState({ start: min, end: max });
  const [isListExpanded, setIsListExpanded] = useState<boolean>(true);
  const [newValue, setNewValue] = useState();

  // @ts-ignore
  const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
  // @ts-ignore
  const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);

  useEffect(() => {
    setValue({ start: from, end: to });
  }, [from, to]);

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue({ start: newValue[0], end: newValue[1] });
    //@ts-ignore
    setNewValue(newValue);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' if you want instant scrolling
      });
    }, 150);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      //@ts-ignore
      refine(newValue);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue]);

  const handleMinMaxFieldChange = (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: String) => {
    let rangeValue: any;
    var inputValue = +v.target.value;
    switch (type) {
      case 'min':
        if (inputValue > max!) rangeValue = [min, value.end];
        else {
          setValue({ start: inputValue, end: value.end });
          rangeValue = [+v.target.value, value.end];
        }
        break;
      case 'max':
        if (inputValue > max!) rangeValue = [value.start, max];
        else {
          setValue({ start: value.start, end: inputValue });
          rangeValue = [value.start, inputValue];
        }
        break;
    }
    refine(rangeValue);
  };

  return {
    min,
    max,
    value,
    handleChange,
    isListExpanded,
    setIsListExpanded,
    handleMinMaxFieldChange,
  };
};
