import { AlgoliaAutocomplete } from 'components';
import { AppContainer } from 'design-system';
import styles from './styles.module.scss';
import { Box } from '@mui/material';

export const HomeSecondaryHeader = () => {
  return (
    <AppContainer>
      <Box className={styles.home__secondary__header__container}>
        <AlgoliaAutocomplete />
      </Box>
    </AppContainer>
  );
};
