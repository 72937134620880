import { Blog } from 'services/dtos/hubspot/GetBlogs.dto';
import { getBlog } from 'services/orchestrator.service';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

export const useBlog = () => {
  const [blog, setBlog] = useState<Blog>();
  const { id } = useParams();

  useEffect(() => {
    id &&
      Promise.all([getBlog({ rowId: id })]).then(([{ data: blog }]) => {
        setBlog(blog);
      });
  }, [id]);

  return { blog };
};
